import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../components/Context';

const Home = () => {

    window.document.title = 'Home';

    const [context] = useContext(Context)

    return (
        <div className='front-container'>
            <nav className="navbar-front">
                <div className="container">
                    <div className='row align-items-center'>
                        <div className='col'>
                            <a className="login-logo" href="https://www.axtelllabs.com" title="Axtell Labs">
                                <img src="/images/logo.png" alt="AxtellLabs-Logo" width="120" height="54" />
                            </a>
                        </div>
                        <div className='col-auto'>
                            {context && context.auth ?
                                context.auth.role_id === 1 ?
                                    <Link to="/admin/dashboard" title="Dashboard" className="btn btn-sm py-2 px-4 rounded-pill btn-primary">Dashboard</Link>
                                    :
                                    <Link to="/user/biomass-bagging" title="Dashboard" className="btn btn-sm py-2 px-4 rounded-pill btn-primary">Dashboard</Link>
                                :
                                <Link to="/login" title="Login" className="btn btn-sm py-2 px-5 rounded-pill btn-primary">Login</Link>
                            }
                        </div>
                    </div>
                </div>
            </nav>

            <div className='hero-banner'>
                <img src="/images/slider1.png" alt="..." />
                <div className="position-relative h-inherit">
                    <a href="https://www.axtelllabs.com" title="Axtell Labs">Axtell Labs</a>
                </div>
            </div>
        </div>
    )
}

export default Home;