import React, {  } from 'react';
import { initialFormState, validateForm } from '../components/Helper';
import { postFormData } from '../fetch-data';
import { AUTH } from '../components/ApiUrl';

const ChangePassword = () => {

    window.document.title = 'Change Password'

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(document.getElementById('change_password'));
        if(validateForm(e, 'change_password')){
            postFormData(AUTH.CHANGE_PASSWORD, formData, (res) => {
                if(res.status){
                    initialFormState('change_password');
                }
            }, true);
        }
    }

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Change Password</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form className="row g-3 g-sm-4" id="change_password">
                        <div className="col-12">
                            <label htmlFor="old_password" className="form-label">Old Password <strong className="text-danger">*</strong></label>
                            <input type="password" name="old_password" id="old_password" className="form-control form-control-lg" placeholder="Old Password" required />
                            <div className="invalid-feedback">Please enter Old Password.</div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="password" className="form-label">New Password <strong className="text-danger">*</strong></label>
                            <input type="password" name="password" className="form-control form-control-lg" placeholder="New Password" required />
                            <div className="invalid-feedback">Please enter New Password.</div>
                        </div>
                        <div className="col-12">
                            <label htmlFor="password" className="form-label">Confirm Password <strong className="text-danger">*</strong></label>
                            <input type="password" name="password_confirmation" className="form-control form-control-lg" placeholder="Confirm Password" required />
                            <div className="invalid-feedback">Please enter Confirm Password.</div>
                        </div>
                        <div className="mb-4 mb-sm-5">
                            <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>   
        </div>
    )
}

export default ChangePassword;