import React, { useEffect, useState, useContext } from 'react';
import { getJsonData, postFormData } from '../../fetch-data';
import { AUTH, USER } from '../../components/ApiUrl';
import { useLocation, useNavigate } from 'react-router-dom';
import { permission, showloader, validateForm } from '../../components/Helper'
import Context from '../../components/Context';

let permissionArr = []
var resultArray = []
var checkedFormArr = []

const EditUser = () => {

    const location = useLocation()
    let user_id = location.state
    var navigate = useNavigate()
    window.document.title = 'Edit User';
    const [permissions, setPermission] = useState([])
    const [user_data, setUserData] = useState('')
    const [userform, setUserForm] = useState(false)
    const [context] = useContext(Context)

    const onChangeValue = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        if (target.type === 'checkbox') {
            if (target.checked) {
                permissionArr = permissions.filter(CheckedVal =>
                    CheckedVal !== value
                )
                permissionArr.push(value)
            }
            else {
                permissionArr = permissions.filter(CheckedVal =>
                    CheckedVal !== value
                )
            }
            setPermission(permissionArr)

        } else {
            setPermission({[name] : value})
        }
    }

    const onFormChange = (e, id) => {
        
        if (e.target.checked) {
            resultArray = userform.filter(CheckedId =>
                CheckedId !== id
            )
            resultArray.push(id)
        }
        else {
            resultArray = userform.filter(CheckedId =>
                CheckedId !== id
            )
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('edit_user'));
        if(validateForm(e, 'edit_user')){
            formData.append('id', user_data.id);
            
            showloader(true)
            postFormData(USER.UPDATE_USER, formData, (res) => {
                showloader()
                if(res.status){
                    checkedFormArr = []
                    navigate('/admin/users')
                }
            }, true);
            
        }
    }

    const getUsersData = () => {

        getJsonData(USER.USER_EDIT +'/'+ user_id, (res) => {
            if(res.status){
                setUserData(res.data);
                if (res.data.permission){
                    setPermission(res.data.permission.split(",").map(String))
                }
                for (let i = 0; i < res.data.assign_form.length; i += 1) {
                    checkedFormArr.push(res.data.assign_form[i].form_id)
                    resultArray.push(res.data.assign_form[i].form_id)
                }
            }
        }, true);        

    }

    const getForm = () => {
        showloader(true)
        getJsonData(AUTH.GET_USER_FORM, (res) => {
            showloader()
            if(res.status){
                setUserForm(res.data)
            }
        }, true);
    }


    useEffect(() => {
        getForm()
        getUsersData()
    }, [])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Edit User</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form className="row g-3 g-sm-4" mthod="post" id="edit_user">
                        <div className="col-6">
                            <label htmlFor="farm" className="form-label">User Type<strong className="text-danger">*</strong></label>
                            <select id="uset_type" name="user_type" className="form-select form-select-lg" value={user_data && user_data.role_id} required>
                                <option value={2}>User</option>
                                <option value={1}>Admin</option>
                            </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="old_password" className="form-label">Name <strong className="text-danger">*</strong></label>
                            <input type="text" name="name" id="name" className="form-control" defaultValue={user_data && user_data.name} placeholder="Name" required />
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Email <strong className="text-danger">*</strong></label>
                            <input type="email" name="email" id="email" className="form-control" placeholder="Email" defaultValue={user_data && user_data.email} required />
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input type="password" name="password" id="password" className="form-control" placeholder="Password" defaultValue={user_data && user_data.password}  />
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Password Confirmation</label>
                                <input type="password" name="password_confirmation" id="password_confirmation" className="form-control" placeholder="Password Confirmation" defaultValue={user_data && user_data.password_confirmation}  />
                        </div>
                        {(user_data && user_data.role_id === 2) ?
                            <>
                                <label htmlFor="password" className="form-label">User Assign Form <strong className="text-danger">*</strong></label>
                                <div className="form-check col-md-3">
                                    { checkedFormArr ?(
                                            userform ? userform.map((form, key) =>
                                                <div key={key}>
                                                    <label className="form-check">
                                                        <input className="form-check-input" name="userform[]" type="checkbox" defaultChecked={checkedFormArr.includes(form.id) ? true : false} onChange={(e) => onFormChange(e, form.id)} value={form.id} />
                                                        <div className="form-check-label small">
                                                            {form.name}
                                                        </div>
                                                    </label>
                                                </div>
                                            ) : ''
                                        ) : ''
                                    }
                                </div>
                            </>
                            : ''
                        }
                        
                        {(user_data.role_id === 1) &&
                            <>
                                <label htmlFor="password" className="form-label">Admin User Permission <strong className="text-danger">*</strong></label>
                                <div className="form-check">
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" defaultChecked={permissions.includes("view")?true:false} value={"view"} onChange={(e) => onChangeValue(e)} />
                                        <label className="form-check-label small" >View</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" defaultChecked={permissions.includes("add") ? true : false} value="add" onChange={(e) => onChangeValue(e)} />
                                        <label className="form-check-label small">Add</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" defaultChecked={permissions.includes("update") ? true : false}value="update" onChange={(e) => onChangeValue(e)} />
                                        <label className="form-check-label small">Update</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" defaultChecked={permissions.includes("delete") ? true : false} value="delete" onChange={(e) => onChangeValue(e)} />
                                        <label className="form-check-label small">Delete</label>
                                    </div>
                                </div>
                            </>
                        }
                        { permission(context && context.auth.permission, 'update') &&
                            <div className="mb-4 mb-sm-5">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Update</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditUser;