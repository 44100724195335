import React from 'react';
import StockList from '../Inventory/StockList';
function CBDAStock() {

    window.document.title = 'Cbda Crude Oil Inventory Stock Log'

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">CBDA Crude Oil Inventory Stock Log</h2>
                </div>
                <StockList inventory_id="3"/>
            </div>
        </div>
    );
}

export default CBDAStock;
