import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import Routing from './components/Routing'
import Icon from './components/Icon';
import './index.scss';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
        <Routing/>
    </Router>
    <Icon />
  </React.StrictMode>
);

reportWebVitals();

