import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal, floatNumber, momentDateDisplay, permission, showloader, toastAlert } from '../../components/Helper';
import Filter from '../../components/Filter';
import ExportData from '../../components/ExportData';
import Context from '../../components/Context';
import { EXTRACTION } from '../../components/ApiUrl';
import { DataList } from '../../components/DataList';
import EditRequest from '../../components/EditRequest';
import _, { now } from 'lodash';
import { getJsonData } from '../../fetch-data';

const PreExtractionList = () => {

    window.document.title = 'Biomass Pre Extraction List'
    const [context] = useContext(Context)
    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setDeleteRecord] = useState('')
    const [filter, setFilter] = useState('')

    const [preBoimassBag, setPreBoimassBag] = useState({ 
        export:{
            data: 'Biomass_Pre_Extraction',
            type: 'xls',
        }
    })    

    const dt = {
        id: 'pre-extraction-list',
        fetchurl: EXTRACTION.PRE_LIST,
        columns: [
            { name: 'id', title: 'Id', width: 10 },
            { name: 'recorder_by', title: 'Recorded by' },
            { name: 'bag_num', title: 'Bag Number', width: 150},
            { name: 'weight_inlbs', title: 'Bag Weight (lbs)', visible : context && context.auth && context.auth.role_id === 2 ? true : false },
            { name: 'date_time', title: 'Date Time', width: 100},
            { name: 'action', title: 'Action', width: 10 },
        ],
        filter_string: filter ? (filter.user_id && filter.date ? 'user_id='+filter.user_id+'&date='+filter.date : (filter.user_id ? 'user_id='+filter.user_id : filter.date && 'date='+filter.date)) : ''
    }

    const deletePreExtractionRecord = (id) => {
        showloader(true)
        getJsonData(EXTRACTION.PRE_LIST_DELETE + '/' + id, (res) => {
            showloader()
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if(res.status){
                setRefresh(now)
            }
        }, true);
    }

    useEffect(() => {
       
    }, [refresh, filter]) 

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Biomass Pre Extraction List</h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh ? refresh : filter}
                        outerbutton={(records) =>
                            <div className="ms-auto">
                                { context && context.auth && context.auth.role_id === 1 ? <>
                                    <ExportData export_data={preBoimassBag.export}/>
                                    <Filter filter_type="DateUser" user_list={preBoimassBag.users_list} onFilterChange={setFilter} /></>
                                :
                                    <Link to="/user/pre-extraction"  className="btn btn-sm btn-success">
                                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                        Back
                                    </Link> 
                                }
                            </div>
                        }
                        edit_column={{

                            weight_inlbs: (record) => 
                                <span>
                                    {floatNumber(record.weight_inlbs)}
                                </span>,
                            date_time: (record) => 
                                <span>
                                    {momentDateDisplay(record.date_time, true, true)}
                                </span>,

                            action: (record) =>
                                <div className="d-flex">
                                    { context && context.auth.role_id === 1 && permission(context.auth.permission, 'delete') &&
                                        <button className="btn btn-sm btn-outline-danger" data-bs-target="#deletesection_modal" data-bs-toggle="modal" title="Delete" onClick={() => setDeleteRecord(record.id)}  >
                                            <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                        </button>
                                    }
                                    {context && context.auth && context.auth.role_id === 2 && (record.request_status === 0 || record.request_status === 3 || record.request_status === 4) &&
                                        <EditRequest form_id="2" record_id={record.id} update_table={setRefresh} />
                                    }
                                    { context && context.auth.role_id === 2 && record.request_status === 2 &&
                                        <Link to="/user/pre-extraction" state={record.id} className="btn btn-sm btn-outline-primary ms-1">
                                            <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                        </Link> 
                                    }
                                </div>
                        }}
                    />
                    <ConfirmationModal msg="Are you sure for delete this record?" method={() => deletePreExtractionRecord(deleteRecord ? deleteRecord : '')} />
                </div>
            </div>
        </div>
    )
}

export default PreExtractionList;


    