import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ExportData from '../../components/ExportData';
import Context from '../../components/Context';
import { DataList } from '../../components/DataList';
import { BIOMASS } from '../../components/ApiUrl';
import Filter from "../../components/Filter";
import { floatNumber, momentDateDisplay, ConfirmationModal, permission } from '../../components/Helper';
import { getJsonData } from '../../fetch-data';
import _, { now } from 'lodash';
import EditRequest from '../../components/EditRequest';

const BiomassBaggingList = () => {
    window.document.title = 'Biomass Bagging'

    const location = useLocation()
    const logDate = location.state;

    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setDeleteRecord] = useState('')
    const [context] = useContext(Context)
    const [filter, setFilter] = useState('')

    const [boimassBag, setBoimassBag] = useState({ 
        export:{
            data: 'Biomass_Bagging',
            type: 'xls',
        },
        users_list: '',
    })
    
    const dt = {
        id: 'biomass-bagging-list',
        fetchurl: BIOMASS.LIST,
        columns: [
            { name: 'id', title: 'Id', width: 10 },
            { name: 'recorder_by', title: 'Recorded by' },
            { name: 'bag_num', title: 'Bag Number', width: 150},
            { name: 'weight', title: 'Bag Weight (lbs)', width: 50 },
            { name: 'date_time', title: 'Date Time', width: 100},
            { name: 'current_status', title: 'Processed' },
            { name: 'action', title: 'Action', width: 10 },
        ],
        filter_string: filter ? (filter.user_id && filter.date ? 'user_id='+filter.user_id+'&date='+filter.date : (filter.user_id ? 'user_id='+filter.user_id : filter.date && 'date='+filter.date)) : logDate ? "date="+logDate : ''
    }
 

    const deleteBiomassRecord = (id) => {
        if(id){
            getJsonData(BIOMASS.DELETE + '/' + id, (res) => {
                document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
                if(res){
                    setRefresh(now)
                }
            }, true);
        }
    }

    useEffect(() => {
         
    }, [refresh, filter])  

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Biomass Bagging</h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh ? refresh : filter}
                        outerbutton={(records) =>
                            <div className="ms-auto">
                                { context && context.auth && context.auth.role_id === 1 ? 
                                <>
                                    <ExportData export_data={boimassBag.export}/>

                                    <Filter filter_type="DateUser" user_list={boimassBag.users_list} onFilterChange={setFilter} /></>
                                :
                                    <Link to="/user/biomass-bagging"  className="btn btn-sm btn-success">
                                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                        Back
                                    </Link> 
                                }
                            </div>
                        }
                        edit_column={{

                            bag_num: (record) => 
                                <span>
                                    {`${record.bag_num} (Week: ${record.bag_week} Bag Number: ${record.num})`}
                                </span>,
                            weight: (record) => 
                                <span>
                                    {floatNumber(record.weight)}
                                </span>,
                            date_time: (record) => 
                                <span>
                                    {momentDateDisplay(record.date_time, true, true)}
                                </span>,
                            current_status: (record) =>
                                <span>
                                    {record.current_status === 0 ? 'Bag' : (record.current_status === 1 ? 'Pre Extraction' : 'Post Extraction')}
                                </span>,

                            action: (record) =>
                                <div className="d-flex">
                                    { context && context.auth.role_id === 1 && permission(context && context.auth.permission, 'delete') &&
                                        <button className="btn btn-sm btn-outline-danger" data-bs-target="#deletesection_modal" data-bs-toggle="modal" title="Delete" onClick={() => setDeleteRecord(record.id)}  >
                                            <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                        </button>
                                    }
                                    { context && context.auth.role_id === 2 && record.current_status === 0 && record.request_status === 2 &&
                                        <Link to="/user/biomass-bagging" state={record.id} className="btn btn-sm btn-outline-primary m-1">
                                            <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                        </Link> 
                                    }
                    
                                    { context && context.auth.role_id === 2 && (record.current_status === 0 && (record.request_status === 0 || record.request_status === 3 || record.request_status === 4)) &&
                                        <EditRequest form_id="1" record_id={record.id} update_table={setRefresh} />
                                    }

                                </div>
                        }}
                    />
                    <ConfirmationModal msg="Are you sure for delete this record?" method={() => deleteBiomassRecord(deleteRecord ? deleteRecord : '')} />
                </div>
            </div>
        </div>
    )
}

export default BiomassBaggingList;