import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { permission, showloader } from './Helper';
import Context from './Context';
import { getJsonData } from '../fetch-data';
import { AUTH, EDIT_REQUEST } from './ApiUrl';

const Header = () => {
    const { pathname } = useLocation()

    const [context, setContext] = useContext(Context)
    const navigate = useNavigate();
    const [editRequest, setEditRequest] = useState(0)

    const handleLogout = (e) => {
        getJsonData(AUTH.LOGOUT, (res) => {
            if (res.status) {
                localStorage.removeItem("token");
                setContext('')
                navigate('/')
            }
        }, true)
    }

    const changePassword = (e) => {
        e.preventDefault();
        navigate('/user/change-password');
    }

    const menuCollapse = () => {
        document.body.classList.toggle('menu-toggle');
    }

    useEffect(() => {
        if (context && context.auth && context.auth.role_id === 1) {
            showloader(true)
            getJsonData(EDIT_REQUEST.COUNT, (res) => {
                showloader()
                if (res.status) {
                    setEditRequest(res.data.edit_request_count)
                }
            }, true)
        }
    }, [context])

    return (
        <>
            {context && context.auth &&
                <div className="top-bar">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="menu-collapse" onClick={menuCollapse}>
                                    <svg className="icon"><use href="#icon_stream"></use></svg>
                                </div>
                            </div>
                            <div className="col-6 col-dropdown">
                                <div className="d-flex align-items-center justify-content-end">
                                    {context.auth.role_id === 1 &&
                                        <Link title="New Edit Request" to="/admin/editrequests" className="position-relative text-primary me-4">
                                            <svg className="icon icon-2x"><use href="#icon_bellfill"></use></svg>
                                            {editRequest > 0 && <span className="position-absolute mt-0 top-0 start-100 translate-middle badge rounded-pill fs-10 ls-1 bg-danger">{editRequest}</span>}
                                        </Link>
                                    }
                                    <div className="dropdown d-flex justify-content-end">
                                        <button className="btn-username dropdown-toggle pe-0" type="button" data-bs-toggle="dropdown">
                                            <svg className="icon"><use href="#icon_user"></use></svg>
                                            <span>{context.auth.name}</span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end border-0 shadow-lg" aria-labelledby="dropdownMenuButton">
                                            {context.auth.role_id === 2 && <><li>
                                                <button type="button" className="dropdown-item py-2" onClick={changePassword}>
                                                    <svg className="icon me-3"><use href="#icon_lock"></use></svg>
                                                    Change Password
                                                </button>
                                            </li>
                                                <li><hr className="dropdown-divider" /></li></>}
                                            <li>
                                                <button type="button" className="dropdown-item py-2" onClick={(e) => handleLogout(e)}>
                                                    <svg className="icon me-3"><use href="#icon_logout"></use></svg>
                                                    Logout
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="aside-bar">
                {context && context.auth && context.auth.role_id === 1 && <>
                    <div className="aside-logo">
                        <Link to="/" >
                            <img src="/images/logo.png" alt="Axtellab-Logo" />
                        </Link>
                    </div>
                    <ul className="aside-menu" id="leftAccordion">
                        <li>
                            <Link title="Dashboard" to="/admin/dashboard" className={['/admin/dashboard'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_dashboard" /></svg>
                                Dashboard
                            </Link>
                        </li>
                        <li>
                            <Link title="Edit Request" to="/admin/editrequests" className={['/admin/editrequests'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_list" /></svg> Edit Request
                            </Link>
                        </li>
                        <li>
                            <Link title="Operations" to="/admin/operations-list" className={['/admin/operations', '/admin/operations-list'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                Operations
                            </Link>
                        </li>
                        <li>
                            <div className="left-card">
                                <div className="left-link">
                                    <a href="#collapse4" data-bs-toggle="collapse" className={['/admin/post-extraction-list', '/admin/pre-extraction-list', '/admin/biomass-bagging-list', '/admin/stock-hemp', '/admin/hemp', '/admin/add-hemp'].includes(pathname) ? 'collapsed-more active' : 'collapsed-more collapsed'}>
                                        <svg className="icon"><use xlinkHref="#icon_bag" /></svg>Biomass
                                    </a>
                                </div>
                                <div id="collapse4" className={['/admin/post-extraction-list', '/admin/pre-extraction-list', '/admin/biomass-bagging-list', '/admin/stock-hemp', '/admin/hemp', '/admin/add-hemp'].includes(pathname) ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                    <ul className="left-sublink">
                                        <li>
                                            <Link title="Biomass Bagging" to="/admin/biomass-bagging-list" className={['/admin/biomass-bagging', '/admin/biomass-bagging-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_bag" /></svg>
                                                Bagging
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Biomass Pre Extraction" to="/admin/pre-extraction-list" className={['/admin/pre-extraction-list', '/admin/pre-extraction'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_prearrow" /></svg>
                                                Pre Extraction
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Biomass Post Extraction" to="/admin/post-extraction-list" className={['/admin/post-extraction', '/admin/post-extraction-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_postarrow" /></svg>
                                                Post Extraction
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Hemp Inventory" to="/admin/hemp" className={['/admin/hemp', '/admin/add-hemp'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>Inventory
                                            </Link>
                                        </li>

                                        <li>
                                            <Link title="Hemp Inventory Stock Log" to="/admin/stock-hemp" className={['/admin/stock-hemp'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_filldrip" /></svg>Inventory Stock
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div className="left-card">
                                <div className="left-link">
                                    <a href="#collapse3" data-bs-toggle="collapse" className={['/admin/cbda-crude-oil-list', '/admin/cbda-crude-oil-stock', '/admin/cbda-crude-oil-spend-list'].includes(pathname) ? 'collapsed-more collapsed active' : 'collapsed-more collapsed'}>
                                        <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>CBDa Crude Oil
                                    </a>
                                </div>
                                <div id="collapse3" className={['/admin/cbda-crude-oil-list', '/admin/cbda-crude-oil-stock', '/admin/cbda-crude-oil-spend-list'].includes(pathname) ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                    <ul className="left-sublink">
                                        <li>
                                            <Link title="CBDa Crude Oil Inventory" to="/admin/cbda-crude-oil-list" className={['/admin/cbda_crude_oil', '/admin/cbda-crude-oil-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>
                                                Inventory
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="CBDa Crude Oil Stock Log" to="/admin/cbda-crude-oil-stock" className={['/admin/cbda-crude-oil-stock'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>
                                                Inventory Stock
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="CBDa Crude Oil Spend" to="/admin/cbda-crude-oil-spend-list" className={['/admin/cbda_crude_oil_spend', '/admin/cbda-crude-oil-spend-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_filldrip" /></svg>
                                                Spend
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li>
                            <Link title="Final Inventory" to="/admin/add-barcode" className={['/admin/add-barcode', '/admin/barcode', '/admin/edit-barcode', '/admin/released-barcode-list'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_bag" /></svg>
                                Final Inventory
                            </Link>
                        </li>

                        <li>
                            <div className="left-card">
                                <div className="left-link">
                                    <a href="#collapse2" data-bs-toggle="collapse" className={['/admin/solvent-spend-list', '/admin/solvent-list', '/admin/solvent-stock', '/admin/add-solvent'].includes(pathname) ? 'collapsed-more collapsed active' : 'collapsed-more collapsed'}>
                                        <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>Solvent
                                    </a>
                                </div>
                                <div id="collapse2" className={['/admin/solvent-spend-list', '/admin/solvent-list', '/admin/solvent-stock', '/admin/add-solvent', '/admin/solvent-list'].includes(pathname) ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                    <ul className="left-sublink">
                                        <li>
                                            <Link title="Solvent Inventory" to="/admin/solvent-list" className={['/admin/solvent-list', '/admin/add-solvent'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>Inventory
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Solvent Stock Log" to="/admin/solvent-stock" className={['/admin/solvent-stock'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>Inventory Stock
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Solvent Spend" to="/admin/solvent-spend-list" className={['/admin/solvent_spend', '/admin/solvent-spend-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_postarrow" /></svg>Spend
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li>
                            <Link title="Bulk Inventory" to="/admin/bulk-inventory-list" className={['/admin/bulk-inventory', '/admin/bulk-inventory-list'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>
                                Bulk Inventory
                            </Link>
                        </li>

                        <li>
                            <div className="left-card">
                                <div className="left-link">
                                    <a href="#invoiceCollapse" data-bs-toggle="collapse" className={['/admin/invoice', '/admin/invoice-list', '/admin/invoice_view', '/admin/invoice-setting'].includes(pathname) || (pathname.match(/^.*invoice_view.*$/)) ? 'collapsed-more collapsed active' : 'collapsed-more collapsed'}>
                                        <svg className="icon"><use xlinkHref="#icon_request-edit" /></svg>Invoice
                                    </a>
                                </div>
                                <div id="invoiceCollapse" className={['/admin/invoice', '/admin/invoice-list', '/admin/invoice_view', '/admin/invoice-setting'].includes(pathname) || (pathname.match(/^.*invoice.*$/)) ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                    <ul className="left-sublink">
                                        <li>
                                            <Link title="Invoice" to="/admin/invoice" className={(pathname === '/admin/invoice') && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon-message" /></svg>
                                                Invoice
                                            </Link>
                                        </li>

                                        <li>
                                            <Link title="Invoice List" to="/admin/invoice-list" className={(pathname === '/admin/invoice-list') && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_postarrow" /></svg>
                                                Invoice List
                                            </Link>
                                        </li>

                                        <li>
                                            <Link title="Invoice Settings" to="/admin/invoice-setting" className={(pathname === '/admin/invoice-setting') && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Invoice Settings
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li>
                            <Link title="Testing Center" to="/admin/testing-center" className={['/admin/testing-center', '/admin/testing-center-list'].includes(pathname) && 'active'}>
                                <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>
                                Testing Center
                            </Link>
                        </li>

                        <li>
                            <div className="left-card">
                                <div className="left-link">
                                    <a href="#collapse5" data-bs-toggle="collapse" className={['/admin/settings', '/admin/material-type-list', '/admin/storage-type-list', '/admin/users', '/admin/add-user', '/admin/storage-type', '/admin/material-type', '/admin/user-activity', '/admin/list-wastage', '/admin/solvent-type', '/admin/solvent-type-list'].includes(pathname) || pathname.match('/admin/edit-user') ? 'collapsed-more active' : 'collapsed-more collapsed'}>
                                        <svg className="icon"><use xlinkHref="#icon_setting" /></svg>Settings
                                    </a>
                                </div>
                                <div id="collapse5" className={['/admin/settings', '/admin/material-type-list', '/admin/storage-type-list', '/admin/users', '/admin/add-user', '/admin/storage-type', '/admin/material-type', '/admin/net-term', '/admin/net-term-list', '/admin/test-type', '/admin/test-type-list', '/admin/user-activity', '/admin/list-wastage', '/admin/solvent-type', '/admin/solvent-type-list'].includes(pathname) || pathname.match('/admin/edit-user') ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                    <ul className="left-sublink">
                                        <li>
                                            <Link title="Users List" to="/admin/users" className={['/admin/users', '/admin/add-user', '/admin/edit-user'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_user" /></svg>
                                                Users List
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Dashboard Threshold" to="/admin/settings" className={(pathname === '/admin/settings') && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Dashboard Threshold
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Storage Type" to="/admin/storage-type" className={['/admin/storage-type', '/admin/storage-type-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Storage Type
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Material Type" to="/admin/material-type" className={['/admin/material-type', '/admin/material-type-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Material Type
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Solvent Type" to="/admin/solvent-type" className={['/admin/solvent-type', '/admin/solvent-type-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Solvent Type
                                            </Link>
                                        </li>
                                        <li>
                                            <Link title="Test Type" to="/admin/test-type" className={['/admin/test-type', '/admin/test-type-list'].includes(pathname) && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Test Type
                                            </Link>
                                        </li>
                                        {
                                            (permission(context.auth.permission, 'add') && permission(context.auth.permission, 'update') && permission(context.auth.permission, 'view') && permission(context.auth.permission, 'delete')) ?
                                                <li>
                                                    <Link title="User Activity" to="/admin/user-activity" className={(pathname === '/admin/user-activity') && 'active'}>
                                                        <svg className="icon"><use xlinkHref="#icon_list" /></svg>
                                                        User Activity
                                                    </Link>
                                                </li>
                                                : ''
                                        }

                                        <li>
                                            <Link title="Wastage List" to="/admin/list-wastage" className={(pathname === '/admin/list-wastage') && 'active'}>
                                                <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                                Wastage List
                                            </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </>
                }

                {context && context.auth && context.auth.assign_forms && context.auth.role_id === 2 &&
                    <>
                        <div className="aside-logo">
                            <Link to="/" >
                                <img src="/images/logo.png" alt="Axtellab-Logo" />
                            </Link>
                        </div>
                        <ul className="aside-menu">
                            {context.auth.assign_forms.includes(1) && <li>
                                <Link title="Biomass Bagging" to="/user/biomass-bagging" className={['/user/biomass-bagging', '/user/biomass-bagging-list'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_bag" /></svg>
                                    Biomass Bagging
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(2) && <li>
                                <Link title="Biomass Pre Extraction" to="/user/pre-extraction" className={['/user/pre-extraction-list', '/user/pre-extraction'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_prearrow" /></svg>
                                    Biomass Pre Extraction
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(3) && <li>
                                <Link title="Biomass Post Extraction" to="/user/post-extraction" className={['/user/post-extraction', '/user/post-extraction-list'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_postarrow" /></svg>
                                    Biomass Post Extraction
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(8) && <li>
                                <Link title="Solvent Spend" to="/user/solvent-spend" className={['/user/solvent-spend', '/user/solvent-spend-list'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_flask2" /></svg>
                                    Solvent Spend
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(4) && <li>
                                <div className="left-card">
                                    <div className="left-link">
                                        <a href="#collapse3" data-bs-toggle="collapse" className={['/user/cbda-crude-oil', '/user/cbda-crude-oil-list', '/user/cbda-crude-oil-spend', '/user/cbda-crude-oil-spend-list'].includes(pathname) ? 'collapsed-more collapsed active' : 'collapsed-more collapsed'}>
                                            <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>CBDa Crude Oil
                                        </a>
                                    </div>
                                    <div id="collapse3" className={['/user/cbda-crude-oil', '/user/cbda-crude-oil-list', '/user/cbda-crude-oil-spend', '/user/cbda-crude-oil-spend-list'].includes(pathname) ? 'collapse show' : 'collapse'} data-bs-parent="#leftAccordion">
                                        <ul className="left-sublink">
                                            <li>
                                                <Link title="CBDa Crude Oil Inventory" to="/user/cbda-crude-oil" className={['/user/cbda-crude-oil', '/user/cbda-crude-oil-list'].includes(pathname) && 'active'}>
                                                    <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>
                                                    Inventory
                                                </Link>
                                            </li>
                                            <li>
                                                <Link title="CBDa Crude Oil Spend" to="/user/cbda-crude-oil-spend" className={['/user/cbda-crude-oil-spend', '/user/cbda-crude-oil-spend-list'].includes(pathname) && 'active'}>
                                                    <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>
                                                    Spend
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>}
                            {context.auth.assign_forms.includes(6) && <li>
                                <Link title="Bulk Inventory" to='/user/bulk-inventory' className={['/user/bulk-inventory', '/user/bulk-inventory-list'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_cubes" /></svg>
                                    Bulk Inventory
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(9) && <li>
                                <Link title="Shipping" to="/user/shipping" className={['/user/shipping'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                    Shipping
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(7) && <li>
                                <Link title="Operations" to="/user/operations" className={['/user/operations', '/user/operations_list'].includes(pathname) && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_setting" /></svg>
                                    Operations
                                </Link>
                            </li>}
                            {context.auth.assign_forms.includes(10) && <li>
                                <Link title="Users List" to="/user/users-list" className={(pathname === '/user/users-list') && 'active'}>
                                    <svg className="icon"><use xlinkHref="#icon_user" /></svg>
                                    Users List
                                </Link>
                            </li>}
                        </ul>
                    </>}
                <div className="admin-copyrightsbox">Copyright © 2022 – Axtell Labs | v3.0.0</div>
            </div>
        </>
    )
}

export default Header;