import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal, floatNumber, momentDateDisplay, permission, showloader } from '../../components/Helper';
import ExportData from '../../components/ExportData';
import Context from '../../components/Context';
import EditRequest from '../../components/EditRequest';
import Filter from '../../components/Filter';
import { DataList } from '../../components/DataList';
import { now } from 'lodash';
import { INVENTORY } from '../../components/ApiUrl';
import { getJsonData } from '../../fetch-data';

const SpendList = (props) => {
    const [context] = useContext(Context);
    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setDeleteRecord] = useState('')
    const [filter, setFilter] = useState()

    const [solvent] = useState({ 
        list_data:'',
        filter: {inventory: props.inventory_id},
        export:{
            data: props.inventory_id == 2 ? 'Solvent_Spend' : 'CBDa_Crude_Oil_Spend',
            type: 'xls'
        },
        solvent_list: props.solvent_list,
        unit_type: props.inventory_id == 2 ? props.solvent_list[0].units : 'kg'
    })

    const dt = {
        id: 'inventory-spend-list',
        fetchurl: INVENTORY.SPEND_LIST,
        columns: [
            { name: 'id', title: 'Id', width: 10 },
            { name: 'recorded_by', title: 'Recorded by' },
            { name: 'log_datetime', title: 'Date Time', width: 100},
            { name: 'weight', title: `Weight (${solvent.unit_type})`, visible : context && context.auth && context.auth.role_id === 2 ? true : false },
            { name: 'action', title: 'Action', width: 40},
        ],
        filter_string: 'inventory=' + (filter && Object.keys(filter).length > 0 ? filter.material_id : (props.inventory_id == 2 ? (props.solvent_list ? props.solvent_list[0].id : '') :props.inventory_id)),
    }

    const deleteSpendListRecord = (id) => {
        showloader(true)
        getJsonData(INVENTORY.DELETE_SPEND + '/' + id, (res) => {
            showloader()
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.status) {
                setRefresh(now)
            }
        }, true);
    }

    useEffect(() => {  
    }, [refresh, filter])

    return (
        <>
            <div className="col-md-12 my-2">
                <DataList
                    dt={dt}
                    refresh={refresh ? refresh : filter}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                            { context && context.auth.role_id === 1 && 
                                <ExportData export_data={solvent.export} inventory_id={solvent.filter.inventory} unit_type={solvent.unit_type} /> 
                            }
                            { props.inventory_id == 2 && 
                                <>
                                    <Filter filter_type="Material" material_list={props.solvent_list} onFilterChange={setFilter} />
                                </> 
                            }
                            { context && context.auth.role_id === 2 && 
                                <Link to={props.inventory_id == 2 ? '/user/solvent-spend' : '/user/cbda-crude-oil-spend'}  className="btn btn-sm btn-success ms-1 mb-1"><svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg> Back</Link> 
                            }
                        </div>
                    }
                    edit_column={{

                        weight: (record) => 
                            <span>
                                {floatNumber(record.weight)}
                            </span>,
                        log_datetime: (record) => 
                            <span>
                                {momentDateDisplay(record.log_datetime, true, true)}
                            </span>,

                        action: (record) =>
                            <div className="d-flex">
                                { context && context.auth.role_id === 1 && permission(context.auth.permission, 'delete') &&
                                    <button className="btn btn-sm btn-outline-danger" data-bs-target="#deletesection_modal" data-bs-toggle="modal" title="Delete" onClick={() => setDeleteRecord(record.id)}  >
                                        <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                    </button>
                                }
                                { props.inventory_id == 2 && context && context.auth.role_id === 2 && (record.status === 0 || record.status === 3 || record.status === 4) && 
                                    <EditRequest form_id="8" record_id={record.id} update_table={setRefresh} /> 
                                }
                                { props.inventory_id == 3 && context && context.auth.role_id === 2 && (record.status === 0 || record.status === 3 || record.status === 4) && 
                                    <EditRequest form_id="5" record_id={record.id} update_table={setRefresh} /> 
                                }
                                { props.inventory_id == 2 && context && context.auth.role_id === 2 && record.status === 2 &&
                                    <Link to="/user/solvent-spend" state={record} className="btn btn-sm btn-outline-primary m-1">
                                        <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                    </Link> 
                                }
                                { props.inventory_id == 3 && context && context.auth.role_id === 2 && record.status === 2 &&
                                    <Link to="/user/cbda-crude-oil-spend" state={record.id} className="btn btn-sm btn-outline-primary m-1">
                                        <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                    </Link> 
                                }
                            </div>
                    }}
                />
                <ConfirmationModal msg="Are you sure for delete this record?" method={() => deleteSpendListRecord(deleteRecord ? deleteRecord : '')} />
            </div>
        </>
    )
}

export default SpendList;