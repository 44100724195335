import SpendList from '../Inventory/SpendList';

const CrudeOilSpendList = () => {

    window.document.title = 'Cbda Crude Oil Spend List'

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">CBDa Crude Oil Spend List</h2>
                </div>
                <SpendList inventory_id={3} />
            </div>
        </div>
    )
}

export default CrudeOilSpendList;