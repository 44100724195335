import React, { useEffect, useState,useContext } from 'react';
import { Modal } from '../../../node_modules/bootstrap/dist/js/bootstrap.esm';
import { Link } from 'react-router-dom';
import { ConfirmationModal, floatNumber, initialFormState, momentDate, momentDateDisplay, permission, showloader, toastAlert, validateForm, downloadPdf} from '../../components/Helper';
import QRCode from 'qrcode.react';
import ExportData from '../../components/ExportData';
import { ASSET_ENDPOINT, BARCODE } from '../../components/ApiUrl';
import { DataList } from '../../components/DataList';
import Context from '../../components/Context';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {now} from 'lodash'
import { getJsonData, postData } from '../../fetch-data';

var resultArray = []

const ListBarcode = () =>  { 

    window.document.title = 'Barcode List';
    
    const [context] = useContext(Context)
    const [refresh, setRefresh] = useState('')
    const [client_name, setClientName] = useState('')
    const [release_date, setReleaseDate] = useState('')
    const [release_time, setReleaseTime] = useState('')
    const [exportData] = useState({data: 'Final_Inventory',type: 'xls'})
    const [inspected_date, setInspectedDate] = useState(momentDate('', false, false, 'MMMM DD, Y', true))
    const [inspected_by, setInspectBy] = useState('')
    const [printbarcode, setPrintBarCode] = useState('')
    const [deleteRecord, setDeleteRecord] = useState('')
    const [barcodeArray, setBarcodeArray] = useState([])

    const dt = {
        id: 'barcode-generator-list',
        fetchurl: BARCODE.BARCODE_GENERATE_LIST,
        columns: [
            { name: 'checkbox', title: 'Checkbox', sorting: false},
            { name: 'id', title: 'ID' },
            { name: 'batch_id', title: 'Batch ID'},
            { name: 'prduction_date', title: 'Production Date'},
            { name: 'storage', title: 'Storage', width: 100},
            { name: 'storage_type', title: 'Storage Type', width: 10 },
            { name: 'material_type', title: 'Material Type', width: 160 },
            { name: 'weight', title: 'Weight', width: 10 },
            { name: 'status', title: 'Status', width: 10 },
            { name: 'attachment', title: 'Attachment', width: 10 },
            { name: 'barcode', title: 'Barcode', width: 10, sorting: false },
            { name: 'action', title: 'Action', width: 100 },
        ]
    }

    const deleteBarcodeRecord = (id) => {
        showloader(true)
        getJsonData(BARCODE.DELETE_BARCODE + '/' + id, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
                setRefresh(now)
            }
        }, true);
    }

    const storeId = (e,id) => {
        if (e.target.checked) {
            setBarcodeArray((prev) => ([...prev , id]))
        }else  { 
            var value_index = barcodeArray.indexOf(id);
            barcodeArray.splice(value_index, 1)
            setBarcodeArray(barcodeArray)
        } 
    }
    
    const changeDateFilter = (date) => {
        setInspectedDate(momentDateDisplay(date, false, false,'MMMM DD, Y'));
    }

    const performAction = (data) => {
        showloader(true)
        postData(BARCODE.BARCODE_RELEASE, data, (res) => {
            showloader()
            document.querySelector('#barcodeStatusModel [data-bs-dismiss="modal"]').click()
            if(res.status){
                setClientName('')
                setReleaseDate('')
                setReleaseTime('')
                setRefresh(now)
            }
        }, true);    
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateForm(e, 'barcodeRelease')) {
            if(barcodeArray.length > 0){
                let relData = {
                    'id': barcodeArray,
                    'status': 1,
                    'client_name': client_name,
                    'release_date': release_date,
                    'release_time': release_time
                }
                performAction(relData)
                initialFormState('barcodeRelease')
            }
            else{
                toastAlert('error', 'Please select barcode.');
                return;
            }
            setBarcodeArray([]);
            document.querySelectorAll('[name="barcodeIds"]').forEach(el => el.checked = false)
        }
        
    }

    const updateInspectionDetail = (e) => {
        e.preventDefault();

        downloadPdf('printbarcode', 'axtellPdf', 'A4', 'portrait');
        
        if (barcodeArray.length > 0) {
            let insData = {
                'id': barcodeArray,
                'status': 2,
                'inspected_by': inspected_by,
                'inspected_date': inspected_date
            }
            performAction(insData)
        }
        else {
            toastAlert('error', 'Please select barcode.');
            return;
        }
    }

    const printSelected = (e) => {
        e.preventDefault()
        if(validateForm(e, 'inspectedDetail')){
            if(barcodeArray.length > 0){
                if (!inspected_by){
                    toastAlert('error', 'Please enter inspector name.');
                    return;
                }else{
                    showloader(true)
                    postData(BARCODE.BARCODE_PRINT, {id: barcodeArray}, (res) => {
                        showloader()
                        if(res.status){
                            setPrintBarCode(res.data)
                            initialFormState('inspectedDetail');
                            document.querySelector('#inspectedDetail [data-bs-dismiss="modal"]').click()

                            var QRPrintModal = new Modal(document.getElementById('QRPrintModal'), {
                                keyboard: false
                            })

                            QRPrintModal.show()
                            setRefresh(now)
                        }
                    }, true);
                }
            }else{
                toastAlert('error', 'Please select barcode.');
            }
        }
    }


    useEffect(() => {
        // const script = document.createElement("script"); script.async = true;
        // script.src = `${process.env.MIX_APP_URL}js/pdf.js`;
        // document.body.appendChild(script);

    }, [refresh]);

    
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Barcode List</h2>
                </div>
                
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(records) =>
                            <div className="col-sm-12 d-flex text-start">
                                <div className="col-sm-6">
                                    <button className="btn btn-sm btn-dark ms-2 mb-1" data-bs-toggle="modal" data-bs-target="#inspectedDetailModel">
                                        <svg className="icon me-2"><use xlinkHref="#icon_request-edit" /></svg>
                                        Print Selected Barcode
                                    </button>
                                </div>
                                <div className="col-sm-6 text-end">
                                    <Link to="/admin/released-barcode-list" className="btn btn-sm btn-success me-2 mb-1">
                                        <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                                        Barcode Released  List
                                    </Link>
                                    <button  data-bs-toggle="modal" data-bs-target="#barcodeStatusModel" className="btn btn-sm btn-primary me-2 mb-1">
                                        <svg className="icon me-2"><use xlinkHref="#icon_bag" /></svg>
                                        Release Barcode
                                    </button>
                                    <ExportData export_data={exportData}/>
                                </div>
                            </div>
                        }

                        edit_column={{

                            prduction_date: (record) =>
                                <span>
                                    {record && momentDateDisplay(record.prduction_date)}
                                </span>,
                            storage_type: (record) =>
                                <span>
                                    {record.storage_type === 5 ? record.storage_type +` gal` : record.storage_info && `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}` + record.storage_info.name}
                                </span>,

                            material_type: (record) => 
                                <span>
                                    {record && record.material_type ? (record.material_type.code ?  `(${record.material_type.code}) ` : 'A ') + record.material_type.name  : '-'}
                                </span>,
                            weight: (record) =>
                                <span>
                                    {record && record.weight && floatNumber(record.weight)}
                                </span>,

                            checkbox:(record) => 
                                <span>
                                    <input type="checkbox" className="form-check-input" name="barcodeIds" defaultChecked={false} value={record && record.id} onChange={(e) => storeId(e, record.id)} />
                                </span>,

                            status:(record) =>
                                <span>
                                    {record.status === 0 ? "Stock" : "Released"}
                                </span>,
                            attachment:(record) =>
                                <span>
                                    {record && record.attachment ? <a href={`${ASSET_ENDPOINT}${record.attachment}`} rel="noopener noreferrer" target="_blank">{record.attachment}</a> : '-'}
                                </span>,
                            barcode: (record) => 
                                <span>
                                    <QRCode value={"Prod. Date:" + momentDateDisplay(record.prduction_date) +" "+ record.material_type.name +" "+ record.weight +"kg  "+ momentDateDisplay(record.prduction_date).replace(/\//g, '') + "B" + record.batch_id + "S" + record.storage + " -" + record.material_type.code + " " + `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}`+"-" + record.weight + (record.attachment) ? ` ${process.env.REACT_APP_ASSET_ENDPOINT}uploads/${record.attachment}`:''}
                                    />
                                    <small>{momentDateDisplay(record.prduction_date).replace(/\//g, '') + "B" + record.batch_id + "S" + record.storage + " -" + record.material_type.code + " " + `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}`+"-" + record.weight}</small>
                                </span>,
                            action: (record) => 
                                <span>
                                    {record.status === 0 && 
                                        <Link to='/admin/edit-barcode/' state={record} className="btn btn-sm btn-outline-primary m-1">
                                            <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                        </Link>
                                    }
                                    { permission(context && context.auth.permission , 'delete') &&
                                        <button className="btn btn-sm btn-outline-danger m-1" onClick={() => setDeleteRecord(record.id)} data-bs-target="#deletesection_modal" data-bs-toggle="modal">
                                            <svg className="icon"><use xlinkHref="#icon_trash"/></svg>
                                        </button> 
                                    }
                                </span>
                        }}
                    />
                </div>
            </div>

            <ConfirmationModal  msg="Are you sure for delete this record?" method={() => deleteBarcodeRecord(deleteRecord ? deleteRecord : '')} />
            
            <div className="modal fade" id="barcodeStatusModel" tabIndex="-1" data-bs-backdrop="static">
                <form method="post" id="barcodeRelease">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="barcodeStatusModelLabel">Detail </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => initialFormState("barcodeRelease")} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="name" className="form-label">Name <strong className="text-danger">*</strong></label>
                                        <input type="text" id="client_name" name="client_name" className="form-control" onInput={(e) => setClientName(e.target.value)} placeholder="Enter Client Name" required/>
                                        <div className='invalid-feedback'>Please enter Name.</div>
                                    </div>
                                    <div className="col-6">
                                    <label htmlFor="release_date" className="form-label">Date <strong className="text-danger">*</strong></label>
                                    <input type="date" id="release_date" name="release_date"  className="form-control" onInput={(e) => setReleaseDate(e.target.value)} placeholder="dd-mm-yyyy" required />
                                    <div className='invalid-feedback'>Please fill date.</div>
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="release_time" className="form-label">Time <strong className="text-danger">*</strong></label>
                                        <input type="time" id="release_time" name="release_time" className="form-control" onInput={(e) => setReleaseTime(e.target.value)} placeholder="HH:mm" required />
                                        <div className='invalid-feedback'>Please enter time.</div>
                                    </div>
                                    
                                </div>
                            </div>
                            {
                                (permission(context && context.auth.permission, 'add') || permission(context && context.auth.permission, 'update') ) ?
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Release</button>
                                    </div>
                                :''
                            }
                        </div>
                    </div>
                </form>
            </div>

            {/* Barcode Print Modal */}

            <div className="modal fade" id="QRPrintModal" tabIndex="-1" aria-labelledby="QRPrintModalLabel">
                <div className="modal-dialog modal-dialog-scrollable modal-xl barcodePrintModal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="QRPrintModalLabel">View Print</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body py-0">
                            <div className="row py-3" id="printbarcode">
                                {
                                    (printbarcode) ? printbarcode.map((barcode,i) =>
                                    <>
                                        <div className={(i % 2 === 0) ? "col-7 align-self-start mt-4 pt-5" :"col-7 align-self-start mt-3 pt-3"}>
                                            <div className="cottonwood-modal text-center">
                                                <div>
                                                    <h5 className="mb-0"><strong>Axtell Labs</strong></h5>
                                                    <p className="mb-0">{barcode.wt} kg</p>
                                                    <p className="mb-0">{barcode.material}</p>
                                                    <p className="mb-0">Testing Attached</p>
                                                </div>

                                                <div className="mt-4">
                                                        <p className="mb-1">Batch Id : {barcode.batchIdQR}</p>

                                                        <p className="mb-1">Production Date : {momentDateDisplay(barcode.p_date, false, false, 'MM/DD/YYYY')}</p>

                                                        <p className="mb-1">Expiration Date : {momentDateDisplay(barcode.expiry_date, false, false, 'MM/DD/YYYY')}</p>

                                                        <p className="mb-1">Ingredient Received : {momentDateDisplay(barcode.ingredient_received_date, false, false, 'MM/DD/YYYY')}</p>

                                                        <p className="mb-0 pb-1">Inspected By : {inspected_by ? inspected_by + ' ' + inspected_date: '--'}</p>
                                                </div>
                                                <div className="mt-3">
                                                    <h5 className="mb-0"><strong>Contact Info:</strong></h5>
                                                    <p className="mb-0">Axtell Labs</p>
                                                    <p className="mb-0">PO Box 3670</p>
                                                    <p className="mb-3">Crested Butte, CO 81224</p>
                                                    <p className="mb-0">AxtellLabs.com</p>
                                                    <p>info@axtelllabs.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={(i % 2 === 0) ? "mt-4 pt-5 col-4 align-self-start" :'col-4 align-self-start mt-3 pt-3'}>
                                            <div className="text-center pb-4">
                                                <img src="/images/black-logo.png" alt="Axtellab-Logo" style={{ "width": "100%", "maxWidth":"256px"}} />
                                            </div>

                                            <div className="qr-codeaxtellLabs text-center ms-auto me-auto py-4" style={{ "width": "100%", "maxWidth": "256px" }} >
                                                <QRCode
                                                    size={256}
                                                    level={'H'}
                                                        value={"Prod. Date:" + barcode.p_date + " " + barcode.material + " " + barcode.wt + "kg"
                                                        + " " + barcode.productionId +" " +barcode.attachment}
                                                />
                                            </div>
                                        </div>
                                        <p className="py-1 mb-0"><hr className={((i % 2 !== 0) ? 'invisible':'')} /></p>
                                        <div className={((i % 2 !== 0) ?'breaker pt-3':'')}></div>
                                    </>
                                    )
                                    :''
                                }
                            </div>
                        </div>
                        {
                            (permission(context && context.auth.permission, 'add') || permission(context && context.auth.permission, 'update')) ?
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" id="print-barcode-submit" onClick={(e) => updateInspectionDetail(e)}>Print</button>
                                </div>
                                : ''
                        }
                    </div>
                </div>
            </div>

            {/* Enter inspected Detail */}

            <div className="modal fade" id="inspectedDetailModel" tabIndex="-1" data-bs-backdrop="static">
                <form method="post" id="inspectedDetail">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Inspector Detail</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => initialFormState("inspectedDetail")} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-6">
                                        <label htmlFor="name" className="form-label">Name <strong className="text-danger">*</strong></label>
                                        <input type="text" id="inspected_by" name="inspected_by" className="form-control" placeholder="Enter Inspected By" onInput={(e) => setInspectBy(e.target.value)} required />
                                        <div className='invalid-feedback'>Please Enter Name.</div>
                                    </div>

                                    <div className="col-6">
                                        <label htmlFor="inspected_date" className="form-label">Inspected Date <strong className="text-danger">*</strong></label>
                                        <div className="date-wrapperbox" style={{ display: 'block' }}>
                                            <DatePicker
                                                placeholderText="dd-mm-yy"
                                                onChange={(e) => changeDateFilter(e)}
                                                name="inspected_date"
                                                id="inspected_date"
                                                value={inspected_date}
                                                className="form-control form-dateicon"
                                                autoComplete="off"
                                                popperModifiers={[
                                                    {
                                                      name: 'arrow',
                                                      options: {
                                                        padding: ({ popper, reference }) => ({
                                                          right: Math.min(popper.width, reference.width) + 50,
                                                        }),
                                                      },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {
                                (permission(context && context.auth.permission, 'add') || permission(context && context.auth.permission, 'update')) ?
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => printSelected(e)}>Submit</button>
                                    </div>
                                    : ''
                            }
                        </div>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default ListBarcode;