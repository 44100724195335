import React, { useState, useEffect } from 'react';
import { ConfirmationModal, generateBarcodeBatchId, momentDate, showloader, toastAlert, downloadPdf } from '../../components/Helper';
import { getJsonData, postData } from '../../fetch-data';
import { INVOICE } from '../../components/ApiUrl';
import { useLocation, useNavigate } from 'react-router-dom';

const InvoiceView = () => {

    window.document.title= "View Invoice"
    const location = useLocation()
    const navigate = useNavigate()
    const invoice_id = location.state;

    const [invoice, setInvoice] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [return_invoice, setReturnInvoice] = useState(false)

    const listData = () => {
        if(invoice_id){
            showloader(true)
            getJsonData(INVOICE.GET_INVOICE +'/'+ invoice_id, (res) =>{
                showloader()
                if(res.status){
                    setInvoice(res.data)
                    setCustomerName(JSON.parse(res.data.customer_info).customerName)
                }
            }, true);
        }
    }

    const returnInvoice = (e) => {

        e.preventDefault();
        showloader(true)
        postData(INVOICE.UPDATE_STATUS, {id: invoice.id,status:2}, (res) =>{
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
            }
        }, true)
    }

    const deleteInvoice = (e) => {
        e.preventDefault();
        showloader(true)
        getJsonData(INVOICE.DELETE_INVOICE +'/'+ invoice.id, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            navigate('/admin/invoice-list');

        }, true);;
    }

    const pdfInvoice = () => {

        var date = momentDate(invoice.created_at, false, false, 'YYYY-MM-DD', true)
        postData(INVOICE.PDF_INVOICE, { invoiceId: invoice.qb_invoice_id, invoice_date: date }, (res) => {

        })
        
    }

    const printShipmentLabel = () => {

        if(invoice.shipment_label){
            var date = momentDate(invoice.created_at, false, false, 'YYYY-MM-DD', true)

            const pdfData = `data:application/pdf;base64,${invoice.shipment_label}`;
            const createDownloadLink = document.createElement("a");
            createDownloadLink.href = pdfData;
            createDownloadLink.download = 'Axtellab_Shipment_Label_'+date;
            createDownloadLink.click();
        }else{
            toastAlert('error', 'Shipment label is not available.');
        }

    }

    useEffect(() => {

        listData()
        const script = document.createElement("script"); script.async = true;
        script.src = `${process.env.MIX_APP_URL}js/pdf.js`;
        document.body.appendChild(script);

    }, [])
    
    return (
        <>
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">View Invoice</h2>
                </div>
                <div className="col-sm-12 text-end">
                    <div className="dropdown">
                        <button className="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Perform Action
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            { invoice && invoice.status === 1 ?
                                    <li>
                                        <button className="dropdown-item" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={() => setReturnInvoice({msg:"Are you sure to perform this action?", type: returnInvoice})}>Return Invoice</button>
                                    </li>
                                : ''
                            }
                            <li>
                                <button className="dropdown-item" data-invoice={invoice.id} id="print-invoice-view" onClick={() => downloadPdf('invoice-view', `Axtellab_Invoice_${invoice.id}_Buckets`)}>Print</button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => printShipmentLabel()}>Print Shipment Label</button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => pdfInvoice()}>QB Invoice PDF</button>
                            </li>
                            <li>
                                <button className="dropdown-item" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={() => setReturnInvoice({msg:"Are you sure to perform this action?", type: deleteInvoice})}>Delete</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-12 col-lg-8 col-xl-7 py-4 px-4 container-print" id="invoice-view">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex mb-2">
                                <span className="me-2">Customer Name:</span>
                                <strong>{(customerName)?customerName:'-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Price Per Kg:</span>
                                <strong>${(invoice) ? invoice.unit_price : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Enter Quantity:</span>
                                <strong>{(invoice) ? invoice.quantity : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Invoice Amount:</span>
                                <strong>${(invoice) ? invoice.invoice_amount : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Shipment Cost:</span>
                                <strong>${(invoice) ? invoice.shipment_cost : '-'}</strong>
                            </div>
                        </div>
                        <div className="col-auto text-end">
                            <h5>QB Invoice Id: <strong>{(invoice) ? invoice.qb_invoice_id : '-'}</strong></h5>
                            <h5>Invoice Date: <strong>
                            {
                                (invoice) ? 
                                    (invoice.txn_date) ? 
                                        momentDate(invoice.txn_date, false, false, 'YYYY-MM-DD', true)
                                    :
                                    momentDate(invoice.created_at, false, false, 'YYYY-MM-DD', true)
                                : '-'
                            }</strong></h5>
                        </div>
                    </div>

                    <div className="col-12">
                        <label htmlFor="invoice-barcode" className="form-label">Released Buckets</label>
                        <table className="table table-sm" id="materialRecords">
                            <thead>
                                <tr>
                                    <th>Batch ID</th>
                                    <th>Production</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (invoice) ? invoice.barcode_invoice.map((barcode) =>
                                        (barcode.barcode)?
                                            <tr key={(barcode.barcode.id) ? barcode.barcode.id:'-'}>
                                                <td>{(barcode.barcode) ? generateBarcodeBatchId(barcode.barcode):'-' }</td>
                                                <td>{(barcode.barcode) ? barcode.barcode.prduction_date:'-'}</td>
                                                <td>{(barcode.barcode) ? barcode.barcode.weight:'-'}</td>
                                            </tr>
                                        : <tr key={1}><td colSpan={4} className="text-center">No Buckets Found</td></tr>

                                    ) : <tr></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmationModal msg={return_invoice && return_invoice.msg} method={return_invoice && return_invoice.type}  />
        </>
    )
}

export default InvoiceView;