import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../../components/Context';
import QRCode from 'qrcode.react';
import { ConfirmationModal, floatNumber, momentDateDisplay, permission, showloader, toastAlert } from '../../components/Helper';
import ExportData from '../../components/ExportData';
import { ASSET_ENDPOINT, BARCODE } from '../../components/ApiUrl';
import { DataList } from '../../components/DataList';
import {now} from 'lodash'
import { getJsonData, postData} from '../../fetch-data';

const ListReleasedBarcode = () => { 

    window.document.title = 'Released Barcode'

    const [refresh, setRefresh] = useState('')
    const [exportData, setExportData] = useState({data: 'Released_Barcode',type: 'xls',})
    const [deleteRecord, setDeleteRecord] = useState(false)
    const [barcodeArray, setBarcodeArray] = useState([])
    const [context, setContext] = useContext(Context)
    const [releaseMsg, setReleaseMsg] = useState(false)


    const dt = {
        id: 'release-barcode-list',
        fetchurl: BARCODE.BARCODE_GENERATE_LIST +'?status=1',
        columns: [
            { name: 'checkbox', title: 'Checkbox'},
            { name: 'id', title: 'ID' },
            { name: 'batch_id', title: 'Batch ID'},
            { name: 'prduction_date', title: 'Production Date'},
            { name: 'storage', title: 'Details', width: 220},
            { name: 'status', title: 'Status', width: 10 },
            { name: 'client_name' , title: 'Client Name',  width: 50},
            { name: 'release_date' , title: 'Released Date',  width: 50},
            { name: 'release_by' , title: 'Released By',  width: 50},
            { name: 'attachment', title: 'Attachment', width: 10 },
            { name: 'barcode', title: 'Barcode', width: 10 },
            { name: 'action', title: 'Action', width: 100 },
        ]
    }

    const storeId = (e,id) => {
        if (e.target.checked) {
            setBarcodeArray((prev) => ([...prev , id]))
        }else  { 
            var value_index = barcodeArray.indexOf(id);
            barcodeArray.splice(value_index, 1)
            setBarcodeArray(barcodeArray)
        } 
    }

    const deleteReleasedBarcodeRecord = (id) =>  {
        showloader(true)
        getJsonData(BARCODE.DELETE_BARCODE + '/' + id, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
                setDeleteRecord(false)
                setRefresh(now)
            }
        }, true);
    }

    const returnToStockSet =() => {
        if (barcodeArray.length > 0) {
            document.querySelector('[data-bs-target="#deletesection_modal"]').click()
            setReleaseMsg('Are you sure. You want selected buckets return to stock?')
        }
        else {
            toastAlert('error', 'Please select barcode.');
        }
    }

    const returnToStock = () =>{
        showloader(true)
        postData(BARCODE.BARCODE_RELEASE, {id: barcodeArray, status: 0}, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
                setReleaseMsg('')
                setRefresh(now)
            }
        }, true);
        setBarcodeArray([]);
        document.querySelectorAll('[name="barcodeIds"]').forEach(el => el.checked = false);
    }

    useEffect(() => {

    },[refresh]);
    
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Released Barcode List</h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(records) =>
                            <div className="col-sm-12 d-flex text-start">
                                <div className="col-sm-6">
                                </div>
                                <div className="col-sm-6 text-end">
                                    <button className="btn btn-sm btn-success me-2 mb-1" onClick={() => returnToStockSet()}>
                                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow" /></svg>
                                        Return to Stock
                                    </button>
                                    <Link to="/admin/barcode" className="btn btn-sm btn-primary me-2 mb-1">
                                        <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                                        Barcode List
                                    </Link>
                                    <ExportData export_data={exportData}/>
                                </div>
                            </div>
                        }

                        edit_column={{

                            prduction_date: (record) =>
                                <span>
                                    {record && momentDateDisplay(record.prduction_date)}
                                </span>,

                            storage: (record) =>
                                <span>
                                    <small className="text-muted">Storage:</small> {record && record.storage} <br/>
                                    <small className="text-muted">Storage Type:</small> {record && record.storage_type === 5 ? record.storage_type +` gal` : `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}` + record.storage_info.name} <br/>
                                    <small className="text-muted">Material Type:</small> {record && record.material_type ? (record.material_type.code ?  `(${record.material_type.code}) ` : 'A ') + record.material_type.name  : '-'} <br/>
                                    <small className="text-muted">Weight:</small> {record && floatNumber(record.weight)} kg <br/>
                                </span>,
                                
                            checkbox:(record) => 
                                <span>
                                    <input type="checkbox" className="form-check-input" name="barcodeIds" defaultChecked={false} value={record && record.id} onChange={(e) => storeId(e, record.id)} />
                                </span>,

                            status:(record) =>
                                <span>
                                    {record.status === 0 ? "Stock" : "Released"}
                                </span>,

                            barcode: (record) => 
                                <span>
                                    <QRCode value={"Prod. Date:" + momentDateDisplay(record.prduction_date) +" "+ record.material_type.name +" "+ record.weight +"kg"
                                            + " " + momentDateDisplay(record.prduction_date).replace(/\//g, '') + "B" + record.batch_id + "S" + record.storage + " -" + record.material_type.code + " " + `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}`+"-" + record.weight + (record.attachment) ? ` ${process.env.REACT_APP_ASSET_ENDPOINT}uploads/${record.attachment}`:''}
                                    />
                                    <small>{momentDateDisplay(record.prduction_date).replace(/\//g, '') + "B" + record.batch_id + "S" + record.storage + " -" + record.material_type.code + " " + `${record.storage_info ? '('+record.storage_info.code+') ' : '(01) '}`+"-" + record.weight}</small>
                                </span>,
                            release_date: (record) => 
                                <span>
                                    {record && record.release_date ? momentDateDisplay(record.release_date) : '-'}
                                    <br/>{record.release_time ? momentDateDisplay(record.release_date+' '+record.release_time, false, true) : ''}
                                </span>,

                            release_by: (record) =>
                                <span>
                                    {record && record.release_by && record.release_by.name}
                                </span>,

                            attachment: (record) =>
                                <span>
                                    {record && record.attachment ? <a href={`${ASSET_ENDPOINT+record.attachment}`} target="_blank">{`${record.attachment}`}</a> : '-'}
                                </span>,

                            action: (record) => 
                                <span>
                                    { permission(context && context.auth.permission , 'delete') &&
                                        <button className="btn btn-sm btn-outline-danger m-1" onClick={() => setDeleteRecord(record.id)} data-bs-target="#deletesection_modal" data-bs-toggle="modal">
                                            <svg className="icon"><use xlinkHref="#icon_trash"/></svg>
                                        </button> 
                                    }
                                </span>
                        }}
                    />
                    <ConfirmationModal msg={releaseMsg ? releaseMsg : "Are you sure want to delete this record?"} method={() => {releaseMsg ? returnToStock() : deleteReleasedBarcodeRecord(deleteRecord ? deleteRecord : '')}}  />
                </div>
            </div>
        </div>
    )
}

export default ListReleasedBarcode;