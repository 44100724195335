import React, { useEffect, useState, useContext } from 'react';
import { floatNumber, generateBarcodeBatchId, momentDateDisplay, permission, toastAlert } from '../../components/Helper';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { getJsonData } from '../../fetch-data/'
import { GET_FORM_DATA } from '../../components/ApiUrl';
import Context from '../../components/Context';

const CreateInvoice = ()  => {

    window.document.title = 'Create Invoice'

    const [context, setContext] = useContext(Context)
    const [customers, setCustomers] = useState('')
    const [items, setItems] = useState('')
    const [qb_items, setQbItems] = useState('')
    const [qb_customer, setQbCustomer] = useState('')
    const [invoice_amount, setInvoiceAmount] = useState('')
    const [customerEmail, setCustomerEmail] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [unit_price, setUnitPrice] = useState('')
    const [material_type, setMaterialType] = useState('')
    const [net_term, setNetTerm] = useState('')
    const [selectedBarcode, setSelectBarcode] = useState([])
    const [invoice_message, setInvoiceMessage] = useState('')
    const [shipment_cost, setShipmentCost] = useState('')
    const [qb_customer_list, setQbCustomerList] = useState([])
    const [selected_customer, setSelectedCustomer] = useState('')
    const [materialList, setMaterialList] = useState('')
    const [barcode_data, setBarcodeData] = useState('')
    const [QBnetTerm, setQBnetTerm] = useState('')
    const [txn_date, setTxnDate] = useState('')

    const fetchQBCustomers = () => {
        getJsonData(GET_FORM_DATA.GET_CUSTOMERS, (res) => {
            if(res.status){

            }
        }, true);

        // InvoiceService.getCustomers((res) => {
        //     let qb_customer_list = [];
        //     res.data.data.QueryResponse.Customer.forEach(qbc => {
        //         qb_customer_list.push({
        //             value: qbc.Id, 
        //             label: qbc.DisplayName ? qbc.DisplayName : '-',
        //             name: qbc.FullyQualifiedName ? qbc.FullyQualifiedName : '',
        //             email: qbc.PrimaryEmailAddr ? qbc.PrimaryEmailAddr.Address.split(',')[0] : '',
        //             city: qbc.ShipAddr ? qbc.ShipAddr.City : '',
        //             country: qbc.ShipAddr ? qbc.ShipAddr.CountrySubDivisionCode : '',
        //             line1: qbc.ShipAddr ? qbc.ShipAddr.Line1 : '',
        //             line2: qbc.ShipAddr ? qbc.ShipAddr.Line2 : '',
        //             postalcode: qbc.ShipAddr ? qbc.ShipAddr.PostalCode : '',
        //             phone: qbc.PrimaryPhone ? qbc.PrimaryPhone.FreeFormNumber : ''
        //         })
        //     });

        //     setState({
        //         customers: qb_customer_list
        //     });
        // });
    }

//     const fetchMaterialType = () => {
//         SystemDataService.list({ input: 1, type: 'material' }).then(res => {
//             setState({ materialList: res.data.data })
//         }).catch(err => {
//             let errorObj = err.response.data.errors;
//                 Object.keys(errorObj).map((item) => toastAlert('error', errorObj[item])
//             )
//         });
//     }

//     const fetchNetTerms = () => {
//         InvoiceService.QBlist((res) => {
//             setState({
//                 QBnetTerm: res.data.data.QueryResponse
//             });
//         }, (err) => {
//             console.log(err)
//         })
//     }

    const fetchBarcode = (materialtype) => {
        // Promise.resolve(setState(() => ({
        //     filter: {
        //         materialtype: materialtype ,
        //         status: 0
        //     }
        // })));
        // BarcodeService.list(filter).then(response => {
        //     setState({
        //         barcode_data: response.data.data
        //     });
        // }).catch(err => {
        //     let errorObj = err.response.data.errors;
        //     Object.keys(errorObj).map((item) => toastAlert('error', errorObj[item])
        //     )
        // });
    }

    const handleInputChanged = (event) => {
        if (event.target.name == 'quantity') {
            // setState({
            //     invoice_amount: unit_price * event.target.value
            // })
        }
        if (event.target.name == 'unit_price') {
            // setState({
            //     invoice_amount: quantity * event.target.value
            // })
        }
        if (event.target.name == 'material_type') {
            fetchBarcode(event.target.value)
            // setState({
            //     invoice_amount: 0,
            //     shipment_cost: '',
            //     quantity: '',
            //     unit_price: ''
            // })

            if (document.getElementById('checkall')){
                document.getElementById('checkall').checked = false
            }
        }
        if (event.target.name == 'net_term') {
            // setState({
            //     net_term_name: event.target.selectedOptions[0].getAttribute('data-name')
            // })
        }
        // setState({
        //     [event.target.name]: event.target.value
        // });
    }


    const selectAllBuckets = (e) => {
        var barcodeWtArr = []; var barcodeArray = [];
        var checks = document.querySelectorAll('#materialRecords tbody .tbody-check');

        barcodeWtArr = [];
        if (e.target.checked) {
            checks.forEach((ch) => {
                ch.checked = true;
            });

            const weight = [...checks].map(e => JSON.parse(e.value).wt);
            barcodeWtArr.push(weight);

            const barcode = [...checks].map(e => JSON.parse(e.value).id);
            barcodeArray.push(barcode);

        } else {
            checks.forEach((ch) => {
                ch.checked = false;
            });
            barcodeWtArr = [null]
        }
        // setState({
        //     selectedBarcode: barcodeArray[0],
        //     selectedBarcodeWt: barcodeWtArr[0],
        //     quantity: barcodeWtArr[0] ? barcodeWtArr[0].reduce((a, b) => parseFloat(a) + parseFloat(b), 0):''
        // })

        fetchShipmentRates(barcodeWtArr[0] ? barcodeWtArr[0].reduce((a, b) => parseFloat(a) + parseFloat(b), 0):'');
    }

    const selectSingleBucket = (e) => {
        var barcodeWtArr = []; var barcodeArray = [];
        var checkedAll = document.querySelectorAll('#materialRecords tbody .tbody-check:checked').length;
        var checkall = document.getElementById('checkall');
        var checks = document.querySelectorAll('#materialRecords tbody .tbody-check');

        checks.forEach((ch) => {
            barcodeWtArr = []; barcodeArray = [];
            var checksCount = checks.length;
            var checkedAllLoop = document.querySelectorAll('#materialRecords tbody .tbody-check:checked');

            if (e.target.checked) {
                e.target.checked = true;
                const weight = [...checkedAllLoop].map(e => JSON.parse(e.value).wt);
                barcodeWtArr.push(weight);

                const barcode = [...checkedAllLoop].map(e => JSON.parse(e.value).id);
                barcodeArray.push(barcode);

                if (checksCount == checkedAll) {
                    checkall.checked = true;
                }
            }
            else {
                e.target.checked = false;

                const weight = [...checkedAllLoop].map(e => JSON.parse(e.value).wt);
                barcodeWtArr.push(weight);

                const barcode = [...checkedAllLoop].map(e => JSON.parse(e.value).id);
                barcodeArray.push(barcode);

                if (checksCount != checkedAll) {
                    checkall.checked = false;
                }
            }
        });
        
        // setState({
        //     selectedBarcode: barcodeArray[0],
        //     selectedBarcodeWt: barcodeWtArr[0],
        //     quantity: barcodeWtArr[0].reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        // })

        fetchShipmentRates(barcodeWtArr[0].reduce((a, b) => parseFloat(a) + parseFloat(b), 0));
    }


//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if(!selectedBarcode.length){
//             toastAlert('error', 'Please select barcode.');
//             return
//         }
//         if (invoice_message.length >= 1000) {
//             toastAlert('error', 'Invoice message cannot be more than 1000 words.');
//             return
//         }
//         const formData = new FormData();
//         formData.append("customerId", qb_customer);
//         formData.append("customerEmail", customerEmail);
//         formData.append("customerName", customerName);
//         formData.append("customerPhone", customerPhone);
//         formData.append("customerCity", customerCity);
//         formData.append("customerCountry", customerCountry);
//         formData.append("customerLine1", customerLine1);
//         formData.append("customerLine2", customerLine2);
//         formData.append("customerPostal", customerPostal);

//         formData.append("barcodeId", selectedBarcode);
//         formData.append("quantity", quantity);
//         formData.append("unit_price", unit_price);
//         formData.append("net_term", net_term);
//         formData.append("net_term_name", net_term_name);
//         formData.append("material_type", material_type);
//         formData.append("txn_date", txn_date);
//         formData.append("invoice_message", invoice_message);
//         formData.append("shipment_cost", shipment_cost);
        
//         InvoiceService.createInvoice(formData, (response) => {
//             if (response.status == 201) {
//                 toastAlert('success', response.data.message);
//                 setState({
//                     invoice_amount: '', materialList: '', barcode_data:'',
//                     qb_customer: '', customerEmail: '', txn_date:'',
//                     quantity: 0, unit_price: '', material_type: '',
//                     invoice_message: '', shipment_cost: ''
//                 })
//                 document.getElementById("create-invoice-form").reset();
//                 fetchMaterialType();
//             } else {
//                 toastAlert('error', response.data.message)
//             }
//         }, (err) => {
//             toastAlert('error', err.response.data.message)
//         })
//     }
    const changeDateFilter = (date) => {
        // setState({ txn_date: momentDateDisplay(date, false, false, 'DD-MM-YYYY') });
    }

    const selectedCustomer = (selectedCustomer) => {
        // Promise.resolve(setState(() => ({
        //     selectedCustomer: selectedCustomer,
        //     qb_customer: selectedCustomer.value,
        //     customerEmail : selectedCustomer.email,
        //     customerName: selectedCustomer.name,
        //     customerPhone: selectedCustomer.phone,
        //     customerCity: selectedCustomer.city,
        //     customerCountry: selectedCustomer.country,
        //     customerLine1: selectedCustomer.line1,
        //     customerLine2: selectedCustomer.line2,
        //     customerPostal: selectedCustomer.postalcode
        // })));

        if(quantity > 0){
            fetchShipmentRates(quantity)
        }
    }

    const fetchShipmentRates = (quantity) => {
        const formData = new FormData();
        // formData.append("customerEmail", customerEmail);
        // formData.append("customerName", customerName);
        // formData.append("customerPhone", customerPhone);
        // formData.append("customerCity", customerCity);
        // formData.append("customerCountry", customerCountry);
        // formData.append("customerLine1", customerLine1);
        // formData.append("customerLine2", customerLine2);
        // formData.append("customerPostal", customerPostal);
        // formData.append("quantity", quantity);
        // formData.append("material_type", material_type);

        if(!quantity){
            toastAlert('error', 'Please select barcode.');
            // setState({
            //     shipment_cost: ''
            // });
        }else if(quantity > 68){
            toastAlert('error', 'Unable to calculate shipment cost. Quantity should not be more than 150 lbs (68 kg).');
            // setState({
            //     shipment_cost: ''
            // });
        }else{
            // InvoiceService.getShipmentRates(formData, (res) => {
            //     let shipmentCost = res.data.data[0].shipmentCost + res.data.data[0].otherCost
            //     // setState({
            //     //     shipment_cost: shipmentCost
            //     // });
            // }, (err) => {
            // })
        }
    }

    useEffect(() => {
        fetchQBCustomers();
        // fetchMaterialType();
        // fetchNetTerms();
    },[]);

    return (

        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Create Invoice</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form method="post" className="row g-3 g-sm-4" id="create-invoice-form">
                        <div className="col-6">
                            <label htmlFor="qb_customer" className="form-label">Select QuickBook Customer<strong className="text-danger">*</strong></label>
                            <Select
                                className="form-select-custom"
                                value={selectedCustomer}
                                onChange={() => selectedCustomer()}
                                options={customers}
                                placeholder="Choose"
                                isDisabled={customers ? false : true}
                            />
                        </div>
                            
                        <div className="col-6">
                            <label htmlFor="material_type" className="form-label">Select Material Type<strong className="text-danger">*</strong></label>
                            <select id="material_type" name="material_type" onChange={() => handleInputChanged()} className="form-select form-select-lg" value={material_type} required disabled={customerEmail ? false : true}>
                                <option value='' disabled>Choose</option>
                                {
                                    (materialList) ? materialList.map((materialType) =>
                                        <option key={materialType.id} value={materialType.id}>({materialType.code}) {materialType.name}</option>
                                    )
                                        : ''
                                }

                            </select>
                        </div>
                        {
                            (barcode_data) ?   
                                <div className="col-12">
                                    <label htmlFor="material_type" className="form-label">Select Barcode<strong className="text-danger">*</strong></label>
                                    <table className="table table-sm" id="materialRecords">
                                        <thead>
                                            <tr>
                                                <th width="120">
                                                    <label className="form-check mb-0">
                                                        <input className="form-check-input" type="checkbox" id="checkall" onChange={() => selectAllBuckets()} disabled={(barcode_data && barcode_data.length > 0) ? '' : true}/>
                                                        <span className="form-check-label">
                                                            Select All
                                                        </span>
                                                    </label>
                                                </th>
                                                <th>Batch ID</th>
                                                <th>Storage</th>
                                                <th>Production</th>
                                                <th>Weight</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (barcode_data && barcode_data.length > 0) ? barcode_data.map((barcode) =>
                                                    <tr key={barcode.id}>
                                                        <td>
                                                            <label className="form-check mb-0">
                                                                <input className="form-check-input tbody-check" type="checkbox" value={JSON.stringify({ 'wt': barcode.weight, 'id': barcode.id})} onChange={() => selectSingleBucket()}/>
                                                                <span className="form-check-label"></span>
                                                            </label>
                                                        </td>
                                                        <td>{generateBarcodeBatchId(barcode) }
                                                        </td>
                                                        <td>{barcode.storage}</td>
                                                        <td>{barcode.prduction_date}</td>
                                                        <td>{barcode.weight}</td>
                                                    </tr>

                                                ) : <tr><td colSpan={6} className="text-center">No Buckets Found</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            :''
                        }

                        <div className="col-6">
                            <label htmlFor="unit_price" className="form-label">Price Per Kg</label>
                            <input type="number" id="unit_price" name="unit_price" min={0} step="any" onChange={handleInputChanged} value={unit_price}className="form-control" placeholder="Unit Price" required/>
                        </div>

                        <div className="col-6">
                            <label htmlFor="quantity" className="form-label">Enter Quantity</label>
                            <input type="number" id="quantity" min={1} step="any" name="quantity" onChange={handleInputChanged} value={quantity} className="form-control" placeholder="Enter Quantity" required readOnly/>
                        </div>

                        <div className="col-6">
                            <label htmlFor="invoice_amount" className="form-label">Invoice Amount<small>($)</small><strong className="text-danger">*</strong></label>
                            <input type="number" id="invoice_amount" min={0} step="any" name="invoice_amount" onChange={handleInputChanged} value={floatNumber(quantity*unit_price)} className="form-control" placeholder="Invoice Amount" readOnly />
                        </div>

                        <div className="col-6">
                            <label htmlFor="shipment_cost" className="form-label">Shipment Cost<small>($)</small><strong className="text-danger">*</strong></label>
                            <input type="number" id="shipment_cost" min={0} step="any" name="shipment_cost" onChange={handleInputChanged} value={floatNumber(shipment_cost)} className="form-control" placeholder="Shipment Cost" readOnly required/>
                        </div>

                        <div className="col-6">
                            <label htmlFor="net_term" className="form-label">Select Net Term<strong className="text-danger">*</strong></label>
                            <select id="net_term" name="net_term" onChange={handleInputChanged} className="form-select form-select-lg" value={net_term} required>
                                <option value='' disabled>Choose</option>
                                {
                                    (QBnetTerm) ? QBnetTerm.Term.map((netTerm) =>
                                        <option key={netTerm.Id} data-name={netTerm.Name} value={netTerm.DueDays} >{netTerm.Name}</option>
                                    )
                                    : ''
                                }

                            </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="date" className="form-label"> Invoice Date <strong className="text-danger">*</strong></label>
                            <div className="date-wrapperbox" style={{ display: 'block' }}>
                                <DatePicker
                                    placeholderText="dd-mm-yy"
                                    onChange={changeDateFilter}
                                    name="txn_date"
                                    id="txn_date"
                                    value={txn_date}
                                    className="form-control form-dateicon"
                                    autoComplete="off"
                                    required={true}
                                    popperModifiers={[
                                        {
                                          name: 'arrow',
                                          options: {
                                            padding: ({ popper, reference }) => ({
                                              right: Math.min(popper.width, reference.width) + 50,
                                            }),
                                          },
                                        },
                                    ]}
                                />
                            </div>
                        </div>

                        <div className="col-6">
                            <label htmlFor="invoice_message" className="form-label">Message on invoice</label>
                            <textarea name="invoice_message" value={invoice_message} onChange={handleInputChanged} className="form-control" rows={10}  />
                        </div>

                        {
                            (permission(context && context.auth.permission, 'add')) ?
                                <div className="mb-4 mb-sm-5">
                                    <button type="submit" className="btn btn-lg btn-primary btn-160">Submit</button>
                                </div>
                                : ''
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateInvoice;