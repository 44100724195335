import InventoryList from '../Inventory/InventoryList';

function ListHemp() {

    window.document.title = "Hemp Inventory"

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Hemp Inventory</h2>
                </div>
                <InventoryList inventory_id={4} />
            </div>
        </div>
    );
}
export default ListHemp;
