import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { getJsonData } from "../fetch-data"
import { AUTH } from './ApiUrl';

import NotFound from './NotFound';
import Dashboard from '../modules/Dashboard/Dashboard';
import ChangePassword from '../modules/ChangePassword';
import AddUser from '../modules/User/AddUser';
import ListUser from '../modules/User/ListUser';
import EditUser from '../modules/User/EditUser';
import AddBarcode from '../modules/Barcode/AddBarcode';
import ListReleasedBarcode from '../modules/Barcode/ListReleasedBarcode';
import ListBarcode from '../modules/Barcode/ListBarcode';
import UpdateBarcode from '../modules/Barcode/UpdateBarcode';
import ListEditRequest from '../modules/EditRequest/ListEditRequest';
import BiomassBagging from '../modules/Biomass/BiomassBagging';
import BiomassBaggingList from '../modules/Biomass/BiomassBaggingList';
import PreExtraction from '../modules/Biomass/PreExtraction';
import PreExtractionList from '../modules/Biomass/PreExtractionList';
import PostExtraction from '../modules/Biomass/PostExtraction';
import PostExtractionList from '../modules/Biomass/PostExtractionList';
import CbdaWeight from '../modules/CrudeOil/CBDaWeight';
import CbdaWeightList from '../modules/CrudeOil/CBDaWeightList';
import CBDAStock from '../modules/CrudeOil/CBDAStock';
import Operations from '../modules/operations/Operations';
import OperationsList from '../modules/operations/OperationsList';
import SolventSpend from '../modules/Solvent/SolventSpend';
import SolventSpendList from '../modules/Solvent/SolventSpendList';
import ListHemp from '../modules/Biomass/ListHemp';
import AddHemp from '../modules/Biomass/AddHemp';
import StockHemp from '../modules/Biomass/StockHemp';
import AddSolvent from '../modules/Solvent/AddSolvent';
import SolventStock from '../modules/Solvent/SolventStock';
import AddSettings from '../modules/Settings/AddSettings';
import CreateInvoice from '../modules/Invoice/CreateInvoice';
import ListInvoice from '../modules/Invoice/InvoiceList';
import ViewInvoice from '../modules/Invoice/InvoiceView';
import Configuration from '../modules/InvoiceSettings/Configuration';
import ShippingList from '../modules/shipping/ShippingList';
import ViewShipping from '../modules/shipping/ShippingView';
import TestingCenter from '../modules/TestingCenter/TestingCenter';
import ListTestingCenter from '../modules/TestingCenter/ListTestingCenter';
import ListUserActivity from '../modules/user_activity/ListUserActivity';
import ListWastage from '../modules/wastage/ListWastage';
import Login from '../modules/Auth/Login';
import Home from '../modules/Home';
import { Backend } from './Layout';
import Context from './Context';

import SystemDataList from '../modules/SystemData/SystemDataList';
import SystemData from '../modules/SystemData/SystemData';
import BulkInventory from '../modules/BulkInventory/BulkInventory';
import BulkInventoryList from '../modules/BulkInventory/BulkInventoryList';
import SolventList from '../modules/Solvent/SolventList';
import CrudeOilSpendList from '../modules/CrudeOil/CrudeOilSpendList';
import CrudeOilSpend from '../modules/CrudeOil/CrudeOilSpend';

function Routing() {
    const [context, setContext] = useState()
    const [token] = useState(localStorage.getItem('token'));

    useEffect(() => {
        if(token){
            getJsonData(AUTH.AUTH_USER, (response) => {
                if (response.data) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: response.data
                    }))
                    
                }
            }, true)
        }
        document.body.classList.remove('menu-toggle');
    }, [token])

    return (
        <Context.Provider value={[context, setContext]}>
            <Routes>
               
                <Route path='/' caseSensitive={false} element={<Home />} />
                <Route path='/login' caseSensitive={false} element={<Login />} />
                <Route path='/admin/login' caseSensitive={false} element={<Login />} />
                {context && context.auth &&
                    <Route path="/" exact caseSensitive={false} element={<Backend />}>
                        {context.auth && context.auth.role_id === 1 ?
                        <>
                            <Route path="admin/dashboard" caseSensitive={false} element={<Dashboard />} />
                            <Route path="/admin/editrequests" caseSensitive={false} element={<ListEditRequest />} />
                            <Route path="/admin/operations-list" caseSensitive={false} element={<OperationsList />} />
                            <Route path="/admin/biomass-bagging-list" caseSensitive={false} element={<BiomassBaggingList />} />
                            <Route path="/admin/pre-extraction-list" caseSensitive={false} element={<PreExtractionList/>} />
                            <Route path="/admin/post-extraction-list" caseSensitive={false} element={<PostExtractionList />} />
                            <Route path="/admin/hemp" caseSensitive={false} element={<ListHemp />} />
                            <Route path="/admin/stock-hemp" caseSensitive={false} element={<StockHemp />} />
                            <Route path="/admin/cbda-crude-oil-list" caseSensitive={false} element={<CbdaWeightList />} />
                            <Route path="/admin/cbda-crude-oil-stock" caseSensitive={false} element={<CBDAStock />} />
                            <Route path="/admin/cbda-crude-oil-spend-list" caseSensitive={false} element={<CrudeOilSpendList />} />
                            <Route path="/admin/add-barcode" caseSensitive={false} element={<AddBarcode />} />
                            <Route path="/admin/barcode" caseSensitive={false} element={<ListBarcode />} />
                            <Route path="/admin/edit-barcode" caseSensitive={true} element={<UpdateBarcode />} />
                            <Route path="/admin/released-barcode-list" caseSensitive={false} element={<ListReleasedBarcode />} />
                            <Route path="/admin/solvent-list" caseSensitive={false} element={<SolventList />} />
                            <Route path="/admin/add-solvent" caseSensitive={false} element={<AddSolvent />} />
                            <Route path="/admin/solvent-stock" caseSensitive={false} element={<SolventStock />} />
                            <Route path="/admin/solvent-spend-list" caseSensitive={false} element={<SolventSpendList />} />
                            <Route path="/admin/bulk-inventory-list" caseSensitive={false} element={<BulkInventoryList />} />
                            <Route path="/admin/invoice" caseSensitive={false} element={<CreateInvoice />} />
                            <Route path="/admin/invoice-list" caseSensitive={false} element={<ListInvoice />} />
                            <Route path="/admin/invoice-view" caseSensitive={false} element={<ViewInvoice />} />
                            <Route path="/admin/invoice-setting" caseSensitive={false} element={<Configuration />} />
                            <Route path="/admin/testing-center" caseSensitive={false} element={<TestingCenter />} />
                            <Route path="/admin/testing-center-list" caseSensitive={false} element={<ListTestingCenter />} />
                            <Route path="/admin/users" caseSensitive={false} element={<ListUser />} />
                            <Route path="/admin/edit-user" caseSensitive={false} element={<EditUser />} />
                            <Route path="/admin/add-user" caseSensitive={false} element={<AddUser />} />
                            <Route path="/admin/settings" caseSensitive={false} element={<AddSettings />} />
                            <Route path="/admin/storage-type" caseSensitive={false} element={<SystemData />} />
                            <Route path="/admin/storage-type-list" caseSensitive={false} element={<SystemDataList />} />
                            <Route path="/admin/material-type" caseSensitive={false} element={<SystemData />} />
                            <Route path="/admin/material-type-list" caseSensitive={false} element={<SystemDataList />} />
                            <Route path="/admin/solvent-type"  caseSensitive={false} element={<SystemData />} />
                            <Route path="/admin/solvent-type-list" caseSensitive={false} element={<SystemDataList />} />
                            <Route path="/admin/test-type" caseSensitive={false} element={<SystemData />} />
                            <Route path="/admin/test-type-list" caseSensitive={false} element={<SystemDataList />} />
                            <Route path="/admin/user-activity" caseSensitive={false} element={<ListUserActivity />} />
                            <Route path="/admin/list-wastage" caseSensitive={false} element={<ListWastage />} />
                            <Route path="/admin/add-hemp" caseSensitive={false} element={<AddHemp />} />
                            <Route path="/admin/bulk-inventory" caseSensitive={false} element={<BulkInventory />} />
                        </>
                        :
                        <>
                            <Route path="/user/biomass-bagging" caseSensitive={false} element={<BiomassBagging />} />
                            <Route path="/user/biomass-bagging-list" caseSensitive={false} element={<BiomassBaggingList />} />
                            <Route path="/user/biomass-bagging" caseSensitive={false} element={<BiomassBagging />} />
                            <Route path="/user/pre-extraction" caseSensitive={false} element={<PreExtraction />} />
                            <Route path="/user/pre-extraction-list" caseSensitive={false} element={<PreExtractionList />} />
                            <Route path="/user/post-extraction" caseSensitive={false} element={<PostExtraction />} />
                            <Route path="/user/post-extraction-list" caseSensitive={false} element={<PostExtractionList />} />
                            <Route path="/user/solvent-spend" caseSensitive={false} element={<SolventSpend />} />
                            <Route path="/user/solvent-spend-list" caseSensitive={false} element={<SolventSpendList />} />
                            <Route path="/user/cbda-crude-oil" caseSensitive={false} element={<CbdaWeight />} />
                            <Route path="/user/cbda-crude-oil-list" caseSensitive={false} element={<CbdaWeightList />} />
                            <Route path="/user/cbda-crude-oil-spend" caseSensitive={false} element={<CrudeOilSpend />} />
                            <Route path="/user/cbda-crude-oil-spend-list" caseSensitive={false} element={<CrudeOilSpendList />} />
                            <Route path="/user/bulk-inventory" caseSensitive={false} element={<BulkInventory />} />
                            <Route path="/user/bulk-inventory-list" caseSensitive={false} element={<BulkInventoryList />} />
                            <Route path="/user/operations" caseSensitive={false} element={<Operations />} />
                            <Route path="/user/users-list" caseSensitive={false} element={<ListUser />} />
                            <Route path="/admin/edit-user" caseSensitive={false} element={<EditUser />} />
                            <Route path="/user/shipping" caseSensitive={false} element={<ShippingList />} />
                            <Route path="/admin/invoice" caseSensitive={false} element={<CreateInvoice />} />
                            <Route path="/user/shipping-view" caseSensitive={false} element={<ViewShipping />} />
                            <Route path="/user/change-password" caseSensitive={false} element={<ChangePassword />} />
                        </>
                        }
                    </Route>
                }
                {!token ? <Route path="*" element={<NotFound />} /> : ''}
            </Routes>
        </Context.Provider>
    )
}
export default Routing;

{/* <FrontendLayout exact path="/" component={Home}/>
<FrontendLayout exact path="/login" component={Login}/>
<FrontendLayout exact path="/admin/login" component={Login}/>
<BackendLayout path="/admin/dashboard" component={Dashboard} />
<BackendLayout path="/admin/add-user" component={AddUser} />
<BackendLayout path="/admin/users" component={ListUser} />
<BackendLayout path="/admin/edit-user/:id" component={EditUser} />
<BackendLayout path="/admin/add-barcode" component={AddBarcode} />
<BackendLayout path="/admin/barcode" component={ListBarcode} />
<BackendLayout path="/admin/edit-barcode/:id" component={UpdateBarcode} />
<BackendLayout path="/admin/editrequests" component={ListEditRequest} />
<BackendLayout path="/admin/biomass_bagging_list" component={BiomassBaggingList} />
<BackendLayout path="/admin/pre_extraction_list" component={PreExtractionList} />
<BackendLayout path="/admin/post_extraction_list" component={PostExtractionList} />
<BackendLayout path="/admin/solvent_spend_list" component={SolventSpendList} />
<BackendLayout path="/admin/cbda_crude_oil_list" component={CbdaWeightList} />
<BackendLayout path="/admin/bulk_inventory" component={BulkInventory} />
<BackendLayout path="/admin/bulk_inventory_list" component={BulkInventoryList} />
<BackendLayout path="/admin/operations_list" component={OperationsList} />
<BackendLayout path="/admin/hemp" component={ListHemp} />
<BackendLayout path="/admin/add-hemp" component={AddHemp} />
<BackendLayout path="/admin/stock-hemp" component={StockHemp} />
<BackendLayout path="/admin/add-solvent" component={AddSolvent} />
<BackendLayout path="/admin/solvent-list" component={SolventList} />
<BackendLayout path="/admin/solvent_stock" component={SolventStock} />
<BackendLayout path="/admin/cbda_crude_oil_stock" component={CBDAStock} />
<BackendLayout path="/admin/cbda_crude_oil_spend_list" component={CrudeOilSpendList} />
<BackendLayout path="/admin/storage_type_list" component={SystemDataList} />
<BackendLayout path="/admin/storage_type" component={SystemData} />
<BackendLayout path="/admin/material_type" component={SystemData} />
<BackendLayout path="/admin/material_type_list" component={SystemDataList} />
<BackendLayout path="/admin/solvent_type" component={SystemData} />
<BackendLayout path="/admin/solvent_type_list" component={SystemDataList} />
<BackendLayout path="/admin/settings" component={AddSettings} />
<BackendLayout path="/admin/released-barcode-list" component={ListReleasedBarcode} />
<BackendLayout path="/admin/invoice" component={CreateInvoice} />
<BackendLayout path="/admin/invoice_list" component={ListInvoice} />
<BackendLayout path="/admin/invoice_view/:id" component={ViewInvoice} />
<BackendLayout path="/admin/test_type" component={SystemData} />
<BackendLayout path="/admin/test_type_list" component={SystemDataList} />
<BackendLayout path="/admin/testing_center" component={TestingCenter} />
<BackendLayout path="/admin/testing_center_list" component={ListTestingCenter} />
<BackendLayout path="/admin/invoice_setting" component={Configuration} />
<BackendLayout path="/admin/user_activity" component={ListUserActivity} />
<BackendLayout path="/admin/list_wastage" component={ListWastage} />

<BackendLayout path="/user/dashboard" component={Dashboard} />
<BackendLayout path="/user/change-password" component={ChangePassword} />
<BackendLayout path="/user/users_list" component={ListUser} />
<BackendLayout path="/user/shipping" component={ShippingList} />
<BackendLayout path="/user/shipping_view/:id" component={ViewShipping} />
<BackendLayout path="/user/biomass_bagging" component={BiomassBagging} />
<BackendLayout path="/user/biomass_bagging_list" component={BiomassBaggingList} />

<BackendLayout path="/user/pre_extraction_list" component={PreExtractionList} />
<BackendLayout path="/user/post_extraction" component={PostExtraction} />
<BackendLayout path="/user/post_extraction_list" component={PostExtractionList} />
<BackendLayout path="/user/solvent_spend" component={SolventSpend} />
<BackendLayout path="/user/cbda_crude_oil_spend" component={CrudeOilSpend} />
<BackendLayout path="/user/solvent_spend_list" component={SolventSpendList} />
<BackendLayout path="/user/cbda_crude_oil_spend_list" component={CrudeOilSpendList} />
<BackendLayout path="/user/cbda_crude_oil" component={CbdaWeight} />
<BackendLayout path="/user/cbda_crude_oil_list" component={CbdaWeightList} />
<BackendLayout path="/user/bulk_inventory" component={BulkInventory} />
<BackendLayout path="/user/bulk_inventory_list" component={BulkInventoryList} />
<BackendLayout path="/user/operations" component={Operations} />
<FrontendLayout path="*" component={NotFound} /> */}

