import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ConfirmationModal, generateBarcodeBatchId, momentDateDisplay } from '../../components/Helper';
import { DataList } from '../../components/DataList';
import { INVOICE } from '../../components/ApiUrl';
import { now } from 'lodash';
import { postData } from '../../fetch-data';

const ShippingList = () => {

    window.document.title = 'Invoice List';

    const [refresh, setRefresh] = useState('')
    const [change_status, setChangeStatus] = useState('')

    const dt = {
        id: 'invoice-list',
        fetchurl: INVOICE.PAID_LIST,
        columns: [
            {name: 'id', title: 'ID', width: 10},
            {name: 'material_type', title: 'Material Type', width: 50},
            {name: 'quantity', title: 'Qty (kg)', width: 60},
            {name: 'barcode_invoice', title: 'Barcode Informations', width: 200},
            {name: 'action', title: 'Action', width: 200},
        ]
    };

    const changeInvoiceStatus = (id, status) => {
        postData(INVOICE.UPDATE_STATUS, { id: id, status: status }, (res) => {
            if(res.status){
                document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click();
                setRefresh(now)
            }
        }, true);
    }

    useEffect(() => {

    }, [refresh]);

    return (
        <>
            <div className="container-fluid container-common">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h2 className="page-heading">Invoice List</h2>
                    </div>
                    <div className="col-md-12 my-2">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(record) =>
                                <div className="ms-auto">
                                    <Link to="/admin/invoice"  className="btn btn-sm btn-success">
                                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                        Back
                                    </Link>
                                </div>                        
                            }
                            edit_column={{
                                material_type: (record) =>
                                    <span>
                                        {record && record.material_type ? '('+ record.material_type.code +') ' + record.material_type.name : '-'}
                                    </span>,
                                barcode_invoice: (record) =>
                                    <span>
                                        {record.barcode_invoice ? record.barcode_invoice.map((barcode, i) =>
                                                (barcode.barcode) ?
                                                    <span key={i}>
                                                        { barcode.barcode ? momentDateDisplay(barcode.barcode.prduction_date).replace(/\//g, '') : ''}{ barcode.barcode ? generateBarcodeBatchId(barcode.barcode) : '-' } <br/>
                                                    </span>
                                                : ''
                                            ) : ''
                                        }
                                    </span>,
                                action: (record) =>
                                    <span>
                                        <Link to='/user/shipping-view/' state={record.id} className="btn btn-sm btn-outline-success m-1">
                                            Print Label & Invoice
                                        </Link>
                                        <Link className="btn btn-sm btn-outline-success m-1" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={() => setChangeStatus({id: record.id, status: 4})}>
                                            Shipped
                                        </Link>
                                    </span>
                            }}
                        />
                    </div>
                </div>
            </div>
            <ConfirmationModal msg="Are you sure to perform this action?" method={change_status ? () => changeInvoiceStatus(change_status.id, change_status.status) : ''}/>
        </>
    )
}

export default ShippingList;