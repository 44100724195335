import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getJsonData } from '../fetch-data';
import { GET_FORM_DATA } from './ApiUrl';
import { momentDateDisplay, showloader } from './Helper';
import {now} from 'lodash'

function Filter(props) {
    const [axData, setAxData] = useState({
        user_id: '',
        date: '',
        material_id: '',
        user_list: '',
        unit_type: ''
    })

    useEffect(() => {
        showloader(true)
        getJsonData(GET_FORM_DATA.FILTER_USER_LIST, (res) => {
            showloader()
            if(res.status){
                setAxData(prevState => ({
                    ...prevState,
                    user_list: res.data.data
                }));
            }
        }, true);

    }, [])

    const changeUser = (e) => {
        setAxData(prevState => ({
            ...prevState,
            user_id: e.target.value
        }));
        props.onFilterChange({'page': 1, 'material_id': axData.material_id, 'user_id': e.target.value, 'date': axData.date ? momentDateDisplay(axData.date, false, false, 'YYYY-MM-DD') : ''});
    }

    const changeMaterial = (e) => {
        let index = e.target.selectedIndex
        let ele = e.target.childNodes[index]

        setAxData(prevState => ({
            ...prevState,
            material_id: e.target.value,
            unit_type : ele.getAttribute('data-unit')
        }));
        props.onFilterChange({'page': 1, 'material_id': e.target.value, 'user_id': axData.user_id, 'date': axData.date ? momentDateDisplay(axData.date, false, false, 'YYYY-MM-DD') : '', 'unit_type': ele.getAttribute('data-unit')});
    }

    const changeDate = (date) => {
        setAxData(prevState => ({
            ...prevState,
            date : momentDateDisplay(date, false, false, 'YYYY-MM-DD')
        }));
        props.onFilterChange({'page': 1, 'material_id': axData.material_id, 'user_id': axData.user_id, 'date': momentDateDisplay(date, false, false, 'YYYY-MM-DD') });
    }

    const changeMonth = (date) => {
        setAxData(prevState => ({
            ...prevState,
            date : momentDateDisplay(date, false, false, 'YYYY-MM')
        }));
        props.onFilterChange({'page': 1, 'date': momentDateDisplay(date, false, false, 'M-Y') });
    }

    const resetFilter = () => {
        setAxData(prevState => ({
            ...prevState,
            date : '', 
            user_id: '', 
            material_id: '',
            unit_type: ''
        }));
        props.onFilterChange(now());
    }

    return(
        <div className="month-wrapperbox">
            { props.filter_type.includes('Date') && <DatePicker
                    placeholderText="Select Date"
                    onChange={(e) => changeDate(e)}
                    name="date_filter"
                    id="date_filter"
                    maxDate={new Date()}
                    value={ axData.date ? momentDateDisplay(axData.date) : ''}
                    selected={axData.date ? new Date(axData.date) : ''}
                    autoComplete="Off"
                    popperModifiers={[
                        {
                          name: 'arrow',
                          options: {
                            padding: ({ popper, reference }) => ({
                              right: Math.min(popper.width, reference.width) + 50,
                            }),
                          },
                        },
                    ]}
            /> }

            { props.filter_type.includes('MonthYear') && <DatePicker
                placeholderText = 'Select Month'
                onChange={changeMonth}
                showMonthYearPicker
                showFullMonthYearPicker
                value={axData.date ? momentDateDisplay(axData.date, false, false, 'MMM, Y') : ''}
                selected={axData.date ? new Date(axData.date) : ''}
                popperModifiers={[
                    {
                      name: 'arrow',
                      options: {
                        padding: ({ popper, reference }) => ({
                          right: Math.min(popper.width, reference.width) + 50,
                        }),
                      },
                    },
                ]}
            /> }

            { props.filter_type.includes('User') && <select className="btn btn-sm btn-fefault ms-2 border mb-1" name="user_filter" id="user_filter" value={axData.user_id} onChange={changeUser}>
                <option value=''>Select User</option>
                { axData.user_list && axData.user_list.length > 0 ? (
                    axData.user_list.map(item => {
                        return <option key={item.id} value={item.id} >{item.name}</option>;
                    } )
                ) : null }
            </select> }

            { props.filter_type.includes('Material') && <select className="btn btn-sm btn-fefault ms-2 border mb-1" name="material_filter" id="material_filter" value={axData.material_id} onChange={changeMaterial}>
                { props.material_list ? props.material_list.map((materialType) =>
                    <option key={materialType.id} value={materialType.id} data-unit={materialType.unit}>{ materialType.code && materialType.code != 0 ? `(${materialType.code})`: '' } {materialType.name}</option>
                ) : '' }
            </select> }

            <button className="ms-2 mb-1 btn btn-primary btn-sm" onClick={resetFilter}>Reset</button>
        </div>
    )
}
 
export default Filter;