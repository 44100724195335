export const ENDPOINT = process.env.REACT_APP_ENDPOINT
export const ASSET_ENDPOINT = process.env.REACT_APP_ASSET_ENDPOINT
export const APP_ENDPOINT = process.env.REACT_APP_SERVER_ENDPOINT

export const AUTH = {
    LOGIN: ENDPOINT + `login`,
    LOGOUT: ENDPOINT + `logout`,
    AUTH_USER: ENDPOINT + `get-auth`,
    GET_USER_FORM: ENDPOINT + `get-form`,
    DIRECT_LOGIN: ENDPOINT + `user/direct-login`,
    CHANGE_PASSWORD: ENDPOINT + `user/update-password`
};

export const USER = {
    USER_LIST : ENDPOINT + `get-users`,
    USER_DELETE: ENDPOINT + `delete-user`,
    USER_EDIT: ENDPOINT + `edit-user`,
    UPDATE_USER: ENDPOINT + `update-user`,
    ADD_USER: ENDPOINT + `add-user`,
    USER_ACTIVITY_LIST: ENDPOINT + `user-activity-list`
}

export const DASHBOARD = {
    STATS: ENDPOINT + `admin-statistics`,
    SEND_FINAL_ENVENTORY: ENDPOINT + `send-final-inverntory`
};

export const EDIT_REQUEST = {
    LIST: ENDPOINT + `edit-request-list`,
    COUNT: ENDPOINT + `get-edit-request`,
    STATUS_UPDATE: ENDPOINT + `edit-request-status-update`,
    REQUEST: ENDPOINT + `edit-request`
}

export const SETTING = {
    THRESHHOLDS: ENDPOINT + `settings`,
    SYSTEM_DATA_STORE: ENDPOINT + `system-data-store`,
    SYSTEM_DATA_LIST: ENDPOINT + `system-data-list`,
    SYSTEM_UPDATE_STATUS : ENDPOINT + `system-data-status`
};

export const ASSET_STATUS = {
    
}

export const OPERATIONS = {
    LIST : ENDPOINT + `operation-list`,
    DELETE : ENDPOINT + `operation-delete`,
    DETAIL : ENDPOINT + `operation-detail`,
    STORE: ENDPOINT + `operation-store`,
    UPDATE: ENDPOINT + `operation-update`
}

export const BIOMASS = {
    LIST : ENDPOINT + `biomassbaging-list`,
    DELETE : ENDPOINT + `biomassbaging-delete`
}

export const EXTRACTION = {
    PRE_LIST : ENDPOINT + `pre-extraction-list`,
    PRE_LIST_DELETE : ENDPOINT + `pre-extraction-delete`,
    POST_LIST : ENDPOINT + `post-extraction-list`,
    POST_LIST_DELETE : ENDPOINT + `post-extraction-delete`,
}

export const INVENTORY = { 

    LIST: ENDPOINT + `inventory-list`,
    STOCK_LIST: ENDPOINT + `inventory-stock-list`,
    SPEND_LIST: ENDPOINT + `inventory-spend-list`,
    DELETE_SPEND: ENDPOINT + `inventory-spend-delete`,
    INVENTORY_ADD_STOCK: ENDPOINT + `inventory-stock-add`,
    UPDATE_WASTAGE: ENDPOINT + `update-wastage`,
    ADD_ATTACHMENT: ENDPOINT + `add-attachment`,
    INVENTORY_DELETE: ENDPOINT + `inventory-delete`,
    INVENTORY_STOCK_EDIT: ENDPOINT + `inventory-stock-edit`,
    INVENTORY_STOCK_UPDATE: ENDPOINT + `inventory-stock-update`
    
}

export const GET_FORM_DATA = {
    GET_BATCH_LIST: ENDPOINT + `batch-list`,
    GET_STORAGE_TO_BARCODE: ENDPOINT + `get-storage-to-barcode`,
    SYSTEM_DATA_LIST: ENDPOINT + `system-data-list`,
    FILTER_USER_LIST: ENDPOINT  + `get-users`,
    GET_CUSTOMERS: ENDPOINT + `get-customers`
}

export const BARCODE = {
    ADD_BARCODE: ENDPOINT + `admin/barcode-genrator-store`,
    BARCODE_GENERATE_LIST: ENDPOINT + `barcode-genrator-list`,
    GET_BARCODE_DETAIL: ENDPOINT + `barcode-genrator-detail`,
    UPDATE_BARCODE: ENDPOINT + `barcode-genrator-update`,
    DELETE_BARCODE: ENDPOINT + `barcode-genrator-delete`,
    BARCODE_PRINT: ENDPOINT + `barcode-print-data`,
    BARCODE_RELEASE: ENDPOINT + `barcode-update-status`,
    GET_BATCH_BARCODE: ENDPOINT + `get-batch-to-barcode`
}

export const INVOICE = {
    LIST: ENDPOINT + `list-invoices`,
    UPDATE_STATUS: ENDPOINT + `update-invoice-status`,
    GET_INVOICE: ENDPOINT + `get-invoice`,
    PDF_INVOICE: ENDPOINT + `invoice-pdf`,
    DELETE_INVOICE: ENDPOINT + `delete-invoice`,
    INVOICE_SETTING: ENDPOINT + `invoice-settings`,
    PAID_LIST: ENDPOINT + `paid-list-invoices`,
}

export const TESTING_CENTER = {
    ADD_TESTING_CENTER: ENDPOINT + `testingcenter-store`,
    TESTING_CENTER_LIST: ENDPOINT + `testingcenter-list`,
    TESTING_CENTER_DELETE: ENDPOINT + `testingcenter-delete`
}

export const WASTAGE = {
    LIST: ENDPOINT + `wastage-list`,
    ADD: ENDPOINT + `add-wastage`
}

export const FARM = {
    LIST: ENDPOINT + `farm-list`,
    STORE: ENDPOINT + `farm-store`
}

export const BIOMASS_BAGGING_SERVICE = {
    DETAILS: ENDPOINT + `biomassbaging-detail`,
    UPDATE: ENDPOINT + `biomassbaging-update`,
    STORE: ENDPOINT + `biomassbaging-store`,
    BAGGING_ALL: ENDPOINT + `biomassbaging-all`
}

export const PRE_EXTRACTION = {
    UPDATE: ENDPOINT + `preextraction-update`,
    STORE: ENDPOINT + `preextraction-store`,
    DETAILS: ENDPOINT + `preextraction-detail`
}

export const POST_EXTRACTION = {
    UPDATE: ENDPOINT + `postextraction-update`,
    DETAILS: ENDPOINT + `postextraction-detail`,
    STORE: ENDPOINT + `postextraction-store`
}

export const INVENTORY_SPEND = {
    ADD_SPEND: ENDPOINT + `inventory-spend`,
    DETAIL_SPEND: ENDPOINT + `inventory-spend-detail`,
    UPDATE: ENDPOINT + `inventory-spend-update`
}


export const EXPORT_DATA_DOWNLOAD = ENDPOINT + `admin/export-data`