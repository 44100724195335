import { Outlet } from "react-router-dom";
import Header from "./Header";

export const Frontend = () => {
    return <Outlet />
}

export const Backend = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}