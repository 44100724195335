import React, { useEffect,useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { permission, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { BARCODE, GET_FORM_DATA } from '../../components/ApiUrl';

const UpdateBarcode = () => {

    window.document.title = 'Update Barcode'
    const navigate = useNavigate()
    const location = useLocation()
    const [context] = useContext(Context)
    const [batchList, setBatchList] = useState('')
    const [materialList, setMaterialList] = useState('')
    const [storageTypeList, setStorageTypeList] = useState('')
    const [barcode_detail] = useState(location.state)

    const [selectedBatch, setSelectedBatch] = useState("");
    const [selectedStorage, setSelectedStorage] = useState("")
    const [selectedMaterial, setSelectedMaterial] = useState("")

   const updateBarcode = (e) => {
        let form_data = new FormData(document.getElementById('updateBarcodeForm'));

        if(validateForm(e, 'updateBarcodeForm')){
            showloader(true)
            postFormData(BARCODE.UPDATE_BARCODE + '/' + barcode_detail.id, form_data, (res) => {
                showloader()
                if(res.status){
                    setTimeout(() =>{
                        navigate('/admin/barcode')
                    },3000)
                    
                }
            }, true);
        }

    }

    const getData = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.GET_BATCH_LIST, (res) => {
            showloader()
            if (res.status) {
                setBatchList(res.data)
            }
        }, true)

        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=material', (res) => {
            showloader()
            if(res.status){
                setMaterialList(res.data.data)
            }
        }, true);

        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=storage', (res) => {
            showloader()
            if(res.status){
                setStorageTypeList(res.data.data)
            }
        }, true);
    }

    const getStorageData = (e) => {
        setSelectedBatch(e.target.value)
            if(e.target.value){
                showloader(true)
                getJsonData(GET_FORM_DATA.GET_STORAGE_TO_BARCODE + '/' +e.target.value, (res) => {
                    showloader()
                    if(res.status){
                        document.querySelector('#storage').value = parseInt(res.data.storage)+1;
                    }
                }, true);
            }      
    }

    useEffect(() => {
        getData();
    },[])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Update Barcode</h2>
                </div>
                <div className="col-sm-12 text-end">
                    <Link to="/admin/barcode" className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow" /></svg>
                        Back
                    </Link>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form method="post" className="row g-3 g-sm-4" id="updateBarcodeForm">
                        <div className="col-6">
                            <label htmlFor="date" className="form-label"> Production Date <strong className="text-danger">*</strong></label>
                            <input type="date" id="prduction_date" name="prduction_date" className="form-control" placeholder="dd-mm-yyyy" defaultValue={barcode_detail && barcode_detail.prduction_date} />
                        </div>
                        <div className="col-6">
                            <label htmlFor="batch_id" className="form-label">Batch Id <strong className="text-danger">*</strong></label>
                            <select id="batch_id" name="batch_id" className="form-select form-select-lg" value={selectedBatch ? selectedBatch : (barcode_detail && barcode_detail.batch_id)} onChange={(e) => getStorageData(e)} required>
                                <option value="">Choose</option>
                                {
                                    batchList ? batchList.map((batch, num) =>
                                        <option key={num} value={batch.batch_id} >{batch.batch_id}</option>
                                    ):''
                                }
                            </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="recorded_by" className="form-label">Storage <strong className="text-danger">*</strong></label>
                            <input type="number" min="1" id="storage" name="storage"  className="form-control"  placeholder="Storage" required defaultValue={barcode_detail && barcode_detail.storage } readOnly/>
                        </div>
                        <div className="col-6">
                            <label htmlFor="material_type" className="form-label">Material Type<strong className="text-danger">*</strong></label>
                            <select id="material_type" name="material_type"  className="form-select form-select-lg" value={selectedMaterial ? selectedMaterial : barcode_detail && barcode_detail.material_type && barcode_detail.material_type.id } onChange={(e) => setSelectedMaterial(e.target.value)} required>
                                <option value=''>Choose</option>
                                {
                                    materialList ? materialList.map((material, num) =>
                                        <option key={num} value={material.id} > ({material.code}) {material.name}</option>
                                    ):''
                                }
                            </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="storage_type" className="form-label">Storage Type<strong className="text-danger">*</strong></label>
                            <select id="storage_type" name="storage_type" className="form-select form-select-lg" value={selectedStorage ? selectedStorage : barcode_detail && barcode_detail.storage_type} onChange={(e) => setSelectedStorage(e.target.value) } required>
                                <option value='5' >Choose</option>
                                {
                                    storageTypeList ? storageTypeList.map((storagetype, num) =>
                                        <option key={num} value={storagetype.id} >{storagetype.name}</option>
                                    ):''
                                }
                            </select>
                        </div>
                        <div className="col-6">
                            <label htmlFor="recorded_by" className="form-label">Weight<small>(Kg)<strong className="text-danger">*</strong></small></label>
                            <input type="number" id="weight" name="weight" className="form-control" placeholder="Weight" defaultValue={barcode_detail && barcode_detail.weight} />
                        </div>
                        <div className="col-6">
                            <label htmlFor="attachment" className="form-label">Attachment</label>
                            <input type="file" accept=".jpeg,.jpg,.png,.pdf" id="attachment" name="attachment" className="form-control" placeholder="Attachment" />
                        </div>
                        {
                            (permission(context && context.auth.permission, 'update')) ?
                                <div className="mb-4 mb-sm-5">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => updateBarcode(e)}>Submit</button>
                                </div>
                            :''
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UpdateBarcode;