import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { INVOICE } from '../../components/ApiUrl';
import { DataList } from '../../components/DataList'
import { now } from 'lodash'
import { ConfirmationModal, generateBarcodeBatchId, momentDateDisplay, showloader } from '../../components/Helper';
import { postData } from '../../fetch-data';
 
const InvoiceList = () => {

    window.document.title = 'Invoice List'

    const [refresh, setRefresh] = useState('')
    const [update_status, setUpdateStatus] = useState(false)

    const dt =  {
        id: 'invoice-list',
        fetchurl: INVOICE.LIST,
        columns: [
            { name: 'id', title: 'ID', width: 10 },
            { name: 'qb_invoice_id', title: 'QB Invoice Id' },
            { name: 'customer_info', title: 'Customer Name', width: 250},
            { name: 'material_type', title: 'Material Type'},
            { name: 'net_term', title: 'Net Term', width: 100},
            { name: 'invoice_amount', title: 'Invoice Amount ($)', width: 10 },
            { name: 'barcode_invoice', title: 'Barcode Informations', width: 300, sorting: false},
            { name: 'created_at', title: 'Date Created'},
            { name: 'status', title: 'Status'},
            { name: 'action', title: 'Action'}
        ]
    }

    const changeInvoiceStatus = (id, status) => {
        showloader(true)
        postData(INVOICE.UPDATE_STATUS, {id: id,status: status}, (res) =>{
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
                setRefresh(now)
            }
        }, true)
    }

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                        <h2 className="page-heading">Invoice List</h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(records) =>
                            <div className="ms-auto">
                                <Link to="/admin/invoice"  className="btn btn-sm btn-success">
                                    <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                    Back
                                </Link>
                            </div>
                        }
                        edit_column={{
                            customer_info: (record) => 
                                <span>
                                    {record && record.customer_info  ? JSON.parse(record.customer_info).customerName : '-'}
                                </span>,
                            material_type: (record) => 
                                <span>
                                    {record.material_type? '('+ record.material_type.code +') ' + record.material_type.name:'-'}
                                </span>,
                            net_term: (record) => 
                                <span>
                                    {record.net_term ? record.net_term : '-'}
                                </span>,
                            barcode_invoice: (record) => 
                                <span>
                                    {
                                        record.barcode_invoice ? record.barcode_invoice.map((barcode) =>
                                            (barcode.barcode)?
                                                <span key={(barcode.barcode.id) ? barcode.barcode.id:'-'}>
                                                    { barcode.barcode ? momentDateDisplay(barcode.barcode.prduction_date).replace(/\//g, '') : ''}{ barcode.barcode ? generateBarcodeBatchId(barcode.barcode) : '-' } <br/>
                                                </span>
                                                
                                            : ''
                                        ) : '-'
                                    }
                                </span>,
                            created_at: (record) =>
                                <span>
                                    {record && momentDateDisplay(record.created_at)}
                                </span>,
                            status : (record) =>
                                <span>
                                    { record && record.status === 1 ? 'Invoice Sent' : '' }
                                    { record && record.status === 2 ? 'Return' : '' }
                                    { record && record.status === 3 ? 'Paid' : '' }
                                    { record && record.status === 4 ? 'Shipped' : '' }
                                </span>,
                            action : (record) =>
                                <span>
                                    <Link to='/admin/invoice-view' state={record.id} className="btn btn-sm btn-outline-success m-1">
                                        <svg className="icon"><use xlinkHref="#icon_eye"/></svg>
                                    </Link>
                                    { record.status !== 4 && record.status !== 3 ?
                                        <Link className="btn btn-sm btn-outline-success m-1" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={(e) => setUpdateStatus({id: record.id, status: 3})}>
                                            <span className='px-1'>$</span>
                                        </Link>
                                    : '' }
                                </span>
                        }}
                    />
                    <ConfirmationModal msg="Are you sure perform this action?" method={update_status ? () => changeInvoiceStatus(update_status.id, update_status.status) : ''} />
                </div>
            </div>
        </div>
    )
}

export default InvoiceList;