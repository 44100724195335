import { useEffect, useState } from "react";
import { getJsonData } from "../fetch-data";
import { showloader, toastNotify, __echoText } from "./Helper";
import { useLocation } from "react-router-dom";

const PAGELENGTH = [10, 25, 50, 100];

export const Datatable = (props, dt, callback) => {
    let perpage = dt.perpage ? dt.perpage : 10;
    let pagenumber = dt.pagenumber ? dt.pagenumber : 1;
    let sortorder = dt.sortorder ? dt.sortorder : 'DESC';

    let query_string = `?page=${pagenumber}&per_page=${perpage}`;

    if (dt.search) {
        query_string = `${query_string}&search=${dt.search}`;
    }

    if (dt.sortcolumn && dt.sortorder) {
        query_string = `${query_string}&sort=${dt.sortcolumn}&order_by=${sortorder}`;
    }

    if (dt.f_type) {
        query_string = `${query_string}&f_type=${dt.f_type}`;
    }

    if (dt.query_string) {
        query_string = `${query_string}&${dt.query_string}`
    }

    if (dt.filter_string || props.dt.filter_string) {
        query_string = `${query_string}&${props.dt.filter_string}`
    }

    showloader(true)
    getJsonData(dt.fetchurl + query_string, (res) => {
        showloader()
        if (res.data) {
            callback(res.data)
        } else {
            toastNotify(res)
        }
    }, (err) => { }, false, dt.loading);
}

export const dtControllers = (e, type, setDt) => {

    if (type === 'perpage') {
        setDt(prevState => ({
            ...prevState,
            perpage: e,
            pagenumber: 1
        }))
    }

    if (type === 'pagination') {
        setDt(prevState => ({
            ...prevState,
            pagenumber: e.split('=')[1]
        }))
    }

    if (type === 'sortby') {
        let set_orderby = e.target.dataset.orderby;
        let set_sortby = e.target.dataset.sortby;
        document.querySelectorAll(e.target.parentElement.nodeName + '> th').forEach((ele) => {
            ele.classList.remove('active')
        });
        e.target.dataset.orderby = (set_orderby === 'asc' ? 'desc' : 'asc');
        e.target.classList.add('active');
        setDt(prevState => ({
            ...prevState,
            sortcolumn: set_sortby,
            sortorder: set_orderby
        }))
    }

    if (type === 'f_type') {
        setDt(prevState => ({
            ...prevState,
            f_type: e
        }))
    }
}

export const PageLength = (props) => {
    return (
        <>
            <small className="text-muted me-2">Showing</small>
            <div className="me-2 dropdown w-auto rounded d-inline-flex border border-1 me-2">
                <button type="button" className="btn btn-pageof minw-130 bg-white rounded dropdown-toggle text-nowrap text-blue" data-bs-toggle="dropdown">
                    <span>
                        {props.records.from ? props.records.from : '0'}-{props.records.to ? props.records.to : '0'}
                    </span> of
                    <span> {props.records.total ? props.records.total : '0'}</span>
                </button>

                <ul className="dropdown-menu dropdown-menu-start border-0 shadow px-2 minw-auto text-center">
                    {PAGELENGTH.map((ele, num) => {
                        return (<li role="row" key={num}>
                            <button type="button" title={ele} className="dropdown-item py-2"
                                onClick={() => dtControllers(ele, 'perpage', props.setDt)}>{ele}</button>
                        </li>)
                    })}
                </ul>
            </div>
            <small className="text-muted">Entries</small>
        </>

    )
}

export const PageLoading = (props) => {
    return (
        <tr role="row">
            <td colSpan={props.colSpan}>
                <div className={`d-flex align-items-center justify-content-center ${props.records.total === 0 ? 'text-danger' : 'text-success'}`}>
                    {props.records.total === 0 ? 'No Record Found' : 'Loading ...'}
                </div>
            </td>
        </tr>
    )
}

export const PageNavigation = (props) => {
    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center justify-content-md-end">
                {props.records.links.map((ele, num) => {
                    return (
                        <li className={`page-item ${ele.active ? ' active' : ''} ${ele.url === null ? 'disabled' : ''}`} key={num}>
                            <span className="page-link" role={"button"} onClick={() => { window.scrollTo({ top: 0, behavior: 'smooth' }); dtControllers(ele.url, 'pagination', props.setDt) }}>
                                {<div dangerouslySetInnerHTML={{ __html: ele.label }} />}
                            </span>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

export const TableHead = (props) => {
    const [column] = useState(props.dt.columns);

    return (
        <thead>
            <tr className="table-dark">
                {column && column.map((item, key) => {
                    return (
                        (item.sorting === false || item.name === 'action' || item.name === 'image') ? <th key={key} width={item.width ?? '100'} className={`text-nowrap ${item.class ?? ''} ${item.visible === false ? 'd-none' : ''}`}>{item.title}</th> :
                            <th key={key} width={item.width ?? '100'} className={`text-nowrap ${item.class ?? ''} ${item.visible === false ? 'd-none' : ''}`} data-orderby={item.order ?? "asc"} data-sortby={item.name} onClick={(e) => item.name !== 'action' ? dtControllers(e, 'sortby', props.setDt) : ''}>{item.title}</th>
                    )
                })}
            </tr>
        </thead>
    )
}

export const TableBody = (props) => {
    const location = useLocation();
    const path = location.pathname === '/admin/barcode' ? true : false;
    return (
        <tbody>
            {props.records.data && props.records.total !== 0 ? props.records.data.map((ele, num) => {

                return (
                    <tr role="row" key={num} className={`${ele.status === 0 && path && 'table-success'}`}>
                        {props.dt.columns && props.dt.columns.map((item, key) => {
                            return (
                                <td key={key} className={`${item.visible === false ? 'd-none' : ''}`}>
                                    {props.edit_column && props.edit_column[item.name] ? props.edit_column[item.name](ele) :
                                        (item && __echoText(ele[item.name], item.type))}
                                </td>)
                        })
                        }
                    </tr>
                )
            })
                : <PageLoading records={props.records} colSpan={props.dt.columns.length} />}
        </tbody>
    )
}

export const DataList = (props) => {
    const [dt, setDt] = useState(props.dt)
    const [searching] = useState(props.searching ?? true)
    const [pagelength] = useState(props.pagelength ?? true)
    const [records, setRecords] = useState('');

    useEffect(() => {
        Datatable(props, dt, (result) => {
            setRecords(result)
        });
    }, [dt, props.refresh])

    return (
        <>
            {props &&
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col ms-auto d-flex align-items-center">
                            {pagelength && <PageLength records={props.index_record ? records.totalData ?? '' : records} setDt={setDt} />}
                            <div className="w-100 d-inline-flex text-end">
                                {props.outerbutton ? props.outerbutton(records, setDt) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="table-spinner active" id={dt.id ?? 'table'}>
                        <div className="table-responsive">
                            <table className="table align-middle table-common table-bordered mt-3 rounded-3" id={dt.id ?? 'dataTable'}>
                                <TableHead setDt={setDt} dt={dt} />
                                <TableBody dt={dt} records={props.index_record ? records.totalData ?? '' : records} edit_column={props.edit_column} setDt={setDt} />
                            </table>
                        </div>
                        {!dt.nopaginate && records && records.total !== 0 && <PageNavigation dt={dt} setDt={setDt} records={props.index_record ? records[props.index_record] : records} />}
                    </div>
                </div>}
        </>
    )
}

