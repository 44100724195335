import React, { useEffect, useContext, useState } from 'react';
import { initialFormState, permission, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { INVOICE } from '../../components/ApiUrl';

const Configuration = () => {

    window.document.title = 'Invoice Setting'

    const [sett_data, setSettingData] = useState([])
    const [context] = useContext(Context)

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(validateForm(e, 'invoice_setting')){
            let form_data = new FormData(document.getElementById('invoice_setting'));
            showloader(true)
            postFormData(INVOICE.INVOICE_SETTING, form_data, (res) => {
                showloader()
                if(res.status){
                    initialFormState('invoice_setting');
                    getSettings()
                }
            }, true);

        }
    }

    const getSettings = () => {
        showloader(true)
        getJsonData(INVOICE.INVOICE_SETTING +"?type=get", (res) => {
            showloader()
            if(res.status){
                setSettingData(res.data);
            }
        }, true);
    }

    useEffect(() => {
        getSettings()
    }, [])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Invoice Settings</h2>
                </div>

                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <div className="col-sm-12 mb-4">
                        <h5 className="fw-bold">Ship Station Settings</h5>
                        <hr/>
                    </div>
                    <form className="row g-3 g-sm-4" method="post" id="invoice_setting">
                        {sett_data && sett_data.map(item =>
                            <>
                                { (item.key_name === 'QB_COMPANY_ID') ?
                                    <div className="col-sm-12">
                                        <h5 className="fw-bold">Quickbook Settings</h5>
                                        <hr className="mb-0"/>
                                    </div> : ''
                                }
                                <div className="col-6">
                                    <label htmlFor={item.key_name} className="form-label">
                                    {item.key_name.toUpperCase().replace(/_/g,' ')} <strong className="text-danger">*</strong></label>
                                    {
                                    (item.key_name === 'access_token')?
                                        <textarea name={item.key_name} id={item.key_name} defaultValue={item.key_value} className="form-control" rows={10} required />
                                        : 
                                            <input type="text" name={item.key_name} id={item.key_name} defaultValue={item.key_value} className="form-control" placeholder="" required />
                                    }
                                    <div className='invalid-feedback'>Please enter {item.key_name.toUpperCase().replace(/_/g,' ')}</div>
                                </div>
                            </>)
                        }
                        {permission(context && context.auth.permission, 'update') &&
                            <div className="mb-4 mb-sm-5">
                                <button type="button" onClick={(e) => handleSubmit(e)} className="btn btn-lg btn-primary btn-160">Submit</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>   
    )
}

export default Configuration;