import React, { useState, useEffect } from 'react';
import { initialFormState, momentDate, momentDateDisplay, showloader, validateForm } from '../../components/Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { getJsonData, postFormData } from '../../fetch-data';
import { GET_FORM_DATA, INVENTORY_SPEND } from '../../components/ApiUrl';
import { useContext } from 'react';
import Context from '../../components/Context';

const Spend = (props) => {

    const location = useLocation()
    const navigate = useNavigate()
    const solvent_data = location.state ? location.state : ''

    const [context] = useContext(Context)
    const [solvent_list, setSolventList] = useState('')
    const [unit_type, setUnitType] = useState(props.inventory_id == 2 ? 'gallons' : 'kg')
    const [recorder_by] = useState(context && context.auth.name)
    const [date] = useState(momentDate('', false, false, 'YYYY-MM-DD', true))
    const [default_time, setDefaultTime] = useState(solvent_data ? momentDateDisplay(solvent_data.log_datetime, false, false, 'HH:mm') : momentDate('', false, false, 'HH:mm', true))
    const [editRequest, setEditRequest] = useState(solvent_data ? true : false)
    const [spend, setSpend] = useState(solvent_data ? solvent_data : '')

    const changeUnit = (e) => {
        let index = e.target.selectedIndex
        let ele = e.target.childNodes[index]
        setUnitType(ele.getAttribute('data-unit'))
    }

    const handleInputChanged = (e) => {
        setDefaultTime(e.target.value)
    }

    const getSolvent = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=solvent', (res) => {
            showloader()
            if(res.status){
                setSolventList(res.data.data)
                setUnitType(res.data.data[0].units)
            }
        }, true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(document.getElementById('solvent_type_form'))

        if(props.inventory_id != 2){
            formData.append("inventory_id", props.inventory_id);
        }
        if(validateForm(e, 'solvent_type_form')){
            if(editRequest){
                formData.append('id', solvent_data.id)

                postFormData(INVENTORY_SPEND.UPDATE, formData, (res) => {
                    if(res.status){
                        props.inventory_id == 2 ? navigate('/user/solvent-spend-list') : navigate('/user/cbda-crude-oil-spend-list');
                    }
                }, true);
            }else{
                postFormData(INVENTORY_SPEND.ADD_SPEND, formData, (res) => {
                    if(res.status){
                        initialFormState('solvent_type_form')
                        props.inventory_id == 2 ? navigate('/user/solvent-spend-list') : navigate('/user/cbda-crude-oil-spend-list');
                    }
                }, true);
            }
        }
    }

    useEffect(() => {
        getSolvent()
    }, [])
    return (
        <form method="post" className="row g-3 g-sm-4" id="solvent_type_form">
            { props.inventory_id == 2 &&
                <div className="col-6">
                    <label htmlFor="inventory_id" className="form-label">Solvent Type<strong className="text-danger">*</strong></label>
                    <select id="inventory_id" name="inventory_id" className="form-select form-select-lg" onChange={(e) => changeUnit(e)} defaultValue={props.inventory_id} required>
                        {/* <option value="">Choose Solvent Type</option> */}
                        { solvent_list && solvent_list.map((solvent, i) =>
                            <option key={i} value={solvent.id} data-unit={solvent.units}>{solvent.name}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">Please choose Solvent Type.</div>
                </div> 
            }
            <div className="col-6">
                <label htmlFor="recorded_by" className="form-label">Recorded by <strong className="text-danger">*</strong></label>
                <input type="text" id="recorded_by" name="recorded_by" defaultValue={spend ? spend.recorded_by : recorder_by} className="form-control" placeholder="Recorded by" required/>
                <div className="invalid-feedback">Please choose Recorded By.</div>
            </div>
            <div className="col-6">
                <label htmlFor="weight" className="form-label">Weight <small>({unit_type && unit_type})</small> <strong className="text-danger">*</strong></label>
                <input type="number" id="weight" name="weight" min={0} step="any" defaultValue={spend && spend.weight} className="form-control" placeholder="Weight" required />
                <div className="invalid-feedback">Please enter valid Weight.</div>
            </div>
            <div className="col-6">
                <label htmlFor="date" className="form-label">Date <strong className="text-danger">*</strong></label>
                <input type="date" id="date" name="date" readOnly value={spend ? momentDateDisplay(spend.log_datetime, false, false, 'YYYY-MM-DD') :date} className="form-control" placeholder="dd-mm-yyyy" required/>
                <div className="invalid-feedback">Please enter Date.</div>
            </div>
            <div className="col-6">
                <label htmlFor="time" className="form-label">Time <strong className="text-danger">*</strong></label>
                <input type="time" id="time" name="time" onChange={(e) => handleInputChanged(e)} value={default_time} className="form-control" placeholder="dd-mm-yyyy" required/>
                <div className="invalid-feedback">Please enter Time.</div>
            </div>
            <div className="mb-4 mb-sm-5">
                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
            </div>
        </form>  
    )
}

export default Spend;