import React from 'react';
import { Link } from 'react-router-dom';
import AddStock from '../Inventory/AddStock';

const AddSolvent = () => {

    window.document.title = "Add Solvent"
    
    return(
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Add Solvent</h2>
                </div>
                <div className="col-sm-12 text-end">
                    <Link to="/admin/solvent-list" className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                        List
                    </Link>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <AddStock inventory_id="2"/>
                </div>
            </div>
        </div>
    )
}

export default AddSolvent