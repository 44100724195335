import React from 'react';

function NotAllowed() {
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
            <section className="d-flex align-items-center align-middle p-3 w-100 start-0 top-0 h-100">
                <style jsx="true">{`
                    h1{
                        font-size: calc( 12vw + 24px);
                        opacity: 0.40
                    }
                    h5{
                        font-size: calc( 1vw + 16px);
                    }
                `}</style>
                <div className="col-sm-12 text-center pb-4">
                    <h1 className="fw-bolder m-0">404</h1>
                    <h5 className="fw-bolder m-0">Opps! Access Denied</h5>
                    <p>Sorry, you don't have permission to view this page.</p>
                    <hr className="w-50 ms-auto me-auto"/>
                    <button onClick={() => this.props.history.goBack()} className="btn btn-dark px-4 px-sm-5">Back</button>
                </div>
            </section>
            </div>
        </div>
    )
}

export default NotAllowed;