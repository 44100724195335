import { now } from "lodash";
import React, { useContext } from "react";
import { postData } from "../fetch-data";
import { EDIT_REQUEST } from "./ApiUrl";
import Context from "./Context";

const EditRequest = (props) => {
    const [context] = useContext(Context)

    const editRequest = (e) => {
        e.preventDefault()
        let data = { 
            user_id: context.auth.id,
            form_id: props.form_id,
            refrence_id: props.record_id,
            request_by: context.auth.name
        }
        postData(EDIT_REQUEST.REQUEST, data, (res) => {
            if(res.status){
                props.update_table(now)
            }
        }, true);
    }

    return(
        <buttton className="btn btn-sm btn-outline-primary ms-1" id={props.record_id} onClick={(e) => editRequest(e)}>
            <svg className="icon "><use xlinkHref="#icon_request-edit"/></svg>
        </buttton>
    )
}

export default EditRequest;