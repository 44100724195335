import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { initialFormState, momentDate, momentDateDisplay, permission, showloader, validateForm } from '../../components/Helper';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { subDays } from 'date-fns';
import Context from '../../components/Context';
import { useLocation } from 'react-router';
import { getJsonData, postFormData } from '../../fetch-data';
import { BARCODE, GET_FORM_DATA, INVENTORY } from '../../components/ApiUrl';

const AddStock = (props) => {

    const location = useLocation()
    const edit_id = location.state ? location.state : false
    const [context] = useContext(Context);
    const [edit_request] = useState(edit_id)
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const navigate = useNavigate()
    
    const [axData, setAxData] = useState({
        inventory_id: props.inventory_id,
        user_id: '',
        id: edit_request && edit_request.id,
        material_id: '',
        batch_id: '',
        bag_num: '',
        recorded_by: context && context.auth && context.auth.name,
        vendor_name: '',
        farm_name: '',
        batch_date: momentDate('', false, false, 'YYYY-MM-DD', true),
        batch_time: momentDate('', false, false, 'HH:mm', true),
        new_date: '',
        weight: '',
        notes: '',
        purity: 0,
        attachment: null,
        from_vendor: false,
        editRequest: false,
        solventList: '',
        unit_type: '',
    })


    const handleChackboxChanged = (e) => {
        setAxData(prevState => ({
            ...prevState,
            from_vendor : e.target.checked ? true : false
        }))
    }

    // const handleInputChanged = (e) => {
    //     setAxData(prevState => ({
    //         ...prevState,
    //         [e.target.name]: e.target.value
    //     }))
    // }

    const changeUnit = (e) => {
        let index = e.target.selectedIndex
        let ele = e.target.childNodes[index]
        setAxData(prevState => ({
            ...prevState,
            unit_type : ele.getAttribute('data-unit')
        }))
    }

    const handleSubmit = (e, form_id) => {
        e.preventDefault();

        var formData = new FormData(document.getElementById(form_id));

        if(validateForm(e, form_id)){
            
            if(props.inventory_id != "2"){
                formData.append("inventory_id", props.inventory_id);
            }
            
            if(axData.editRequest){
                formData.append("id", axData.id);
                showloader(true)
                postFormData(INVENTORY.INVENTORY_STOCK_UPDATE, formData, (res) => {
                    showloader()
                    if(res.status){
                        navigate('/admin/bulk-inventory-list')
                    }
                }, true);
                // Inventory.updateStock(formData, (response) => {
                //     if (response.status == 201) {
                //         toastAlert('success', response.data.message);
                //         if(props.inventory_id == 3){
                //             if(context && context.auth && context.auth.type === 1){
                //                 history('/admin/cbda_crude_oil_list')
                //             }else{
                //                 history('/user/cbda_crude_oil_list')
                //             }
                //         }else{
                //             if(context && context.auth && context.auth.type === 1){
                //                 history('/admin/bulk_inventory_list')
                //             }else{
                //                 history('/user/bulk_inventory_list')
                //             }
                //         }
                //     } else {
                //         toastAlert('error', response.data.message)
                //     }
                //     e.target.reset()
                // }, (err) => {
                //     e.target.reset()
                //     let errorObj = err.response.data.errors;
                //     Object.keys(errorObj).map((item) => toastAlert('error', errorObj[item])
                //     )
                // })
            }else{
                formData.append("user_id", context && context.auth && context.auth.id);
                postFormData(INVENTORY.INVENTORY_ADD_STOCK, formData, (res) => {
                    if(res.status){
                        initialFormState(form_id)
                    }
                }, true);
            }

        }
        
    }

    const changeDateFilter = (date) => {
        setAxData(prevState => ({
            ...prevState,
            new_date: momentDateDisplay(date, false, false, 'YYYY-MM-DD'),
            batch_date: momentDateDisplay(date, false, false, 'YYYY-MM-DD')
        }));
    }


    const getBatchID = () => {
        showloader(true)
        getJsonData(BARCODE.GET_BATCH_BARCODE, (res) => {
            showloader()
            if(res.status){
                setAxData(prevState => ({
                    ...prevState,
                    batch_id: res.data.batch_id
                }))
            }
        }, true);
    }

    const getSolvent = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=solvent', (res) => {
            showloader()
            if(res.status){
                setAxData(prevState => ({
                    ...prevState,
                    solventList: res.data.data,
                    unit_type: res.data.data[0].units
                }))
            }
        }, true);
    }

    useEffect(() => {
        getBatchID()
        getSolvent()
            showloader(true)
            if(edit_request){
                setAxData(prevState => ({
                    ...prevState,
                    editRequest: true,
                    from_vendor: edit_id.vendor_name ? true : false
                }))
            }
    }, [])
    
    return (
        <>
            {/* Add Boimass (Hemp) | Inventory ID == 4 */}

            { props.inventory_id == 4 && 
                <form method="post" className="row g-3 g-sm-4" id="inventory_four">
                    <div className="col-6">
                        <label htmlFor="batch_id" className="form-label">Batch Id <strong className="text-danger">*</strong></label>
                        <input type="text" id="batch_id" name="batch_id" className="form-control" defaultValue={axData.batch_id} placeholder="Batch Id" required />
                        <div className="invalid-feedback">Please enter Batch ID</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="farm_name" className="form-label">Farm<strong className="text-danger">*</strong></label>
                        <select id="farm_name" name="farm_name" className="form-select form-select-lg" required>
                            <option value=''>Choose</option>
                            { props.farm_list ? props.farm_list.map((farmList, i) =>
                                <option key={i} value={farmList.name}>{farmList.name}</option>
                            ) : '' }
                        </select>  
                        <div className="invalid-feedback">Please choose Farm.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="batch_date" className="form-label"> Hemp Date <strong className="text-danger">*</strong></label>
                        <input type="date" id="batch_date" name="batch_date" defaultValue={axData.batch_date} className="form-control" placeholder="dd-mm-yyyy" readOnly required/>
                    </div>
                    <div className="col-6">
                        <label htmlFor="weight" className="form-label">Weight <small>(lbs)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="weight" name="weight" min={0} step="any" className="form-control"  placeholder="Weight" required />
                        <div className="invalid-feedback">Please enter Weight.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbda" className="form-label">CBDa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbda" name="component[cbda]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbd" className="form-label">CBD <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbd" name="component[cbd]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBD.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbna" className="form-label">CBNa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbna" name="component[cbna]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBNa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbn" className="form-label">CBN <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbn" name="component[cbn]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBN.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbga" className="form-label">CBGa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbga" name="component[cbga]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBGa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbg" className="form-label">CBG <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbg" name="component[cbg]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBG.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thcva" className="form-label">THCVa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thcva" name="component[thcva]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCVa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thcv" className="form-label">THCV <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thcv" name="component[thcv]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCV.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbdva" className="form-label">CBDVa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbdva" name="component[cbdva]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDVa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbdv" className="form-label">CBDV <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbdv" name="component[cbdv]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDV.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbca" className="form-label">CBCa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbca" name="component[cbca]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBCa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbc" className="form-label">CBC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbc" name="component[cbc]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBC.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thca" className="form-label">THCa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thca" name="component[thca]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thc" className="form-label">THC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thc" name="component[thc]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THC.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thc8" className="form-label">8THC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thc8" name="component[thc8]" min={0} step="any" className="form-control" defaultValue={0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter 8THC.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="attachment" className="form-label">Attachment</label>
                        <input type="file" id="attachment" name="attachment" className="form-control" placeholder="Attachment" accept='.jpeg,.pdf,.jpg,.png' />
                    </div>
                    { permission(context && context.auth.permission, 'add') && <div className="mb-4 mb-sm-5">
                        <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'inventory_four')}>Submit</button>
                    </div> }
                </form> 
            }

            {/* Add Solvent | Inventory ID == 2 */}

            { props.inventory_id == 2 && 
                <form method="post" className="row g-3 g-sm-4" id="inventory_two">
                    <div className="col-6">
                        <label htmlFor="inventory_id" className="form-label">Farm<strong className="text-danger">*</strong></label>
                        <select id="inventory_id" name="inventory_id" className="form-select form-select-lg" onChange={(e) => changeUnit(e)} defaultValue={axData.inventory_id} required>
                            <option value="" disabled>Choose</option>
                            { axData.solventList && axData.solventList.map((solvent, i) =>
                                <option key={i} value={solvent.id} data-unit={solvent.units}>{solvent.name}</option>
                            )}
                        </select>
                        <div className='invalid-feedback'>Please choose farm.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="vendor_name" className="form-label">Vendor Name<strong className="text-danger">*</strong></label>
                        <input type="text" id="vendor_name" name="vendor_name" defaultValue={axData.vendor_name} className="form-control" placeholder="Vendor Name" required />
                        <div className='invalid-feedback'>Please enter vendor name.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="batch_date" className="form-label"> Ethanol Date <strong className="text-danger">*</strong></label>
                        <input type="date" id="batch_date" name="batch_date" defaultValue={axData.batch_date} className="form-control" placeholder="dd-mm-yyyy" readOnly required/>
                        <div className='invalid-feedback'>Please enter ethanol date.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="weight" className="form-label">Weight <small>({axData.unit_type})</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="weight" name="weight" min={0} step="any" className="form-control" defaultValue={axData.weight} placeholder="Weight" required />
                        <div className='invalid-feedback'>Please enter weight.</div>
                    </div>
                    { permission(context && context.auth.permission, 'add') && 
                        <div className="mb-4 mb-sm-5">
                            <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'inventory_two')}>Submit</button>
                        </div> 
                    }
                </form> 
            }
            
            {/* Add CBDa Crude Oil | Inventory ID == 3 */}

            { props.inventory_id == 3 && 
                <form method="post" className="row g-3 g-sm-4" id="inventory_three">
                    <div className="col-6">
                        <label htmlFor="recorded_by" className="form-label">Recorded by <strong className="text-danger">*</strong></label>
                        <input type="text" id="recorded_by" name="recorded_by" defaultValue={edit_request.recorded_by ? edit_request.recorded_by : axData.recorded_by} className="form-control" placeholder="Recorded by" required/>
                        <div className="invalid-feedback">Please enter Recorded By.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="batch_date" className="form-label">Batch Date <strong className="text-danger">*</strong></label>
                        <div className="date-wrapperbox" style={{ display: 'block' }}>
                            <DatePicker
                                onChange={(e) => changeDateFilter(e)}
                                name="batch_date"
                                id="batch_date"
                                value={edit_request ? edit_request.batch_date : axData.batch_date}
                                className="form-control form-dateicon"
                                popperModifiers={[
                                    {
                                      name: 'arrow',
                                      options: {
                                        padding: ({ popper, reference }) => ({
                                          right: Math.min(popper.width, reference.width) + 50,
                                        }),
                                      },
                                    },
                                ]}
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="invalid-feedback">Please enter Batch Date.</div>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" defaultValue="" id="from_vendor" name="from_vendor" onChange={(e) => handleChackboxChanged(e)} checked={axData.from_vendor == 1 ? true : false}/>
                            <label className="form-check-label" htmlFor="from_vendor">
                                From Vendor
                            </label>
                        </div>
                    </div>
                    { axData.from_vendor && <>
                    <div className="col-6">
                        <label htmlFor="batch_id" className="form-label">Batch Id <strong className="text-danger">*</strong></label>
                        <input type="text" id="batch_id" name="batch_id" className="form-control" defaultValue={edit_request ? edit_request.batch_id : axData.batch_id} placeholder="Batch Id" required />
                        <div className="invalid-feedback">Please enter Batch Id.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="vendor_name" className="form-label">Vendor Name<strong className="text-danger">*</strong></label>
                        <input type="text" id="vendor_name" name="vendor_name" defaultValue={edit_request ? edit_request.vendor_name : axData.vendor_name} className="form-control" placeholder="Vendor Name" required />
                        <div className="invalid-feedback">Please enter Vendor Name.</div>
                    </div> </>}
                    <div className="col-6">
                        <label htmlFor="weight" className="form-label">Weight <small>(kg)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="weight" name="weight" min={0} step="any" className="form-control" defaultValue={edit_request ? edit_request.weight : axData.weight} placeholder="Weight" required />
                        <div className="invalid-feedback">Please enter valid Weight.</div>
                    </div>
                    <div className="col-6"></div>
                    { !axData.from_vendor && 
                        <div className="col-6">
                        <label htmlFor="batch_time" className="form-label">Time <strong className="text-danger">*</strong></label>
                        <input type="time" id="batch_time" name="batch_time" defaultValue={edit_request ? edit_request.batch_time : axData.batch_time} className="form-control" placeholder="dd-mm-yyyy" required />
                        <div className="invalid-feedback">Please enter Time.</div>
                    </div> }
                    { axData.from_vendor && <>
                    <div className="col-3">
                        <label htmlFor="cbda" className="form-label">CBDa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbda" name="component[cbda]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbda : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbd" className="form-label">CBD <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbd" name="component[cbd]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbd : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBD.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbna" className="form-label">CBNa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbna" name="component[cbna]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbna : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBNa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbn" className="form-label">CBN <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbn" name="component[cbn]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbn : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBN.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbga" className="form-label">CBGa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbga" name="component[cbga]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbga : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBGa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbg" className="form-label">CBG <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbg" name="component[cbg]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbg : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBG.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thcva" className="form-label">THCVa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thcva" name="component[thcva]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).thcva : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCVa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thcv" className="form-label">THCV <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thcv" name="component[thcv]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).thcv : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCV.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbdva" className="form-label">CBDVa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbdva" name="component[cbdva]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbdva : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDVa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbdv" className="form-label">CBDV <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbdv" name="component[cbdv]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbdv : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBDV.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbca" className="form-label">CBCa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbca" name="component[cbca]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbca : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBCa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="cbc" className="form-label">CBC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="cbc" name="component[cbc]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).cbc : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter CBC.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thca" className="form-label">THCa <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thca" name="component[thca]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).thca : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THCa.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thc" className="form-label">THC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thc" name="component[thc]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).thc : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter THC.</div>
                    </div>
                    <div className="col-3">
                        <label htmlFor="thc8" className="form-label">8THC <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="thc8" name="component[thc8]" min={0} step="any" className="form-control" defaultValue={edit_request ? JSON.parse(edit_request.component).thc8 : 0} placeholder="Dry Weight" max={100} required />
                        <div className="invalid-feedback">Please enter 8THC.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="attachment" className="form-label">Attachment</label>
                        <input type="file" id="attachment" name="attachment" className="form-control" placeholder="Attachment" accept='.jpeg,.pdf,.jpg,.png' />
                    </div></> }
                    <div className="col-6">
                        <label htmlFor="notes" className="form-label">Note <small>(optional)</small></label>
                        <textarea defaultValue={edit_request ? edit_request.notes : axData.notes} name="notes" className="form-control" rows="3" id="notes" placeholder="notes"></textarea>
                    </div>
                    <div className="mb-4 mb-sm-5">
                        <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'inventory_three')}>Submit</button>
                    </div>
                </form>
            }

            {/* Add Bulk Inventory | Inventory ID == 1 */}

            { props.inventory_id == 1 &&
                <form method="post" className="row g-3 g-sm-4" id="inventory_one">
                    <div className="col-6">
                        <label htmlFor="recorded_by" className="form-label">Recorded by <strong className="text-danger">*</strong></label>
                        <input type="text" id="recorded_by" name="recorded_by" defaultValue={edit_request ? edit_request.recorded_by : axData.recorded_by} className="form-control" placeholder="Recorded by" required/>
                        <div className="invalid-feedback">Please enter Recorded by.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="material_id" className="form-label">Select Material Type<strong className="text-danger">*</strong></label>
                        <select id="material_id" name="material_id" className="form-select form-select-lg" value={selectedMaterial ? selectedMaterial : edit_request && edit_request.material_id} onChange={(e) => setSelectedMaterial(e.target.value) } required>
                            <option value="">Choose Material Type</option>
                            { props.material_list ? props.material_list.map((materialType, num) =>
                                <option key={num} value={materialType.id} >({materialType.code}) {materialType.name}</option>
                            ) : '' }
                        </select>
                        <div className="invalid-feedback">Please choose Material type.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="batch_date" className="form-label">Batch Date <strong className="text-danger">*</strong></label>
                        <div className="date-wrapperbox" style={{ display: 'block' }}>
                            <DatePicker
                                placeholderText="dd-mm-yy"
                                onChange={changeDateFilter}
                                name="batch_date"
                                id="batch_date"
                                // value={edit_request ? edit_request.batch_date : axData.batch_date}
                                value={axData.new_date ? axData.new_date : edit_request.batch_date }
                                minDate={subDays(new Date(), 5)}
                                maxDate={new Date()}
                                className="form-control form-dateicon"
                                autoComplete="off"
                                popperModifiers={[
                                    {
                                      name: 'arrow',
                                      options: {
                                        padding: ({ popper, reference }) => ({
                                          right: Math.min(popper.width, reference.width) + 50,
                                        }),
                                      },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="batch_time" className="form-label">Time <strong className="text-danger">*</strong></label>
                        <input type="time" id="batch_time" name="batch_time" defaultValue={edit_request ? edit_request.batch_time : axData.batch_time} className="form-control" placeholder="dd-mm-yyyy" required />
                        <div className="invalid-feedback">Please enter Time.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="weight" className="form-label">Weight <small>(kg)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="weight" name="weight" min={0} step="any" className="form-control" defaultValue={edit_request && edit_request.weight} placeholder="Weight" required />
                        <div className="invalid-feedback">Please enter valid Weight.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="purity" className="form-label">Purity <small>(%)</small> <strong className="text-danger">*</strong></label>
                        <input type="number" id="purity" name="purity" min={0} step="any" className="form-control" defaultValue={edit_request ? edit_request.purity : axData.purity ? axData.purity : ''} placeholder="Purity" max={100} required />
                        <div className="invalid-feedback">Please enter Purity.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="notes" className="form-label">Note <small>(optional)</small></label>
                        <textarea defaultValue={edit_request ? edit_request.notes : ''} name="notes" className="form-control" rows="3" id="notes" placeholder="notes"></textarea>
                    </div>
                    <div className="mb-4 mb-sm-5">
                        <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'inventory_one')}>{ axData.editRequest ? 'Update' : 'Submit' }</button>
                    </div>   
                </form> 
            }
        </>
    )
}

export default AddStock;