import React, { useState, useEffect } from 'react';
import { generateBarcodeBatchId, momentDate, toastAlert } from '../../components/Helper';
import { useLocation } from 'react-router-dom';
import { getJsonData } from '../../fetch-data';
import { INVOICE } from '../../components/ApiUrl';

const ViewShipping = () => {

    window.document.title = 'View Invoice';
    const location = useLocation()
    const invoice_id = location.state
    const [invoice_data, setInvoiceData] = useState('')

    const listData = () => {
        getJsonData(INVOICE.GET_INVOICE + `/${invoice_id}`, (res) => {
            if(res.status){
                setInvoiceData(res.data);
            }
        }, true);
    }

    const printShipmentLabel = () => {
        if(invoice_data.shipment_label){
            var date = momentDate(invoice_data.created_at, false, false, 'YYYY-MM-DD', true)

            const pdfData = `data:application/pdf;base64,${invoice_data.shipment_label}`;
            const createDownloadLink = document.createElement("a");
            createDownloadLink.href = pdfData;
            createDownloadLink.download = 'Axtellab_Shipment_Label_'+date;
            createDownloadLink.click();
        }else{
            toastAlert('error', 'Shipment label is not available.');
        }
    }

    useEffect(() => {
        listData();

        // const script = document.createElement("script"); script.async = true;
        // script.src = `${APP_ENDPOINT}js/pdf.js`;
        // document.body.appendChild(script);
    }, [])

    return (

        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">View Invoice</h2>
                </div>

                <div className="col-sm-12 text-end">
                    <div className="dropdown">
                        <button className="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            Perform Action
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <button className="dropdown-item" data-invoice={invoice_data && invoice_data.id} id="print-invoice-view">Print</button>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={() => printShipmentLabel()}>Print Shipment Label</button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-12 col-lg-8 col-xl-7 py-4 px-4 container-print" id="invoice-view">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex mb-2">
                                <span className="me-2">Customer Name:</span>
                                <strong>{(invoice_data && JSON.parse(invoice_data.customer_info) && JSON.parse(invoice_data.customer_info).customerName) ? JSON.parse(invoice_data.customer_info).customerName  : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Price Per Kg:</span>
                                <strong>${(invoice_data) ? invoice_data.unit_price : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Enter Quantity:</span>
                                <strong>{(invoice_data) ? invoice_data.quantity : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Invoice Amount:</span>
                                <strong>${(invoice_data) ? invoice_data.invoice_amount : '-'}</strong>
                            </div>
                            <div className="d-flex mb-2">
                                <span className="me-2">Shipment Cost:</span>
                                <strong>${(invoice_data) ? invoice_data.shipment_cost : '-'}</strong>
                            </div>
                        </div>
                        <div className="col-auto text-end">
                            <h5>QB Invoice Id: <strong>{(invoice_data) ? invoice_data.qb_invoice_id : '-'}</strong></h5>
                            <h5>Invoice Date: <strong>{
                                (invoice_data) ? 
                                    (invoice_data.txn_date) ? 
                                        momentDate(invoice_data.txn_date, false, false, 'YYYY-MM-DD', true)
                                    :
                                    momentDate(invoice_data.created_at, false, false, 'YYYY-MM-DD', true)
                                : '-'
                                }</strong></h5>
                        </div>
                    </div>

                    <div className="col-12">
                        <label htmlFor="invoice-barcode" className="form-label">Released Buckets</label>
                        <table className="table table-sm" id="materialRecords">
                            <thead>
                                <tr>
                                    <th>Batch ID</th>
                                    <th>Production</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (invoice_data) ? invoice_data.barcode_invoice.map((barcode, i) =>
                                        (barcode.barcode)?
                                            <tr key={i}>
                                                <td>{(barcode.barcode) ? generateBarcodeBatchId(barcode.barcode):'-' }</td>
                                                <td>{(barcode.barcode) ? barcode.barcode.prduction_date:'-'}</td>
                                                <td>{(barcode.barcode) ? barcode.barcode.weight:'-'}</td>
                                            </tr>
                                        : <tr key={1}><td colSpan={4} className="text-center">No Buckets Found</td></tr>

                                    ) : <tr></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewShipping;