import React, { useState, useContext } from 'react';
import { momentDateDisplay, permission } from '../../components/Helper';
import NotAllowed from '../../components/NotAllowed';
import Context from '../../components/Context';
import { DataList } from '../../components/DataList';
import { USER } from '../../components/ApiUrl';

const ListUserActivity = () => {


    window.document.title = 'User Activity List'

    const [context] = useContext(Context)
    const [refresh] = useState()

    const dt =  {
        id: 'user-activity-list',
        fetchurl: USER.USER_ACTIVITY_LIST,
        columns: [
            { name: 'id', title: 'ID', width: 10 },
            { name: 'reference_id', title: 'Reference ID'},
            { name: 'action', title: 'Action Performed', width: 100},
            { name: 'log_date', title: 'Date'},
            { name: 'log_time', title: 'Time'},
            { name: 'user_id', title: 'User'},
        ]
    }

    return (
        (permission(context && context.auth.permission, 'add') && permission(context && context.auth.permission, 'update') && permission(context && context.auth.permission, 'view') && permission(context && context.auth.permission, 'delete')) ?
            <div className="container-fluid container-common">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h2 className="page-heading">User Activity</h2>
                    </div>
                    <div className="col-md-10 text-end">
                    </div>
                    <div className="col-md-12">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                </div>
                            }
                            edit_column={{
                            log_date: (record) => 
                                <span>
                                    {record && momentDateDisplay(record.log_date)}
                                </span>,
                            user_id: (record) => 
                                <span>
                                    {record && record.user.name}
                                </span>
                            }}
                        />
                    </div>
                </div>
            </div>
        : <NotAllowed />
    )
}
export default ListUserActivity;
