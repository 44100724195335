import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmationModal, permission, showloader } from '../../components/Helper';
import Context from "../../components/Context";
import { DataList } from "../../components/DataList";
import { AUTH, USER } from "../../components/ApiUrl";
import { getJsonData } from "../../fetch-data";
import { now } from "lodash";

const ListUser = () => {
    window.document.title = 'Users List'

    const [context, setContext] = useContext(Context)
    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setdeleteRecord] = useState('')

    const navigate = useNavigate()

    const [userDate] = useState({
        form_url_1: '/user/biomass-bagging',
        form_url_2: '/user/pre-extraction',
        form_url_3: '/user/post-extraction',
        form_url_4: '/user/cbda-crude_oil',
        form_url_5: '/user/cbda-crude-oil-spend',
        form_url_6: '/user/bulk-inventory',
        form_url_7: '/user/operations',
        form_url_8: '/user/solvent-spend',
        form_url_9: '/user/shipping',
        form_url_10: '/user/users-list',
    })

    const dt = {
        id: 'invoice-list',
        fetchurl: USER.USER_LIST,
        columns: [
            { name: 'id', title: 'ID' },
            { name: 'name', title: 'Name' },
            { name: 'email', title: 'Email', width: 200 },
            { name: 'role_id', title: 'User Type', visible: context && context.auth.role_id === 1 ? true : false },
            { name: 'action', title: 'Action' }
        ]
    }

    const deleteUserRecord = (id) => {
        if (id) {
            showloader(true)
            getJsonData(USER.USER_DELETE + '/' + id, (res) => {
                document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
                showloader()
                if (res.status) {
                    setRefresh(now)
                }
            }, true);
        }
    }

    const directLogin = (id) => {
        showloader(true)
        getJsonData(AUTH.DIRECT_LOGIN + `/${id}`, (res) =>{
            showloader()
            localStorage.removeItem('token')
            if(res.status){
                localStorage.setItem("token", res.data.access_token);
                setContext(prevState => ({
                    ...prevState,
                    auth: res.data
                }));
                navigate(userDate['form_url_' + res.data.assign_forms[0]])
            }
        });
    }

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Users List</h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(record) =>
                            <div className="ms-auto">
                                {context && context.auth.role_id === 1 &&
                                    <Link to="/admin/add-user" className="btn btn-sm btn-success">
                                        <svg className="icon me-2"><use xlinkHref="#icon_user" /></svg>
                                        Add User
                                    </Link>
                                }
                            </div>
                        }
                        edit_column={{
                            role_id: (record) =>
                                <span>
                                    {record.role_id === 1 ? 'Admin' : 'User'}
                                </span>,

                            action: (record) =>
                                <span>
                                    {context && context.auth.role_id === 1 &&
                                        <>
                                        <Link to='/admin/edit-user' state={record.id} className="btn btn-sm btn-outline-success m-1">
                                            <svg className="icon"><use xlinkHref="#icon_edit" /></svg>
                                        </Link>
                                        {permission(context && context.auth.permission, 'delete') &&
                                            <button className="btn btn-sm btn-outline-danger m-1" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={(e) => setdeleteRecord(record.id)}>
                                                <svg className="icon"><use xlinkHref="#icon_trash" /></svg>
                                            </button>
                                        }
                                        </>
                                        
                                    }
                                    {context && context.auth.role_id === 2 &&
                                        <Link to="#" className="btn btn-sm btn-primary m-1" onClick={() => directLogin(record.id)}>
                                            <svg className="icon"><use xlinkHref="#icon_postarrow" /></svg>
                                        </Link>
                                    }
                                </span>
                        }}
                    />
                    <ConfirmationModal msg="Are you sure want to delete?" method={deleteRecord ? () => deleteUserRecord(deleteRecord ? deleteRecord : '') : ''} />
                </div>
            </div>
        </div>
    )
}

export default ListUser;