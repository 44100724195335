import React, { useState, useEffect, useContext } from 'react';
import './partials/operation.css'
import { initialFormState, momentDate, showloader, toastAlert, validateForm } from '../../components/Helper';
import { postData, postFormData } from '../../fetch-data';
import { OPERATIONS } from '../../components/ApiUrl';
import Context from '../../components/Context';

const Operations = () => {

    window.document.title = 'Operations'

    const [context] = useContext(Context)
    const [jumbo_end_time, setJumboEndTime] = useState('')
    const [jumbo_start_time, setJumbostartTime] = useState('')
    const [cup30_start_time, setCup30StartTime] = useState('')
    const [av30_start_time, setAv30StartTime] = useState('')
    const [cup30_end_time, setCup30EndTime] = useState('')
    const [av30_end_time, setAv30EndTime] = useState('')
    const [operationdata, setOperationData] = useState('')
    const [log_date] = useState(momentDate('', false, false, 'YYYY-MM-DD', true))
    const [updateOperationForm, setUpdateOperationForm] = useState(false)

    const handleSubmit = (e, form_id) => {
        e.preventDefault();

        const formData = new FormData(document.getElementById(form_id))

        if(validateForm(e, form_id)){

            formData.append('user_id', context && context.auth.id)

            if (updateOperationForm){
                formData.append('id', operationdata.id);
                if(formData.get('initital_proof_ethanol')){
                    if(formData.get('julabo_start') || formData.get('cup_30_start') || formData.get('av30_start')){
                        showloader(true)
                        postFormData(OPERATIONS.UPDATE, formData, (res) => {
                            showloader()
                            if(res.status){
                                initialFormState(form_id)
                                getOperationForm();
                            }
                        }, true);
                    }else{
                        toastAlert('error', "Please on at least 1 machine.");
                    }
                }else{
                    formData.append('initital_proof_ethanol', operationdata.initital_proof_ethanol)
                    formData.append('julabo_temp', operationdata.julabo_temp)
                    if(formData.get('final_proof_ethanol') || formData.get('temp_run')){
                        if(formData.get('julabo_end') || formData.get('cup_30_end') || formData.get('av30_end')){
                            showloader(true)
                            postFormData(OPERATIONS.UPDATE, formData, (res) => {
                                showloader()
                                if(res.status){
                                    initialFormState(form_id)
                                    getOperationForm();
                                }
                            }, true);
                        }else{
                            toastAlert('error', "Please off at least 1 machine.");
                        }
                    }
                } 
            }else{
                if(formData.get('initital_proof_ethanol')){
                    if(formData.get('julabo_start') || formData.get('cup_30_start') || formData.get('av30_start')){
                        showloader(true)
                        postFormData(OPERATIONS.STORE, formData, (res) => {
                            showloader()
                            if(res.status){
                                initialFormState(form_id)
                                getOperationForm();
                            }
                        }, true);
                    }else{
                        toastAlert('error', "Please start at least 1 machine.");
                    }
                }else{
                    showloader(true)
                    postFormData(OPERATIONS.STORE, formData, (res) => {
                        showloader()
                        if(res.status){
                            initialFormState(form_id)
                            getOperationForm();
                        }
                    }, true);
                } 
            }
        }        
    }

    const timeNow = (e, inputid) => {
        var time = momentDate('', false, false, 'HH:mm', true);
        e.target.classList.add('active');
        document.getElementById(inputid).value = time
    }
    
    /* get operation form data for today */

    const getOperationForm = () => {
        showloader(true)
        postData(OPERATIONS.DETAIL, {operation_date: log_date}, (res) => {
            showloader()
            if(Object.keys(res.data).length > 0 && res.status){
                setUpdateOperationForm(true)
                setOperationData(res.data)
            }
        }, true);
    }

    useEffect(() => {
        getOperationForm();
    },[])

    return (
            <div className="container-fluid container-common">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h2 className="page-heading">Operations</h2>
                    </div>
                    <div className="col-md-12 col-lg-8 col-xl-7 py-4">                            
                        <form method="post" className="row g-3 g-sm-4" id="start_form">
                            <div className="col-6">
                                <label htmlFor="jumbo_start_time" className="form-label">Julabo Start Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="julabo_start" name="julabo_start" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.julabo_start} onInput={(e) => setJumbostartTime(e.target.value)}  />
                                    <span className={`input-group-text input-group-onoff ${jumbo_start_time || operationdata.julabo_start ? 'active' : ''}`} onClick={(e) => timeNow(e, "julabo_start")}>On</span>
                                </div>
                            </div>
                            {/*  */}
                            <div className="col-6">
                                <label htmlFor="julabo_temp" className="form-label">Julabo Temp <strong className="text-danger">*</strong></label>
                                <input type="number" id="julabo_temp" name="julabo_temp" step="any" defaultValue={operationdata && operationdata.julabo_temp} className="form-control" placeholder="Julabo Temp" required />
                                <div className="invalid-feedback">Please enter Julabo Temp.</div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="cup30_start_time" className="form-label">CUP30 Start Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="cup_30_start" name="cup_30_start" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.cup_30_start} onInput={(e) => setCup30StartTime(e.target.value)} />
                                    <span className={`input-group-text input-group-onoff ${cup30_start_time || operationdata.cup_30_start ? 'active' : ''}`} onClick={(e) => timeNow(e, "cup_30_start")}>On</span>
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <label htmlFor="initital_proof_ethanol" className="form-label">Initial Proof of Ethanol <strong className="text-danger">*</strong></label>
                                <input type="number" id="initital_proof_ethanol" step="any" name="initital_proof_ethanol" defaultValue={operationdata && operationdata.initital_proof_ethanol} className="form-control" placeholder="Initial Proof of Ethanol" required />
                                <div className="invalid-feedback">Please enter Initial Proof of Ethanol.</div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="av30_start_time" className="form-label">AV30 Start Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="av30_start" name="av30_start" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.av30_start} onInput={(e) => setAv30StartTime(e.target.value)} />
                                    <span className={`input-group-text input-group-onoff ${av30_start_time || operationdata.av30_start ? 'active' : ''}`} onClick={(e) => timeNow(e, "av30_start")}>On</span>
                                </div>
                            </div>
                            
                            <div className="col-6">
                                <label htmlFor="log_date" className="form-label">Date <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="date" id="log_date" name="log_date" className="form-control" placeholder="hh:mm" defaultValue={operationdata ? operationdata.log_date : log_date} readOnly required />
                                    <div className="invalid-feedback">Please enter Date.</div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mt-4">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'start_form')}>Submit</button>
                                </div>
                            </div>
                        </form>
                            <hr></hr>
                        <form method="post" className="row g-3 g-sm-4" id="start_end">
                            <div className="col-6">
                                <label htmlFor="julabo_end" className="form-label">Julabo End Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="julabo_end" name="julabo_end" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.julabo_end} onInput={(e) => setJumboEndTime(e.target.value)} />
                                    <span className={`input-group-text input-group-onoff ${jumbo_end_time || operationdata.julabo_end ? 'active' : ''}`} onClick={(e) => timeNow(e, "julabo_end")}>off</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="cup_30_end" className="form-label">CUP30 End Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="cup_30_end" name="cup_30_end" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.cup_30_end} onInput={(e) => setCup30EndTime(e.target.value)} />
                                    <span className={`input-group-text input-group-onoff ${cup30_end_time || operationdata.cup_30_end ? 'active' : ''}`} onClick={(e) => timeNow(e, "cup_30_end")}>Off</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="av30_end" className="form-label">AV30 End Time <strong className="text-danger">*</strong></label>
                                <div className="input-group">
                                    <input type="time" id="av30_end" name="av30_end" className="form-control" placeholder="hh:mm" defaultValue={operationdata && operationdata.av30_end} onInput={(e) => setAv30EndTime(e.target.value)} />
                                    <span className={`input-group-text input-group-onoff ${av30_end_time || operationdata.av30_end ? 'active' : ''}`} onClick={(e) => timeNow(e, "av30_end")}>Off</span>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="final_proof_ethanol" className="form-label">Final Proof of Ethanol <strong className="text-danger">*</strong></label>
                                <input type="number" id="final_proof_ethanol" step="any" name="final_proof_ethanol" defaultValue={operationdata && operationdata.final_proof_ethanol} className="form-control" placeholder="Final Proof of Ethanol" required />
                                <div className="invalid-feedback">Please enter Final Proof of Ethanol.</div>
                            </div>

                            <div className="col-6">
                                <label htmlFor="temp_run" className="form-label">Temp Run <strong className="text-danger">*</strong></label>
                                <input type="number" id="temp_run" name="temp_run" step="any" defaultValue={operationdata && operationdata.temp_run} className="form-control" placeholder="Temp Run" required />
                                <div className="invalid-feedback">Please enter Temp Run.</div>
                            </div>

                            <div className="mb-4 mb-sm-5">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e, 'start_end')}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>  
            </div>  
    )
}

export default Operations;