import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { initialFormState, momentDate, momentDateDisplay, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { BIOMASS_BAGGING_SERVICE } from '../../components/ApiUrl';

const BiomassBagging = () => {

    window.document.title = 'Biomass Bagging'

    const location = useLocation()
    const navigate = useNavigate()

    const edit_id = location.state

    const [context] = useContext(Context)
    const [recorder_by] = useState(context && context.auth.name)
    const [date, setDate] = useState(momentDate('', false, false, 'YYYY-MM-DD', true))
    const [default_time, setTime] = useState(momentDate('', false, false, 'HH:mm', true))
    const [editRequest, setEditRequest] = useState(false)
    const [added_value, setAddedValue] = useState(false)

    const[boimassData, setBoimassData] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();
       
        const formData = new FormData(document.getElementById('biomass_bagging'));

        if(validateForm(e, 'biomass_bagging')){
            formData.append('user_id', context.auth.id)
            formData.append('date_time', date+' '+formData.get('time')) 

            if(editRequest){
                formData.append('id', edit_id);
                postFormData(BIOMASS_BAGGING_SERVICE.UPDATE, formData, (res) =>{
                    if(res.status){
                        navigate('/user/biomass-bagging-list')
                    }
                }, true);
            }else{
                postFormData(BIOMASS_BAGGING_SERVICE.STORE, formData, (res) =>{
                    if(res.status){
                        initialFormState('biomass_bagging')
                        setAddedValue(res.data)
                        document.getElementById('genrated_bag_id').classList.add('active');
                    }
                }, true);
            }

        }       
    }
    
    const getYear = () => {
        return new Date().getFullYear();
    }
    
    const close = () => {
        document.getElementById('genrated_bag_id').addEventListener('click', function () {
            this.classList.remove('active');
        });
    }

    useEffect(() => {
        if(edit_id){
            showloader(true)
            getJsonData(BIOMASS_BAGGING_SERVICE.DETAILS + `/${edit_id}`, (res) =>{
                showloader()
                if(res.status){
                    setBoimassData(res.data)
                }
            }, true);
        }
    }, [])

    return (
        <>
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Biomass Bagging</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <div className="col-sm-12 text-end">
                            <Link to="/user/biomass-bagging-list"  className="btn btn-sm btn-success">
                                <svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>
                                List
                            </Link>
                        </div>
                    <form method="post" className="row g-3 g-sm-4" id="biomass_bagging">
                        <div className="col-6">
                            <label htmlFor="recorder_by" className="form-label">Recorded by <strong className="text-danger">*</strong></label>
                            <input type="text" id="recorder_by" name="recorder_by" defaultValue={boimassData ? boimassData.recorder_by : recorder_by} readOnly className="form-control" placeholder="Recorded by" required/>
                            <div className="invalid-feedback">Please enter Recorded By.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="weight" className="form-label">Bag Weight <small>(lbs)</small> <strong className="text-danger">*</strong></label>
                            <input type="number" id="weight" name="weight" min={0} step="any" className="form-control" placeholder="Bag Weight" defaultValue={boimassData ? boimassData.weight : ''} required />
                            <div className="invalid-feedback">Please enter valid Bag Weight.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="date" className="form-label">Date <strong className="text-danger">*</strong></label>
                            <input type="date" id="date" name="date" value={boimassData && boimassData.date_time ? momentDateDisplay(boimassData.date_time, false, false, 'YYYY-MM-DD') : date} readOnly className="form-control" placeholder="dd-mm-yyyy" required />
                            <div className="invalid-feedback">Please enter Date.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="time" className="form-label">Time <strong className="text-danger">*</strong></label>
                            <input type="time" id="time" name="time" defaultValue={boimassData && boimassData.date_time ? momentDateDisplay(boimassData.date_time, false, false, 'HH:mm') : default_time} className="form-control" placeholder="dd-mm-yyyy" required />
                            <div className="invalid-feedback">Please enter Time.</div>
                        </div>
                        <div className="mb-4 mb-sm-5">
                            <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div className="added-bagbox" id="genrated_bag_id">
            <div className="added-inbag" data-closelabel="Close" onClick={() => close()}>
                <svg className="icon"><use href="#icon_bag"></use></svg>
                <h3>Added Bag Number <span id="added_bag_no">{added_value && added_value.bag_num}</span></h3>
                <div className="added-ywbn">
                    <strong>{getYear}</strong>
                    <strong>Week: <span id="week_no"></span>{added_value && added_value.bag_week}</strong>
                    <strong>Bag Number: <span id="bag_no">{added_value && added_value.num}</span></strong>
                </div>
            </div>
        </div>  
        </>
    )
}

export default BiomassBagging;