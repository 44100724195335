import React, { useEffect, useState } from 'react';
import StockList from '../Inventory/StockList';
import { showloader } from '../../components/Helper';
import { getJsonData } from '../../fetch-data';
import { GET_FORM_DATA } from '../../components/ApiUrl';

const SolventStock = () => {

    window.document.title = 'Solvent Inventory Stock Log'

    const [axData, setAxData] = useState('');

    const getSolvent = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=solvent', (res) => {
            showloader()
            if(res.status){
                setAxData(res.data.data)
            }
        }, true);
    }

    useEffect(() => {
        getSolvent()
    }, [])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Solvent Inventory Stock Log</h2>
                </div>
                { axData && axData.length > 0 && <StockList inventory_id="2" material_list={axData}/> }
            </div>
        </div>
    );
}

export default SolventStock;
