import { Toast } from "bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
import $ from 'jquery'
import Context from './Context';
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
// toast.configure();
import html2pdf from 'html2pdf.js';

const helpers = {

}

export default helpers;

export const UserContext = Context;

export const showAlertMsg = (data, form_id = false) => {
    let status = data.status ? true : false;
    if (data.message) {
        const idGenerate = Math.floor((Math.random() * 1000000) + 1);
        var seticon = status ? 'square_check' : !status ? 'circle_cancle' : 'bellfill';

        const htmlToast = `<div id="${idGenerate}" class="toast fade toast-${status ? 'success' : 'danger'}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000">
            <div class="toast-body first-text-uppercase">
                <svg class="icon me-3"><use href="#icon_${seticon}"></use></svg>
                <span class="text-white">${data.message}</span>
            </div>
            <button type="button" class="btn-close ms-auto me-3 float-end" data-bs-dismiss="toast" aria-label="Close" title="Close"></button>
        </div>`;

        document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
        var getIdToast = document.getElementById(idGenerate);

        var toast = new Toast(getIdToast);
        toast.show();
        getIdToast.addEventListener('hidden.bs.toast', function () {
            setTimeout(() => {
                this.remove();
            }, 500);
        });

    } else if (data.errors) {
        let i = 1;
        for (let key in data.errors) {
            if (i === 1) {
                $(`input[name="${key}"]`).closest('form').removeClass('was-validated');
                $(`input[name="${key}"]`).closest('form').find('.form-control').removeClass('is-invalid');
                $(`input[name="${key}"]`).closest('form').find('.invalid-feedback').remove();
            }

            $(`input[name="${key}"]`).addClass('is-invalid').after(`<div class="invalid-feedback">${data.errors[key][0]}</div>`)
            i++;
        }
    }
}

export const loadingData = (active) => {
    if (active) {
        document.querySelector('body').classList.add('loading-data');
    } else {
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const validateForm = (e, form_id = false) => {
    let error = 0;
    if (form_id) {
        let form = document.getElementById(form_id)
        if (!form.checkValidity()) {
            error++;
            e.preventDefault();
            e.stopPropagation();
        }
        form.classList.add('was-validated')
    } else {
        let forms = document.querySelectorAll('.needs-validation')
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    error++;
                    e.preventDefault();
                    e.stopPropagation();
                }
                form.classList.add('was-validated')
            })
    }

    if (error) {
        return false
    } else {
        return true
    }
}

export const momentDate = (date, isDate = false, isTime = false, useAsParam = null, offset = false) => {

    var timeOffset = -7 + 1; // 1 is dst hr
    var format = '';

    if (date) {
        var moment = Moment(date);
    } else {
        moment = Moment();
    }
    if (isDate && isTime) {
        format = 'MMM DD, Y hh:mm a';
    } else if (useAsParam) {
        format = useAsParam;
    } else if (isDate) {
        format = 'MMM DD, Y';
    } else if (isTime) {
        format = 'hh:mm a';
    } else {
        format = 'MMM DD, Y';
    }

    if (offset) {
        return moment.utcOffset(timeOffset).format(format);
    } else {
        return moment.format(format);
    }

}

export const toastAlert = (alertType, alertMessage) => {
    const idGenerate = Math.floor((Math.random() * 1000000) + 1);
    var seticon = alertType === 'success' ? 'squarecheck' : alertType === 'error' ? 'cancelcircle' : 'bellfill';

    const htmlToast = `<div id="${idGenerate}" class="toast fade toast-${alertType === 'success' ? 'success' : 'danger'}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000">
        <div class="toast-body first-text-uppercase">
            <svg class="icon me-3"><use href="#icon_${seticon}"></use></svg>
            <span>${alertMessage}</span>
        </div>
        <button type="button" class="btn-close ms-auto me-3 float-end" data-bs-dismiss="toast" aria-label="Close" title="Close"></button>
        
    </div>`;

    document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
    var getIdToast = document.getElementById(idGenerate);

    var toast = new Toast(getIdToast);
    toast.show();
    getIdToast.addEventListener('hidden.bs.toast', function () {
        setTimeout(() => {
            this.remove();
        }, 500);
    });
}

export const momentDateDisplay = (date, isDate = false, isTime = false, useAsParam = null) => {
    var format = '';
    if (date) {
        var moment = Moment(date);
    } else {
        moment = Moment();
    }

    if (isDate && isTime) {
        format = 'MMM DD, Y hh:mm a';
    } else if (useAsParam) {
        format = useAsParam;
    } else if (isTime) {
        format = 'hh:mm a';
    } else {
        format = 'MMM DD, Y';
    }

    return moment.format(format);
}

export const floatNumber = (num) => {
    return parseFloat(num).toFixed(3).slice(0, -1);
}

export const showloader = (view) => {
    if (view) {
        document.querySelector('body').classList.add('loading-data');
    } else {
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const permission = (permission, slug) => {

    if (permission && permission.indexOf(slug) > -1) {
        return true
    } else {
        return false
    }

}

export const ButtonLoading = props => {
    return (
        <>
            {
                props.load ?
                    <div className="d-flex justify-content-center py-1">
                        <span className="spinner-grow spinner-grow-sm d-block" role="status" aria-hidden="true"></span>
                    </div>
                    : props.btnName
            }
        </>
    )
}

export const toastNotify = () => {
    return "Hello";
}

export const __echoText = (data) => {
    return (
        data ? data : '-'
    )
}

export const noImageHandle = (e) => {
    e.target.src = "/images/no-image.png";
}



export const ConfirmationModal = (props) => {
    return (
        <div className="modal fade" id="deletesection_modal" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content overflow-hidden border border-2 border-dark rounded">
                    <div className="modal-body p-3">
                        <h5 className="text-dark">{props.msg}</h5>
                        <p className="m-0 pt-2">
                            This is a destructive action and cannot be reversed.
                        </p>
                    </div>
                    <div className="modal-footer justify-content-between px-3 pt-0 pb-2 border-0">
                        <button type="button" className="btn btn-danger p-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-outline-dark p-2" onClick={props.method}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const initialFormState = (formId, setData = false) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select`)].forEach((ele) => {
        ele.classList.remove('is-invalid');
    });
    document.getElementById(formId).classList.remove('was-validated')
    document.getElementById(formId).reset()
    if (setData) {
        setData(prevState => ({ ...prevState = '' }))
    }
    return setData;
}

export const generateBarcodeBatchId = (barcodeData) => {
    var str_type_id = '01';

    if (barcodeData.storage_type !== 5) {
        str_type_id = barcodeData.storage_info ? barcodeData.storage_info.code : '01';
    }

    var barcodeBatchId = "B" + barcodeData.batch_id + "S" + barcodeData.storage + " -" + barcodeData.material_type.code + " " + str_type_id + "-" + barcodeData.weight;

    return barcodeBatchId;
}

export const dateFormat = (date) => {
    return new Date(date).toLocaleDateString('en-US', {timeZone: 'UTC'});
}

export const downloadPdf = (id, name='', format='A4', orientation='portrait') => {

    const printDiv= document.getElementById(id);
    var opt = {
        margin: [0,0],
        filename: name+'.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        pagebreak: { mode: '', before: '.break-before', after: '.break-after', avoid: '.break-avoid' },
        jsPDF: { unit: 'pt', format: format, orientation: orientation },
    };
    //To Direct Download
    html2pdf().set(opt).from(printDiv).save();

    //To Prevent Auto Download
    // html2pdf().set(opt).from(printDiv).toPdf().get('pdf').then(function (pdfObj) {
    //     pdfObj.autoPrint();
    //     window.open(pdfObj.output('bloburl'), '_blank');
    // });
}

export const downloadBlob = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Axtellabs.xlsx`);
    document.body.appendChild(link);
    link.click();
}