import React, { useContext, useEffect, useState } from 'react';
import ExportData from '../../components/ExportData';
import { DataList } from '../../components/DataList';
import { OPERATIONS } from '../../components/ApiUrl';
import { getJsonData } from '../../fetch-data';
import { ConfirmationModal, floatNumber, momentDateDisplay, permission, showloader } from '../../components/Helper';
import { now } from 'lodash'
import Context from '../../components/Context';

const OperationsList = () => {
    window.document.title = 'Operations List'

    const [refresh, setRefresh] = useState('')
    const [context] = useContext(Context)
    const [deleteRecord, setDeleteRecord] = useState(false)
    const [export_data] = useState({data: 'Operations',type: 'xls'})
    
    const dt = {
        id: 'operations-list',
        fetchurl: OPERATIONS.LIST,
        columns: [
            { name: 'id', title: 'Id' },
            { name: 'log_date', title: 'Date', width: 200, },
            { name: 'julabo_start', title: 'Julabo Start Time' },
            { name: 'julabo_end', title: 'Julabo End Time'},
            { name: 'cup_30_start', title: 'CUP30 Start Time' },
            { name: 'cup_30_end', title: 'CUP30 End Time'},
            { name: 'av30_start', title: 'AV30 Start Time' },
            { name: 'av30_end', title: 'AV30 End Time' },
            { name: 'julabo_temp', title: 'Julabo Temp' },
            { name: 'initital_proof_ethanol', title: 'Initial Proof of Ethanol' },
            { name: 'final_proof_ethanol', title: 'Final Proof of Ethanol' },
            { name: 'extraction', title: 'EE %', sorting: false },
            { name: 'decard_retension', title: 'DWR %', sorting: false },
            { name: 'last_update_user', title: 'Last Updated User', sorting: false },
            { name: 'action', title: 'Action' },
        ]
    }

    const deleteOperation = (id) => {
        if(id){
            showloader(true)
            getJsonData(OPERATIONS.DELETE + '/' + id, (res) => {
                showloader()
                document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
                if(res.status){
                    setRefresh(now)
                }
            }, true);
        }
    }

    useEffect(() => {
        
    }, [refresh])
    
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Operations List</h2>
                </div>
                <div className="col-md-12 my-2">
                <DataList
                    dt={dt}
                    refresh={refresh}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                            <ExportData export_data={export_data}/>
                        </div>
                    }
                    edit_column={{
                        log_date: (record) =>
                            <span>
                                { record && momentDateDisplay(record.log_date)}
                            </span>,
                        julabo_start: (record) => 
                            <span>
                                {record && record.julabo_start? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.julabo_start, false, true):'--'}
                            </span>,
                        julabo_end: (record) =>
                            <span>
                                {record && record.julabo_end? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.julabo_end, false, true):'--'}
                            </span>,
                        cup_30_start: (record) =>
                            <span>
                                {record && record.cup_30_start? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.cup_30_start, false, true):'--'}
                            </span>,
                        cup_30_end: (record) =>
                            <span>
                                {record && record.cup_30_end? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.cup_30_end, false, true):'--'}
                            </span>,
                        av30_start: (record) =>
                            <span>
                                {record && record.av30_start? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.av30_start, false, true):'--'}
                            </span>,
                        av30_end: (record) =>
                            <span>
                                {record && record.av30_end? momentDateDisplay( momentDateDisplay(record.log_date)+' '+record.av30_end, false, true):'--'}
                            </span>,
                        extraction: (record) =>
                            <span>
                                {record && floatNumber(record.extraction)}%
                            </span>,
                        decard_retension: (record) =>
                            <span>
                                {record && floatNumber(record.decard_retension)}%
                            </span>,
                        last_update_user: (record) => 
                            <span>
                                {record && record.last_update_user ? record.last_update_user.name : ''}
                            </span>,
                        action: (records) =>
                            <div className="d-flex">
                                {permission(context  && context.auth.permission, 'delete') &&
                                    <button className="btn btn-sm btn-outline-danger" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={(e) => setDeleteRecord(records.id)}  >
                                        <svg className="icon" role="img"><use href="#icon_trash" /></svg>
                                    </button>
                                }
                            </div>
                    }}
                />
                <ConfirmationModal msg="Are you sure want to delete?" method={deleteRecord ? (e) => deleteOperation(deleteRecord) : ''} />
                </div>
            </div>
        </div>
    )
}

export default OperationsList;