import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { floatNumber, momentDateDisplay, showloader, toastAlert } from '../../components/Helper';
import ExportData from '../../components/ExportData';
import { Link, useLocation } from 'react-router-dom';
import { INVENTORY, SETTING } from '../../components/ApiUrl'
import { getJsonData } from '../../fetch-data';

const StockList = (props) => {
    
    const [axData, setAxData] = useState({
        stock_data: '',
        startDate: '',
        endDate:'',
        stock_alert:'',
        current_stock: '',
        pageCount: 1,
        currentPage: 1,
        export:{
            data: props.inventory_id == 2 ? 'Solvent_Inventory_Stock' : (props.inventory_id == 3 ? 'CBDa_Crude_Oil_Inventory_Stock' : 'Hemp_Inventory_Stock'),
            type: 'xls'
        },
        inventory: props.inventory_id == 2 ? props.material_list[0].id : props.inventory_id,
        unit: props.inventory_id == 3 ? "kg" : (props.inventory_id == 2 ? props.material_list[0].units : "lbs")
    })
    
    const listData = (start_date = '', end_date = '') => { 
        showloader(true)
        let request = `start_date=${start_date}&end_date=${end_date}&inventory=${axData.inventory}&main_inventory_id=${props.inventory_id}`
        getJsonData(INVENTORY.STOCK_LIST +'?'+ request , (res) => {
            showloader()
            if(res.data){
                setAxData(prevState => ({
                    ...prevState,
                    stock_data: res.data,
                    current_stock: res.data[0].left 
                }));
            }
        }, true);
    }
    
    const getSettings = () => {
        showloader(true)
        getJsonData(SETTING.THRESHHOLDS, (res) => {
            if(res.status){
                res.data && res.data.map((item, i) => {
                    if(item.inventory_id > 0 && item.inventory_id === axData.inventory) {
                        setAxData(prevState => ({
                            ...prevState,
                            stock_alert: item.key_value,
                        }))
                    }
                });
            }
        }, true);
    }

    const setStartDate = (start_date) => {
        setAxData(prevState => ({
            ...prevState,
            startDate: start_date
        }));
    }

    const setEndDate = (end_date) => {
        setAxData(prevState => ({
            ...prevState,
            endDate: end_date
        }));
    }

    const resetFlter = (e) => {
        if(axData.date != ''){
            listData();
            setAxData(prevState => ({
            ...prevState,
                startDate:'',
                endDate:''
            }));
        }
    }

    const applyDate = (e) => {
        if (momentDateDisplay(axData.startDate, true, false) && momentDateDisplay(axData.endDate, true, false)){
            var start_date = momentDateDisplay(axData.startDate, false, false, 'Y-MM-DD')
            var end_date = momentDateDisplay(axData.endDate, false, false, 'Y-MM-DD')
            listData(start_date, end_date);
        }else{
            toastAlert('error', "Invalid Dates");
        }
    }
    
    const inStock = () => {
        var stockStatus = {}
        if (parseInt(axData.current_stock) <= 0){
            stockStatus = {
                stock: axData.current_stock,
                class: "text-danger h5",
                text: "( Out of Stock )"
            }
        } else if (parseInt(axData.current_stock) <= parseInt(axData.stock_alert)){
            stockStatus = {
                stock: axData.current_stock,
                class: "text-danger h5",
                text: "( Low Stock )"
            }
        }else{
            stockStatus = {
                stock: axData.current_stock,
                class: "text-dark h5",
                text: ""
            }
        }
        return stockStatus
    }

    const changeMaterial = (e) => {
        let index = e.target.selectedIndex
        let ele = e.target.childNodes[index]
        setAxData(prevState => ({
            ...prevState,
            inventory: e.target.value,
            unit : ele.getAttribute('data-unit')
        }));
    }

    useEffect(() => {
        listData()
        getSettings();
    }, [axData.inventory])

    return (
        <div className="col-md-12 my-2">
            <div className="row align-items-center">
                <div className="col-md-12 col-xl-5">
                    <strong className="h5 me-2">Inventory Stock:</strong>
                    <span className={inStock().class}>
                        {floatNumber(inStock().stock) + ' ' + inStock().text}
                    </span>
                </div>
                
                <div className="col-md-12 col-xl-7 text-end">
                { props.inventory_id == 2 && 
                <select className="btn btn-sm btn-fefault me-2 border mb-1" name="material_filter" id="material_filter" value={axData.inventory} onChange={changeMaterial}>
                    { props.material_list ? props.material_list.map((materialType) =>
                        <option key={materialType.id} value={materialType.id} data-unit={materialType.unit}>{materialType.name}</option>
                    ) : '' }
                </select> }
                    <ExportData export_data={axData.export} inventory_id={axData.inventory} unit_type={axData.unit_type}/>
                    <div className="month-wrapperbox mb-2">
                        <DatePicker
                            placeholderText="Select Start Date"
                            onChange={startDate => setStartDate(startDate)}
                            startDate={axData.startDate}
                            endDate={axData.endDate}
                            maxDate={new Date()}
                            value={axData.startDate ? momentDateDisplay(axData.startDate) : ''}
                            popperModifiers={[
                                {
                                  name: 'arrow',
                                  options: {
                                    padding: ({ popper, reference }) => ({
                                      right: Math.min(popper.width, reference.width) + 50,
                                    }),
                                  },
                                },
                            ]}
                        />
                    </div>
                    <div className="month-wrapperbox ms-2">
                        <DatePicker
                            placeholderText="Select End Date"
                            onChange={endDate => setEndDate(endDate)}
                            startDate={axData.startDate}
                            endDate={axData.endDate}
                            minDate={axData.startDate}
                            maxDate={new Date()}
                            value={axData.endDate ? momentDateDisplay(axData.endDate) : ''}
                            popperModifiers={[
                                {
                                  name: 'arrow',
                                  options: {
                                    padding: ({ popper, reference }) => ({
                                      right: Math.min(popper.width, reference.width) + 50,
                                    }),
                                  },
                                },
                            ]}
                        />
                    </div>
                    <button className="ms-2 mb-2 btn btn-dark btn-sm" onClick={(e) => applyDate()}>Apply</button>
                    <button className="ms-2 mb-2 btn btn-primary btn-sm" onClick={(e) => resetFlter()}>Reset</button>
                </div>
            </div>
            <table className="table table-bordered table-hover align-middle mb-2">
                <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                </colgroup>
                <thead>
                    <tr className="table-dark">
                        <th> Date </th>
                        <th> Inventory <small>({ axData.unit })</small> </th>
                        <th> Spent From Inventory<small>({ axData.unit })</small> </th>
                        { props.inventory_id != 3 && <th> Wastage<small>({ axData.unit })</small> </th> }
                        <th> In stock <small>({ axData.unit })</small> </th>
                    </tr>
                </thead>
                <tbody>
                    { axData.stock_data && axData.stock_data.length > 0 ? axData.stock_data.map(item => 
                        <tr key={item.log_date}>
                            <td>{momentDateDisplay(item.log_date)}</td>
                            <td>{floatNumber(item.stock)}</td>
                            <td>{floatNumber(item.spent)}
                            { props.inventory_id == 4 && parseInt(item.spent) > 0 &&  <Link title="View Bag" className="ms-2 text-primary text-decoration-none" to="/admin/biomass-bagging-list" state={item.log_date} style={{ cursor: "pointer" }}>View Bag</Link> }
                            </td>
                            { props.inventory_id != 3 && <td>{floatNumber(item.waste)}</td> }
                            <td>{floatNumber(item.left)}</td>
                        </tr> )
                    : <tr><td colSpan={5} className="text-center">No Record Found</td></tr>}
                </tbody>
            </table>
        </div>
    );
}
export default StockList;
