import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { initialFormState, momentDateDisplay, permission, showAlertMsg, showloader, validateForm } from "../../components/Helper";
import DatePicker from "react-datepicker";
import Context from '../../components/Context';
import { BARCODE, GET_FORM_DATA } from '../../components/ApiUrl';
import { getJsonData, postFormData } from '../../fetch-data';

const AddBarcode = () => {

    window.document.title = 'Add Barcode'

    const [batchList, setBatchList] = useState('')
    const [prduction_date, setPrductionDate] = useState('')
    const [materialList, setMaterialList] = useState('')
    const [storageTypeList, setStorageTypeList] = useState('')
    const [storage, setStorage] = useState('')
    const [context] = useContext(Context)

    const handleInputChanged = (e) => {

        if(e.target.name === 'batch_id'){
            console.log(e.target.value);
            showloader(true)
            getJsonData(GET_FORM_DATA.GET_STORAGE_TO_BARCODE + '/' +e.target.value, (res) => {
                showloader()
                if(res.status){
                    setStorage(parseInt(res.data.storage)+1)
                }
            }, true);
        }
    }

    const handleSubmit = (e) => {
        let formData = new FormData(document.getElementById('addBarcode'))
        if(validateForm(e, 'addBarcode')){
            showloader(true)
            postFormData(BARCODE.ADD_BARCODE, formData, (res) => {
                showloader()
                if(res.status){
                    initialFormState('addBarcode')
                }
            }, true);
        }
    }

    const getData = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.GET_BATCH_LIST, (res) => {
            showloader()
            if (res.status) {
                setBatchList(res.data)
            }
        }, true)

        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=material', (res) => {
            showloader()
            if(res.status){
                setMaterialList(res.data.data)
            }
        }, true);

        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=storage', (res) => {
            showloader()
            if(res.status){
                setStorageTypeList(res.data.data)
            }
        }, true);
    }

    const changeDateFilter = (date) => {
        setPrductionDate(momentDateDisplay(date, false, false, 'DD-MM-YYYY'));
    }
    
    useEffect(() => {
        getData()
    },[])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Add Barcode</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <div className="col-sm-12 text-end">
                        <Link to="/admin/barcode" className="btn btn-sm btn-success">
                            <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                            List
                        </Link>
                    </div>
                    <form method="post" className="row g-3 g-sm-4" id="addBarcode">
                        <div className="col-6">
                            <label htmlFor="date" className="form-label"> Production Date <strong className="text-danger">*</strong></label>
                            <div className="date-wrapperbox" style={{display:'block'}}>
                            <DatePicker                                    
                                placeholderText="dd-mm-yy"
                                onChange={(e) => changeDateFilter(e)}
                                name="prduction_date"
                                id="prduction_date"
                                className="form-control form-dateicon"
                                autoComplete="off"
                                popperModifiers={[
                                    {
                                      name: 'arrow',
                                      options: {
                                        padding: ({ popper, reference }) => ({
                                          right: Math.min(popper.width, reference.width) + 50,
                                        }),
                                      },
                                    },
                                ]}
                                value={prduction_date}
                                required
                            />                            
                            </div>
                            <div className='invalid-feedback'>Please select Production Date.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="batch_id" className="form-label">Batch Id <strong className="text-danger">*</strong></label>
                            <select id="batch_id" name="batch_id" onChange={(e) => handleInputChanged(e)} className="form-select form-select-lg" required>
                                <option value="">Choose</option>
                                {batchList && batchList.map((batch, i) =>
                                    <option key={i} value={batch.batch_id} >{batch.batch_id}</option>
                                )}
                            </select>
                            <div className='invalid-feedback'>Please select Batch Id.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="recorded_by" className="form-label">Storage <strong className="text-danger">*</strong></label>
                            <input type="number" min="1" id="storage" name="storage" value={storage} className="form-control"  placeholder="Storage" readOnly required />
                        </div>
                        <div className="col-6">
                            <label htmlFor="material_type" className="form-label">Material Type<strong className="text-danger">*</strong></label>
                            <select id="material_type" name="material_type" className="form-select form-select-lg"  required>
                                <option value=''>Choose</option>
                                { materialList && materialList.map((material, i) =>
                                    <option key={i} value={material.id} >({material.code}) {material.name}</option>
                                )}
                            </select>
                            <div className='invalid-feedback'>Please select material Type.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="storage_type" className="form-label">Storage Type<strong className="text-danger">*</strong></label>
                            <select id="storage_type" name="storage_type" className="form-select form-select-lg" required>
                                <option value=''>Choose</option>
                                { storageTypeList && storageTypeList.map((storagetype) =>
                                    <option key={storagetype.id} value={storagetype.id} >({storagetype.code}) {storagetype.name}</option>
                                )}
                            </select>
                            <div className='invalid-feedback'>Please select Storage Type.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="recorded_by" className="form-label">Weight<small>(Kg)<strong className="text-danger">*</strong></small></label>
                            <input type="number" id="weight" name="weight" min={0} step="any" className="form-control" placeholder="Weight" required />
                            <div className='invalid-feedback'>Please enter Weight.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="attachment" className="form-label">Attachment</label>
                            <input type="file" accept=".jpeg,.jpg,.png,.pdf" id="attachment" name="attachment" className="form-control"  placeholder="Attachment" />
                        </div>
                        {
                            (permission(context.auth.permission, 'add')) ?
                                <div className="mb-4 mb-sm-5">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                                : ''
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddBarcode;