import React, { useContext, useState, useEffect } from 'react';
import { initialFormState, permission, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { SETTING } from '../../components/ApiUrl';

const AddSettings = () => {

    window.document.title = "Settings"
    const [sett_data, setSettState] = useState([]) 
    const [context] = useContext(Context)

    const handleSubmit = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('threshold_form'));
        if(validateForm(e, 'threshold_form')){
            showloader(true)
            postFormData(SETTING.THRESHHOLDS, formData, (res) => {
                showloader()
                if(res.status){
                    initialFormState('threshold_form');
                    getSettings()
                }
            }, true);
        }
    }

    const getSettings = () => {
        showloader(true)
        getJsonData(SETTING.THRESHHOLDS, (res) => {
            showloader()
            if(res.status){
                setSettState(res.data);
            }
        }, true);
    }

    useEffect(() => {
        getSettings()
    }, [])

    return (
        <div className="container-fluid container-common">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h2 className="page-heading">Settings</h2>
                    </div>
                    <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                        <form className="row g-3 g-sm-4" id="threshold_form">
                            {sett_data && sett_data.map((item, key) =>
                                <div key={key} className="col-6">
                                    <label htmlFor="hemp_stock_end" className="form-label">
                                        {item.key_name.toUpperCase().replace(/_/g,' ')} THRESHOLD <strong className="text-danger">*</strong></label>
                                    <input type="number" id={item.key_name} name={item.key_name} defaultValue={item.key_value} className="form-control" required />
                                    <div className='invalid-feedback'>Please enter {item.key_name.toUpperCase().replace(/_/g,' ')} THRESHOLD</div>
                                </div> )
                            }
                            {permission(context && context.auth.permission, 'update') &&
                                <div className="mb-4 mb-sm-5">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>   
    )
}

export default AddSettings;