import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { initialFormState, permission, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { FARM } from '../../components/ApiUrl';
import AddStock from '../Inventory/AddStock';

const AddHemp = () => {

    window.document.title = 'Add Hemp'
    const [context] = useContext(Context)
    const [farm_list, setFarmList] = useState()
 
    const addFarm = (e) => {
        e.preventDefault();
        let formData = new FormData(document.getElementById('add_farm_modal'))

        if(validateForm(e, 'add_farm_modal')){
            showloader(true)
            postFormData(FARM.STORE, formData, (res) => {
                showloader()
                if(res.status){
                    document.querySelector('#addfarmModal [data-bs-dismiss="modal"]').click();
                    initialFormState('add_farm_modal')
                }
            }, true);
        }
        getFarms();
    }

    const getFarms = () => {
        showloader(true)
        getJsonData(FARM.LIST, (res) => {
            showloader()
            if(res.status){
                setFarmList(res.data.data)
            }
        }, true);
    }

    useEffect(() => {
        getFarms()
    }, [])

    return (
        
        <div className="container-fluid container-common">
            {/* Farm Modal */}
            <div className="modal fade" id="addfarmModal" tabIndex="-1" data-bs-backdrop="static">
                <form method="post" id="add_farm_modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addfarmModalLabel">Add Farm</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => initialFormState('add_farm_modal')} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12">
                                    <label htmlFor="farm_name" className="form-label">Farm Name <strong className="text-danger">*</strong></label>
                                    <input type="text" id="farm" name="farm" className="form-control" placeholder="Farm Name" required />
                                    <div className="invalid-feedback">Please enter Farm Name.</div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-secondary btn-160" data-bs-dismiss="modal" onClick={(e) => initialFormState('add_farm_modal')} >Close</button>
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => addFarm(e)}>Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Add Hemp</h2>
                </div>
                <div className="col-sm-12 text-end">
                    {(permission(context && context.auth.permission, 'add')) &&
                        <button className="btn btn-sm btn-dark me-2" data-bs-toggle="modal" data-bs-target="#addfarmModal" >Add Farm</button>
                    }
                    <Link to="/admin/hemp" className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                        List
                    </Link>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <AddStock inventory_id="4" farm_list={farm_list}/>
                </div>
            </div>
        </div>
    )
}

export default AddHemp;