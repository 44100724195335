import { showAlertMsg } from "../components/Helper"

export const fetchData = async(url, method, res, data_type, token = false, signal = false, data = false, content_type) => {
    
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }

    if (method === 'POST' && !content_type) {
        headers = { ...headers, 'Content-Type': 'application/json'}
    }

    if (token) {
        headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('token') }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if(signal){
        request = { ...request, 'signal': signal }
    }

    if (data) {
        request = { ...request, 'body': content_type ? data : JSON.stringify(data) }
    }

    await fetch(url, request)
        .then(response => data_type === 'blob' ? response.blob() : ( data_type === 'text' ? response.text() : response.json()))
        .then((json) => {
            let form_id = '';
            if (json.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                window.location.href = '/'
            }
            if(json.message) {
                showAlertMsg(json, form_id)
                res(json)
            }else if(json.data){
                res(json)
            }else{
                showAlertMsg(json, form_id)
                res(json)
            }
        })
        .catch(err => console.log(err));
}

export const getJsonData = (url, res, token = false, signal = false) => {
    fetchData(url, 'GET', res, 'json', token, signal)
}

export const getTextData = (url, res, token = false, signal = false) => {
    fetchData(url, 'GET', res, 'text', token, signal)
}

export const getBlobData = (url, data, res, token = false, signal = false) => {
    fetchData(url, 'POST', res, 'blob',  token, signal, data)
}

export const postData = (url, data, res, token = false, signal = false) => {
    fetchData(url, 'POST', res, 'json',  token, signal, data, false)
}

export const postFormData = (url, data, res, token = false, signal = false) => {
    fetchData(url, 'POST', res, 'json',  token, signal, data, true)
}