import React, { useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { initialFormState, permission, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { SETTING } from '../../components/ApiUrl';
import { postFormData } from '../../fetch-data'

const SystemData = () => {

    const location = useLocation()
    window.document.title = location.pathname.match('/storage-type') ? 'Storage Type' : (location.pathname.match('/material-type') ? 'Material Type' : location.pathname.match('/solvent-type') ? 'Solvent Type' : location.pathname.match('/test-type') && 'Test Type')
    const [context] = useContext(Context)
    const [systemData] = useState({
        type: '',
        code: 0,
        name: '',
        unit: 'gallons'
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        var type = location.pathname.match('/storage-type') ? 'storage' : (location.pathname.match('/material-type') ? 'material' : (location.pathname.match('/solvent-type') ? 'solvent' : 'test'))
        let formData = new FormData(document.getElementById('add_storage_type'))
        formData.append('type', type);
        if(location.pathname.match('/solvent-type') || location.pathname.match('/test-type')){
            // formData.append('code', systemData.code);
        }
        if(validateForm(e, 'add_storage_type')){
            postFormData(SETTING.SYSTEM_DATA_STORE, formData, (res) => {
                if(res.status){
                    initialFormState('add_storage_type')    
                }
            }, true);
        }
    }

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">
                        { location.pathname.match('/storage-type') && 'Storage Type' }
                        { location.pathname.match('/material-type') && 'Material Type' }
                        { location.pathname.match('/solvent-type') && 'Solvent Type' }
                        { location.pathname.match('/test-type') && 'Test Type' }
                    </h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <div className="col-sm-12 text-end">
                        { location.pathname.match('/storage-type') && <Link to="/admin/storage-type-list"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>List</Link> }
                        { location.pathname.match('/material-type') && <Link to="/admin/material-type-list"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>List</Link> }
                        { location.pathname.match('/solvent-type') && <Link to="/admin/solvent-type-list"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>List</Link> }
                        { location.pathname.match('/test-type') && <Link to="/admin/test-type-list"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>List</Link> }
                    </div>
                    <form method="post" className="row g-3 g-sm-4" id="add_storage_type">
                        <div className="col-6">
                            <label htmlFor="name" className="form-label">
                            { location.pathname.match('/storage-type') && 'Storage Type' }
                            { location.pathname.match('/material-type') && 'Material Type' }
                            { location.pathname.match('/solvent-type') && 'Solvent Type' }
                            { location.pathname.match('/test-type') && 'Test Type' }
                            <strong className="text-danger"> *</strong></label>

                            <input type="text" id="name" name="name" className="form-control"  placeholder="Enter value" required />
                            <div className='invalid-feedback'>Please enter value.</div>
                        </div>
                        { !location.pathname.match('/test-type') && !location.pathname.match('/solvent-type') &&
                        <div className="col-6">
                            <label htmlFor="code" className="form-label">Code <strong className="text-danger">*</strong></label>
                            
                            <input type="number" min="0" id="code" defaultValue={systemData.code} name="code" className="form-control" placeholder="Enter value" required />
                            <div className='invalid-feedback'>Please enter code.</div>
                        </div>
                        }
                        { location.pathname.match('/solvent-type') &&
                        <div className="col-6">
                            <label htmlFor="unit" className="form-label">Unit Type<strong className="text-danger">*</strong></label>
                            
                            <input type="text" id="units" name="units" className="form-control" defaultValue={systemData.unit}  placeholder="Enter value" required />
                            <div className='invalid-feedback'>Please enter unit type.</div>
                        </div>
                        }
                        {permission(context && context.auth.permission, 'add') &&
                            <div className="mb-4 mb-sm-5">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div> 
    )
}

export default SystemData;