import React, { useEffect, useState, useContext } from 'react';
import { momentDateDisplay, permission, showloader } from '../../components/Helper';
import { EDIT_REQUEST} from '../../components/ApiUrl';
import { DataList } from '../../components/DataList';
import { __echoText } from '../../components/Helper';
import _, { now } from 'lodash'
import Context from '../../components/Context';
import { postData } from '../../fetch-data';

const ListEditRequest = () => {
    window.document.title = 'Edit Request'
    
    const [refresh, setRefresh] = useState('')
    const [context] = useContext(Context)

    const dt = {
        id: 'edit-request',
        fetchurl: EDIT_REQUEST.LIST,
        columns: [
            { name: 'name', title: 'Farm Name' },
            { name: 'request_by', title: 'Requested By', width: 50 },
            { name: 'request_status', title: 'Status' },
            { name: 'created_at', title: 'Request Date' },
            { name: 'action', title: 'Action' },
        ]
    }

    const requestUpdate = (data, status) => {
        let req_data = {
            request_id: data.id,
            form_id: data.form_id,
            refrence_id: data.refrence_id,
            request_status: status
        }
        
        showloader(true)
        postData(EDIT_REQUEST.STATUS_UPDATE, req_data, (res) => {
            showloader()
            if (res.status) {
                setRefresh(now)
            }
        }, true)
    }

    const reqStatus = (status) => {
        switch (status) {
            case 1:
                return 'Requested'
            case 2:
                return 'Approved'
            case 3:
                return 'Rejected'
            case 4:
                return 'Completed'
            default:
                return 'Default'
        }
    }

    useEffect(() => {

    }, [refresh])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Edit Request</h2>
                </div>
                <DataList
                    dt={dt}
                    refresh={refresh}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                        </div>
                    }
                    edit_column={{
                        request_status : (records) =>
                            <span>
                                {records && reqStatus(records.request_status)}
                            </span>,
                        created_at: (records) =>
                            <span>
                                {records && momentDateDisplay(records.created_at)}
                            </span>,
                        action: (records) =>
                            <div className="d-flex">
                                { permission(context && context.auth.permission, 'update') &&
                                    (records && records.request_status === 1)?
                                    <div>
                                        <button className="btn btn-sm btn-outline-success m-1" onClick={() => requestUpdate(records, 2)}>Accept
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger m-1" onClick={() => requestUpdate(records, 3)}>Reject
                                        </button>
                                    </div>
                                    :'-'
                                }
                            </div>
                    }}
                />
            </div>
        </div>
    );
}

export default ListEditRequest;
