import React from 'react';
import StockList from '../Inventory/StockList';

const StockHemp = () => {

    window.document.title = 'Hemp Inventory Stock Log'

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Hemp Inventory Stock Log</h2>
                </div>
                <StockList inventory_id="4"/>
            </div>
        </div>
    );
}
export default StockHemp;
