import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { showloader } from '../../components/Helper';
import { SETTING } from '../../components/ApiUrl';
import { DataList } from '../../components/DataList';
import { now } from 'lodash';
import { postData } from '../../fetch-data';

const SystemDataList = () => {
    const location = useLocation()
    window.document.title = location.pathname.match('/storage-type') ? 'Storage Type List' : (location.pathname.match('/material-type') ? 'Material Type List' : location.pathname.match('/solvent-type') ? 'Solvent Type List' : location.pathname.match('/test-type') && 'Test Type List')
    const [refresh, setRefresh] = useState()
    const [type] = useState(location.pathname.match('/storage-type-list') ? 'storage' : location.pathname.match('/material-type-list') ? 'material' : location.pathname.match('/solvent-type-list') ? 'solvent' : 'test')
    
    const dt =  {
        id: 'system-data-list',
        fetchurl: SETTING.SYSTEM_DATA_LIST + '?type=' + type + '&',
        columns: [
            { name: 'id', title: 'ID', width: 10 },
            { name: 'code', title: 'Code', visible: !location.pathname.match('/test-type-list') && !location.pathname.match('/solvent-type-list') ? true : false},
            { name: 'name', title: (location.pathname.match('/storage-type-list') && 'Storage Type') || (location.pathname.match('/material-type-list') && 'Material Type') || (location.pathname.match('/solvent-type-list') && 'Solvent Type') || (location.pathname.match('/test-type-list') && 'Test Type'), width: 100},
            { name: 'units', title: 'Unit Type', visible: location.pathname.match('/solvent-type-list') ? true : false},
            { name: 'status', title: 'Status'},
            { name: 'action', title: 'Action'}
        ]
    }
   
    const changeStatus = (record_id, status) => {
        showloader(true)
        postData(SETTING.SYSTEM_UPDATE_STATUS, { id: record_id, status: status}, (res) => {
            showloader()
            if(res.status){
                setRefresh(now)
            }
        }, true);
    }


    useEffect(() => {   
    }, [refresh])

    return(
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">
                        { location.pathname.match('/storage-type-list') && 'Storage Type List' }
                        { location.pathname.match('/material-type-list') && 'Material Type List' }
                        { location.pathname.match('/solvent-type-list') && 'Solvent Type List' }
                        { location.pathname.match('/test-type-list') && 'Test Type List' }
                    </h2>
                </div>
                <div className="col-md-12 my-2">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(records) =>
                            <div className="ms-auto">
                                { location.pathname.match('/storage-type-list') && <Link to="/admin/storage-type"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>Back</Link> }
                                { location.pathname.match('/material-type-list') && <Link to="/admin/material-type"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>Back</Link> }
                                { location.pathname.match('/solvent-type-list') && <Link to="/admin/solvent-type"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>Back</Link> }
                                { location.pathname.match('/test-type-list') && <Link to="/admin/test-type"  className="btn btn-sm btn-success"><svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>Back</Link> }
                            </div>
                        }
                        edit_column={{
                            status : (record) =>
                                <span>
                                    { record && record.status === 'active' ? 'Active' : 'Inactive' }
                                </span>,
                            action : (record) =>
                                <span>
                                    { record.status === 'active' ?
                                        <button type="button" className="btn btn-sm btn-danger m-1" onClick={(e) => changeStatus(record.id, 'inactive')}>
                                            <svg className="icon"><use xlinkHref="#icon_inactive"/></svg>
                                        </button>
                                    :
                                        <button type="button" className="btn btn-sm btn-success m-1" onClick={(e) => changeStatus(record.id, 'active')}>
                                            <svg className="icon"><use xlinkHref="#icon_active"/></svg>
                                        </button>
                                    }
                                </span>
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default SystemDataList;