import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { downloadBlob, momentDateDisplay, showloader } from './Helper';
import $ from 'jquery';
import { getBlobData, postData, postFormData } from '../fetch-data';
import { EXPORT_DATA_DOWNLOAD } from './ApiUrl';

const ExportData = (props) => {
    
    const [eData, setExportData] = useState({
        export_type: props.export_data.type,
        export_data: props.export_data.data,
        start_date: momentDateDisplay('', false, false, 'YYYY-MM-DD'),
        end_date: momentDateDisplay('', false, false, 'YYYY-MM-DD'),
        disabled: '',
    })

    const selectStartDate = (date) => {
        setExportData(prevState => ({
            ...prevState,
            start_date : momentDateDisplay(date, false, false, 'YYYY-MM-DD') 
        }));
    }

    const selectEndDate = (date) => {
        setExportData(prevState => ({
            ...prevState,
            end_date : momentDateDisplay(date, false, false, 'YYYY-MM-DD') 
        }));
    }

    const exportData = () => {    
        setExportData(prevState => ({
            ...prevState,
            disabled: 'disabled'
        }))

        $(".button-spinner").show();
        
        let filter = {
                export_type: eData.export_type,
                export_data: eData.export_data,
                start_date: eData.start_date,
                end_date: eData.end_date,
                export_inventory: parseInt(props.inventory_id),
                unit_type: props.unit_type
        }
        showloader(true)
        getBlobData(EXPORT_DATA_DOWNLOAD, filter, (res) => {
            showloader()
            downloadBlob(res)
            setExportData(prevState => ({
                ...prevState,
                disabled: ''
            }))
            $(".button-spinner").hide();
            $(".export-filter").toggle();
            
        }, true);
    }

    const exportFilterToggle = () => {
        $(".export-filter").toggle();
    }

    return(
        <div className="export-filter-daterange">
            <button className="btn btn-success btn-sm me-2 mb-1" onClick={() => exportFilterToggle()}>
                Export
            </button>
            <div className="export-dropdown export-filter">
                <div className="month-wrapperbox">
                    <DatePicker 
                            className="me-2"
                            onChange={selectStartDate}
                            name="export_start_date"
                            id="export_start_date"
                            maxDate={new Date()}
                            value={eData.start_date ? momentDateDisplay(eData.start_date) : ''}
                            selected={eData.start_date ? new Date(eData.start_date) : ''}
                    />
                    <DatePicker
                            placeholderText="Select Date"
                            onChange={selectEndDate}
                            name="export_end_date"
                            id="export_end_date"
                            maxDate={new Date()}
                            minDate={eData.start_date ? new Date(eData.start_date) : ''}
                            value={eData.end_date ? momentDateDisplay(eData.end_date) : ''}
                            selected={eData.end_date ? new Date(eData.end_date) : ''}
                    />
                    <button className={`ms-2 btn btn-success btn-sm d-flex ${eData.disabled}`} onClick={() => exportData()}>
                        Download
                        <span className="spinner-grow spinner-grow-sm mt-1 ms-2 button-spinner" role="status" aria-hidden="true"></span>
                    </button>
                </div>
                
            </div>
        </div>
    )
}
 
export default ExportData;