import React, { useEffect, useState, useContext } from 'react';
import { initialFormState, permission, showloader, validateForm } from '../../components/Helper';
import { getJsonData, postFormData } from '../../fetch-data';
import { AUTH, USER } from '../../components/ApiUrl';
import Context from '../../components/Context';

const AddUser = () => {
    window.document.title = 'Add User'

    const [userform, setUserForm] = useState('')
    const [user_type, setUserType] = useState(false)
    const [context] = useContext(Context)

    const handleSubmit = (e) => {
        let formData = new FormData(document.getElementById('add_user'));

        if (validateForm(e, 'add_user')) {
            showloader(true)

            postFormData(USER.ADD_USER, formData, (res) => {
                showloader()

                if (res.status) {
                    initialFormState('add_user');
                }
            }, true);
        }
    }

    const getForm = () => {
        showloader(true)
        getJsonData(AUTH.GET_USER_FORM, (res) => {
            showloader()
            if (res.status) {
                setUserForm(res.data)
            }
        }, true);
    }

    useEffect(() => {
        getForm()
    }, [])


    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Add User</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form className="row g-3 g-sm-4" id="add_user" autoComplete='off'>
                        <div className="col-6">
                            <label htmlFor="farm" className="form-label">User Type<strong className="text-danger">*</strong></label>
                            <select id="user_type" name="user_type" className="form-select form-select-lg" required onChange={(e) => setUserType(e.target.value)}>
                                <option value=''>Choose User Type</option>
                                <option value={2}>User</option>
                                <option value={1}>Admin</option>
                            </select>
                            <div class="invalid-feedback">Please select user type.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="old_password" className="form-label">Name <strong className="text-danger">*</strong></label>
                            <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                            <div class="invalid-feedback">Name is required.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Email <strong className="text-danger">*</strong></label>
                            <input type="email" name="email" className="form-control" placeholder="Email" required />
                            <div class="invalid-feedback">Email is required.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Password <strong className="text-danger">*</strong></label>
                            <input type="password" name="password" className="form-control" placeholder="Password" required />
                            <div class="invalid-feedback">Password is required.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="password" className="form-label">Password Confirmation <strong className="text-danger">*</strong></label>
                            <input type="password" name="password_confirmation" className="form-control" placeholder="Password Confirmation" required />
                            <div class="invalid-feedback">Password confirmation is required.</div>
                        </div>

                        {user_type === "2" &&
                            <>
                                <label className="form-label">User Assign Form <strong className="text-danger">*</strong></label>
                                <div>
                                    {
                                        userform.map((data, key) =>
                                            <div key={key}>
                                                <div className="form-check">
                                                    <input className="form-check-input" name="userform[]" id="userform" type="checkbox" defaultChecked={false} value={data.id} />
                                                    <label className="form-check-label small" htmlFor="userform">
                                                        {data.name}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        }

                        {user_type === "1" &&
                            <>
                                <label className="form-label">Admin User Permission <strong className="text-danger">*</strong></label>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" value={"view"} />
                                        <label className="form-check-label small" >View</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" value="add" />
                                        <label className="form-check-label small">Add</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" value="update" />
                                        <label className="form-check-label small">Update</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" name="permission[]" type="checkbox" value="delete" />
                                        <label className="form-check-label small">Delete</label>
                                    </div>
                                </div>
                            </>
                        }
                        {permission(context && context.auth.permission, 'add') &&
                            <div className="mb-4 mb-sm-5">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUser;