import React, { useContext, useState} from 'react';
import { ConfirmationModal, momentDateDisplay, permission, showloader } from '../../components/Helper';
import { DataList } from '../../components/DataList';
import { TESTING_CENTER, ASSET_ENDPOINT } from '../../components/ApiUrl';
import { getJsonData } from '../../fetch-data';
import Context from '../../components/Context';
import {now} from 'lodash';

const ListTestingCenter = () => {
    window.document.title = 'Testing Center List';

    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setdeleteRecord] = useState('')
    const [context] = useContext(Context)

    const dt =  {
        id: 'invoice-list',
        fetchurl: TESTING_CENTER.TESTING_CENTER_LIST,
        columns: [
            { name: 'test_date', title: 'Test Date'},
            { name: 'batch_id', title: 'Batch ID' },
            { name: 'farm_name', title: 'Farm Name', width: 80},
            { name: 'material_type', title: 'Material Type'},
            { name: 'test_type', title: 'Test Type', width: 80},
            { name: 'attachment', title: 'Attachment', width: 250},
            { name: 'action', title: 'Action'}
        ]
    }

    const deleteTestingRecord = (id) => {
        showloader(true)
        getJsonData(TESTING_CENTER.TESTING_CENTER_DELETE +'/'+ id, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            showloader()
            if(res.status){
                setRefresh(now)
            }
        }, true);
    }
            
    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Testing Center</h2>
                </div>
                <div className="col-md-12">
                    <DataList
                        dt={dt}
                        refresh={refresh}
                        outerbutton={(records) =>
                            <div className="ms-auto">
                            </div>
                        }
                        edit_column={{

                            test_date: (record) => 
                                <span>
                                    {record && momentDateDisplay(record.test_date)}
                                </span>,

                            material_type: (record) => 
                                <span>
                                    {record && record.material_type && record.material_type.name ? '('+ record.material_type.code +') ' + record.material_type.name : '-'}
                                </span>,
                            test_type: (record) => 
                                <span>
                                    { record.test_type && record.test_type ? record.test_type.name : '-'}
                                </span>,
                            attachment: (record) => 
                                record.attachment ? <a href={`${ASSET_ENDPOINT}${record.attachment}`} rel="noopener noreferrer"  target="_blank">{record.attachment}</a> : '-',
                                
                            action : (record) =>
                                <span>
                                    { permission(context && context.auth.permission, 'delete') &&
                                        <button className="btn btn-sm btn-outline-danger m-1" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={(e) => setdeleteRecord(record.id)}>
                                            <svg className="icon"><use xlinkHref="#icon_trash"/></svg>
                                        </button>
                                    }
                                </span>
                        }}
                    />
                    <ConfirmationModal msg="Are you sure want to delete?" method={deleteRecord ? () => deleteTestingRecord(deleteRecord ? deleteRecord : '') : ''} />
                </div>
            </div>
        </div>
    );
}
export default ListTestingCenter;
