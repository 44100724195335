import React, { useContext, useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { momentDate, momentDateDisplay, floatNumber, showloader, downloadPdf } from '../../components/Helper';
import { getJsonData } from '../../fetch-data';
import { DASHBOARD, SETTING } from '../../components/ApiUrl';
import Context from '../../components/Context';

const Dashboard = () => {

    window.document.title = 'Dashboard';

    const [context] = useContext(Context)
    const [stats, setStats] = useState({ 
        inventory_stats: [],
        date: momentDate('', false, false, 'YYYY-MM-DD', true), 
        final_inventory: 0, 
        tot_bag_weight: 0,
        tot_bag_num: 0,
        hemp_current_stock: 0,
        cbda_current_stock: 0,
        stock_alert: [],
        solvent_list: '',
        material_list: '',
    })

    const getSettings = () => {
        let stockAlertArray = []
        showloader(true)
        getJsonData(SETTING.THRESHHOLDS, (res) => {
            showloader()
            if (res.status) {
                res.data && res.data.map(settings => {
                    stockAlertArray[settings.inventory_id] = settings.key_value
                })
                setStats(prevState => ({
                    ...prevState,
                    stock_alert: stockAlertArray,
                }))
            }
        }, true)
    }

    const getStats = (date) => {
        showloader(true)
        getJsonData(DASHBOARD.STATS + `?date=` + date, (res) => {
            showloader()
            if(res.status && res.data){
                setStats(prevState => ({
                    ...prevState,
                    inventory_stats: res.data,
                    final_inventory: res.data.inventory.final_inventory,
                    hemp_current_stock: res.data.inventory.hemp_inventory,
                    cbda_current_stock: res.data.inventory.cbda_inventory,
                    tot_bag_num: res.data.inventory.bagged_hemp.tot_bag_num,
                    tot_bag_weight: res.data.inventory.bagged_hemp.tot_bag_weight,
                    general_waste_inventory: res.data.inventory.general_waste_inventory,
                    total_thc_waste: res.data.inventory.total_thc_waste,
                    solvent_list: res.data.inventory.solvent,
                    material_list: res.data.inventory.material
                }));
            }
        }, true)
    }

    const changeDateFilter = (filter_date) => {
        setStats(prevState => ({
            ...prevState,
            date: momentDateDisplay(filter_date, false, false, 'YYYY-MM-DD') 
        }));

        getStats(momentDateDisplay(filter_date, false, false, 'YYYY-MM-DD'));
    }

    const sendFinalInventory = () => {
        getJsonData(DASHBOARD.SEND_FINAL_ENVENTORY + `?${stats.date}`, (res) => {
            if(res.status){
            }
        }, true);
    }


    useEffect(() => {
        getSettings();
        getStats(stats.date);
    }, [])

    return (
        <>
            { context.auth.role_id === 1 ?
            <div className="container-fluid container-common">
                <div className="col-sm-12">
                    <h2 className="page-heading">
                        Operation Sheet <span style={{ fontWeight: 'normal', marginLeft: '10px', lineHeight: '1', fontSize: '14px' }}>{momentDateDisplay(stats.date)}</span>
                    </h2>
                </div>
                
                <div className="row justify-content-center" id="container">
                <div className="row d-print-none">
                    <div className="col-sm-6 mb-3" id="select-date">
                        <div className="d-flex align-items-center1">
                            <div className="input-group input-group-sm" style={{ maxWidth: '380px' }}>
                                <span className="input-group-text text-white border-0 bg-dark">Select Date</span>
                                <div className="month-wrapperbox">
                                    <DatePicker
                                        placeholderText="Select Date"
                                        onChange={changeDateFilter}
                                        name="date"
                                        maxDate={new Date()}
                                        value={ momentDateDisplay(stats.date)}
                                        selected={new Date(stats.date)}
                                        shouldCloseOnSelect={true}
                                        popperModifiers={[
                                            {
                                              name: 'arrow',
                                              options: {
                                                padding: ({ popper, reference }) => ({
                                                  right: Math.min(popper.width, reference.width) + 50,
                                                }),
                                              },
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div className="col-sm-6 mb-3 text-end align-self-center">
                    <button type="button" className="btn btn-sm px-3 btn-success me-1" onClick={() => sendFinalInventory()} id="send-inventory"><svg className="icon center me-1"><use xlinkHref="#icon_mail" /></svg>Send Final Inventory</button>
                        <button type="button" className="btn btn-sm px-3 btn-dark" id="generate-pdf" onClick={() => downloadPdf('printDiv', `Axtelllab_${momentDateDisplay(stats.date, false, false, 'DD-MM-YYYY')}` , 'A3', 'landscape' )}><svg className="icon center me-1 "><use xlinkHref="#icon_request-edit" /></svg>Generate PDF</button>
                    </div>
                    <div className="col-md-12 text-muted text-start pdf-date d-none">Date : {momentDateDisplay(stats.date, false, false, 'DD-MM-YYYY')}</div>
                </div>
                <div id="printDiv">  
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-8 mt-2">
                            <div className="card">
                                <h5 className="card-header text-white bg-dark">Inventory</h5>
                                <div className="card-body">
                                    <div className="row">
                                        
                                        <div className='col'>
                                            <div className="col mb-1 p-1 bg-dark rounded" style={{opacity: "0.8"}}>
                                                <h6 className="fw-bold text-white d-inline-block m-0">Biomass</h6>
                                            </div>
                                            <div className={`col mb-1 p-1 rounded text-white text-center align-self-center ${parseInt(stats.hemp_current_stock) <= parseInt(stats.stock_alert[4]) ? "bg-danger" : "bg-success"}`}>
                                                <span className="me-3">Hemp Stock</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(stats.hemp_current_stock)}</span>
                                                <span className="fs-12">(lbs)</span>
                                            </div>
                                            <div className={`col mb-4 p-1 rounded text-white text-center align-self-center ${parseInt(stats.hemp_current_stock) <= parseInt(stats.stock_alert[4]) ? "bg-danger" : "bg-success"}`}>
                                                <span className="me-3">Bagged Hemp</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(stats.tot_bag_weight)}</span>
                                                <span className="fs-12">(lbs)</span><span className="fw-bold fs-20 me-1"> / {parseInt(stats.tot_bag_num)}</span>
                                                <div><span className="fs-12">Weight of Bag / No. of Bag</span></div>
                                            </div>
                                            <div className="col mb-1 p-1 bg-dark rounded" style={{opacity: "0.8"}}>
                                                <h6 className="fw-bold text-white d-inline-block m-0">Crude Oil</h6>
                                            </div>
                                            <div className={`col mb-4 p-1 rounded text-white text-center align-self-center ${parseInt(stats.cbda_current_stock) <= parseInt(stats.stock_alert[3]) ? "bg-danger" : "bg-success"}`}>
                                                <span className="me-3">CBDa Crude Oil</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(stats.cbda_current_stock)}</span>
                                                <span className="fs-12">(kgs)</span>
                                            </div>

                                            <div className="col mb-1 p-1 bg-dark rounded" style={{opacity: "0.8"}}>
                                                <h6 className="fw-bold text-white d-inline-block m-0">Solvents</h6>
                                            </div>
                                            { stats.solvent_list && stats.solvent_list.length > 0 && stats.solvent_list.map(item =>
                                            <div key={item.id} className={`col mb-1 p-1 rounded text-white text-center align-self-center ${parseInt(item.inventory) <= parseInt(stats.stock_alert[item.id])? "bg-danger" : "bg-success"}`}>
                                                <span className="me-3">{item.name} Stock</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(item.inventory)}</span>
                                                <span className="fs-12">({item.unit})</span>
                                            </div> )}
                                        </div>
                                        <div className='col'>
                                            <div className="col mb-1 p-1 bg-dark rounded" style={{opacity: "0.8"}}>
                                                <h6 className="fw-bold text-white d-inline-block m-0">Waste</h6>
                                            </div>
                                            <div className={`col mb-1 p-1 rounded text-white text-center align-self-center bg-success`}>
                                                <span className="me-3">General Waste Stock</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(stats.general_waste_inventory)}</span>
                                                <span className="fs-12">(kgs)</span>
                                            </div>
                                            <div className={`col mb-4 p-1 rounded text-white text-center align-self-center  bg-success`}>
                                                <span className="me-3">Total THC Waste Stock</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(stats.total_thc_waste)}</span>
                                                <span className="fs-12">(kgs)</span>
                                            </div>
                                            <div className="col mb-1 p-1 bg-dark rounded" style={{opacity: "0.8"}}>
                                                <h6 className="fw-bold text-white d-inline-block m-0">Bulk Inventory</h6>
                                            </div>
                                            { stats.material_list && stats.material_list.length > 0 && stats.material_list.map(item =>
                                            <div key={item.id} className={`col mb-1 p-1 rounded text-white text-center align-self-center ${parseInt(item.inventory) <= parseInt(stats.stock_alert[item.id])? "bg-danger" : "bg-success"} ${item.id == 16 || item.id == 17 ? 'd-none' : ''}`}>
                                                <span className="me-3">{item.name} Stock</span>
                                                <span className="fw-bold fs-20 me-1">{floatNumber(item.inventory)}</span>
                                                <span className="fs-12">(kgs)</span>
                                            </div> )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 mt-2">
                            <div className="card">
                                <h5 className="card-header text-white bg-dark">Final Inventory</h5>
                                <div className="card-body">
                                    <div className="col mb-1">
                                        { stats.final_inventory && stats.final_inventory.length > 0 && stats.final_inventory.map((item, key) =>{
                                            return (
                                                <div key={key}>
                                                    <div className={`col mb-1 p-1 rounded text-white text-center align-self-center ${parseInt(item.inventory) <= parseInt(stats.stock_alert[item.id])? "bg-danger" : "bg-success"} ${item.id == 16 || item.id == 17 ? 'd-none' : ''}`}>
                                                        <span className="me-3">{item.name}</span><br></br>
                                                        { item.id == 11 ? item.inventory && item.inventory && item.inventory.length > 0 ? item.inventory.map((v, i) => {
                                                                return (<div key={i}><span className="fw-bold fs-20 me-1">{v.weight}</span><span className="fs-12">(ltr)</span><span className="fw-bold fs-20 me-1">/ {v.unit}</span><span className="fs-12">(units)</span><br></br></div>)
                                                            }) : <><span className="fw-bold fs-20 me-1">0</span><span className="fs-12">(ltr)</span><span className="fw-bold fs-20 me-1"> / 0</span><span className="fs-12">(units)</span></> : ''}
                                                        
                                                        { item.id == 13 ? item.inventory && item.inventory && item.inventory.length > 0 ? item.inventory.map((v, i) => {
                                                                return (<div key={i}><span className="fw-bold fs-20 me-1">{v.weight * 1000}</span><span className="fs-12">(grams)</span><span className="fw-bold fs-20 me-1">/ {v.unit}</span><span className="fs-12">(units)</span><br></br></div>)
                                                            }) : <><span className="fw-bold fs-20 me-1">0</span><span className="fs-12">(grams)</span><span className="fw-bold fs-20 me-1"> / 0</span><span className="fs-12">(units)</span></> : ''}
                                                        
                                                        { item.id != 11 && item.id != 13 ? item.inventory && item.inventory && item.inventory.length > 0 ? item.inventory.map((v, i) => {
                                                                return (<div key={i}><span className="fw-bold fs-20 me-1">{v.weight}</span><span className="fs-12">(kgs)</span><span className="fw-bold fs-20 me-1">/ {v.unit}</span><span className="fs-12">(units)</span><br></br></div>)
                                                            }) : <><span className="fw-bold fs-20 me-1">0</span><span className="fs-12">(kgs)</span><span className="fw-bold fs-20 me-1"> / 0</span><span className="fs-12">(units)</span></> : ''}
                                                    </div>
                                                </div> 
                                        )})}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='row mt-4 break-before'>
                        <div className="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <div className="card">
                                <h5 className="card-header text-white bg-dark">Operations</h5>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered mb-4">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="3">Biomass Extraction</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Today</td>
                                                        <td>{(stats.inventory_stats.preextraction_statistics) ? floatNumber(stats.inventory_stats.preextraction_statistics.today.weight) : ''} <small className="text-muted">lbs</small> ({(stats.inventory_stats.preextraction_statistics) ? stats.inventory_stats.preextraction_statistics.today.number_of_bag : ''} <small className="text-muted">Bags</small>)</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>{(stats.inventory_stats.preextraction_statistics) ? floatNumber(stats.inventory_stats.preextraction_statistics.wtd.weight) : ''} <small className="text-muted">lbs</small> ({(stats.inventory_stats.preextraction_statistics) ? stats.inventory_stats.preextraction_statistics.wtd.number_of_bag : ''} <small className="text-muted">Bags</small>)</td>
                                                        <td><small className="text-muted">Avg</small> {(stats.inventory_stats.preextraction_statistics) ? floatNumber(stats.inventory_stats.preextraction_statistics.avg_wtd.weight) : ''} <small className="text-muted">lbs</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{(stats.inventory_stats.preextraction_statistics) ? floatNumber(stats.inventory_stats.preextraction_statistics.mtd.weight) : ''} <small className="text-muted">lbs</small> ({(stats.inventory_stats.preextraction_statistics) ? stats.inventory_stats.preextraction_statistics.mtd.number_of_bag : ''} <small className="text-muted">Bags</small>)</td>
                                                        <td><small className="text-muted">Avg</small> {(stats.inventory_stats.preextraction_statistics) ? floatNumber(stats.inventory_stats.preextraction_statistics.avg_mtd.weight) : ''} <small className="text-muted">lbs</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-4 col-lg-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered mb-4">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="3">Extraction Efficiency</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Yesterday</td>
                                                        <td>{(stats.inventory_stats.extraction_efficiency) ? floatNumber(stats.inventory_stats.extraction_efficiency.yesterday.percent) : ''} <small className="text-muted">%</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{(stats.inventory_stats.extraction_efficiency) ? floatNumber(stats.inventory_stats.extraction_efficiency.mtd.percent) : ''} <small className="text-muted">%</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-4 col-lg-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered mb-4">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="3">Decarb Weight Retention</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Yesterday</td>
                                                        <td>{(stats.inventory_stats.decarb_weight_retention) ? floatNumber(stats.inventory_stats.decarb_weight_retention.yesterday.percent) : ''} <small className="text-muted">%</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{(stats.inventory_stats.decarb_weight_retention) ? floatNumber(stats.inventory_stats.decarb_weight_retention.mtd.percent) : ''} <small className="text-muted">%</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered mb-4">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="3">CBDa Produced</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Today</td>
                                                        <td>{ (stats.inventory_stats.cbda_inventory_statistics)? floatNumber(stats.inventory_stats.cbda_inventory_statistics.today.weight) :'0.00'  } <small className="text-muted">kg</small></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>{ (stats.inventory_stats.cbda_inventory_statistics)? floatNumber(stats.inventory_stats.cbda_inventory_statistics.wtd.weight) :'0.00'  } <small className="text-muted">kg</small></td>
                                                        <td><small className="text-muted">Avg</small> { (stats.inventory_stats.cbda_inventory_statistics)? floatNumber(stats.inventory_stats.cbda_inventory_statistics.avg_wtd.weight):'0.00'  } <small className="text-muted">kg</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{ (stats.inventory_stats.cbda_inventory_statistics)? floatNumber(stats.inventory_stats.cbda_inventory_statistics.mtd.weight) :'0.00'  } <small className="text-muted">kg</small></td>
                                                        <td><small className="text-muted">Avg</small> { (stats.inventory_stats.cbda_inventory_statistics)? floatNumber(stats.inventory_stats.cbda_inventory_statistics.avg_mtd.weight):'0.00'  } <small className="text-muted">kg</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="3">CBD Produced</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Today</td>
                                                        <td>{(stats.inventory_stats.cbd_inventory_statistics) ? floatNumber(stats.inventory_stats.cbd_inventory_statistics.today.weight) : '0.00'} <small className="text-muted">kg</small></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>{ (stats.inventory_stats.cbd_inventory_statistics)? floatNumber(stats.inventory_stats.cbd_inventory_statistics.wtd.weight):'0.00'  } <small className="text-muted">kg</small></td>
                                                        <td><small className="text-muted">Avg</small> {stats.inventory_stats.cbd_inventory_statistics ? floatNumber(stats.inventory_stats.cbd_inventory_statistics.avg_wtd.weight) : '0.00'} <small className="text-muted">kg</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{ (stats.inventory_stats.cbd_inventory_statistics)? floatNumber(stats.inventory_stats.cbd_inventory_statistics.mtd.weight):'0.00'  } <small className="text-muted">kg</small></td>
                                                        <td><small className="text-muted">Avg</small> {stats.inventory_stats.cbd_inventory_statistics ? floatNumber(stats.inventory_stats.cbd_inventory_statistics.avg_mtd.weight) : '0.00'} <small className="text-muted">kg</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="col-md-4">
                                            <table className="table table-sm table-bordered table-striped table-hover text-center bordered mb-4">
                                                <tbody>
                                                    <tr className="table-light">
                                                        <th colSpan="4">Ethanol Spend</th>
                                                    </tr>
                                                    <tr>
                                                        <td>Today</td>
                                                        <td>{ (stats.inventory_stats.ethanol_spent_statistics)? floatNumber(stats.inventory_stats.ethanol_spent_statistics.today.weight):'0.00'  } <small className="text-muted">gallons</small></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Week</td>
                                                        <td>{ (stats.inventory_stats.ethanol_spent_statistics)? floatNumber(stats.inventory_stats.ethanol_spent_statistics.wtd.weight):'0.00'  } <small className="text-muted">gallons</small></td>
                                                        <td><small className="text-muted">Avg</small> { (stats.inventory_stats.ethanol_spent_statistics)? floatNumber(stats.inventory_stats.ethanol_spent_statistics.avg_wtd.weight):'0.00'  } <small className="text-muted">gallons</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td>This Month</td>
                                                        <td>{ (stats.inventory_stats.ethanol_spent_statistics)? floatNumber(stats.inventory_stats.ethanol_spent_statistics.mtd.weight):'0.00'  } <small className="text-muted">gallons</small></td>
                                                        <td><small className="text-muted">Avg</small> { (stats.inventory_stats.ethanol_spent_statistics)? floatNumber(stats.inventory_stats.ethanol_spent_statistics.avg_mtd.weight) :'0.00'  } <small className="text-muted">gallons</small></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        : 
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Dashboard</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                </div>
            </div>
        </div>
        }
    </> )
}
export default Dashboard;