import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className='front-container'>
            <div className="w-100 minh-100 d-flex align-items-center justify-content-center">
                <div className="d-block text-center">
                    <style jsx="true">{`
                        .minh-100{
                            min-height: 100vh;
                        }
                        h1{
                            font-size: calc( 12vw + 24px);
                            opacity: 0.40
                        }
                        h5{
                            font-size: calc( 1vw + 16px);
                        }
                    `}</style>

                    <h1 className="fw-bolder m-0">404</h1>
                    <h5 className="fw-bolder m-0">Opps! Page Not Found</h5>
                    <p className='py-2'>Sorry, the page you're looking for doesn't exist.</p>
                    <hr className="w-50 ms-auto me-auto"/>
                    <Link to="/" className="btn btn-dark px-4 px-sm-5">Return Back</Link>
                </div>
            </div>
        </div>
    )
}

export default NotFound;