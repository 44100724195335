import React from 'react';
import InventoryList from '../Inventory/InventoryList';

function CBDaWeightList() {

    window.document.title = 'Cbda Crude Oil Inventory'

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">CBDa Crude Oil Inventory</h2>
                </div>
                <InventoryList inventory_id={3}/>
            </div>
        </div>
    )
}

export default CBDaWeightList;