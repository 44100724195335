import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { showloader } from '../../components/Helper';
import AddStock from '../Inventory/AddStock';
import { getJsonData } from '../../fetch-data';
import { GET_FORM_DATA } from '../../components/ApiUrl';
import Context from '../../components/Context';

const BulkInventory = (props) => {

    window.document.title = 'Bulk Inventory'

    const [axData, setAxData] = useState()
    const [context] = useContext(Context);
    let user_type = context && context.auth && context.auth.role_id === 1 ? 'admin' : 'user';
    

    const fetchMaterialType = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=material', (res) => {
            showloader()
            if(res.status){
                setAxData(res.data.data)
            }
        }, true);
    }

    useEffect(() => {
        fetchMaterialType()
    }, [])

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Bulk Inventory</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                <div className="col-sm-12 text-end">
                    <Link to={`/${user_type}/bulk-inventory-list`}  className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>
                        List
                    </Link>
                </div>
                {axData && <AddStock inventory_id={1} material_list={axData} />}
                </div>
            </div>
        </div> 
    )
}

export default BulkInventory;