import React from 'react';
import { Link } from 'react-router-dom';
import Spend from '../Inventory/Spend';

const CrudeOilSpend = () => {

    window.document.title = 'CBDA Crude Oil Spend'

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">CBDa Crude Oil Spend</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <div className="col-sm-12 text-end">
                        <Link to="/user/cbda-crude-oil-spend-list"  className="btn btn-sm btn-success">
                            <svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>
                            List
                        </Link>
                    </div>
                    <Spend inventory_id={3} />
                </div>
            </div>
        </div>   
    )
}

export default CrudeOilSpend;