import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { momentDate, momentDateDisplay, permission, showloader, validateForm } from '../../components/Helper';
import DatePicker from "react-datepicker";
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { GET_FORM_DATA, TESTING_CENTER } from '../../components/ApiUrl';

const TestingCenter = () => {

    window.document.title = 'Testing Center'

    const [context] = useContext(Context)
    const [batch_id] = useState('')
    const [farm_name, setFarmName] = useState('')
    const [test_type] = useState('')
    const [material_type] = useState('')
    const [test_date, setTestDate] = useState(momentDate('', false, false, 'YYYY-MM-DD', true))
    const [farm_list, setFarmList] = useState(false)
    const [materialList, setMaterialList] = useState(false)
    const [testTypeList, setTestTypeList] = useState(false)
    const [batchList, setBatchList] = useState(false)
    const navigate = useNavigate();

    const handleInputChanged = (e) => {
		if (e.target.name === 'batch_id') {
			setFarmName(e.target.selectedOptions[0].getAttribute('data-farm') ?? 'no-farm')
		}
    }

    const handleSubmit = (e) =>  {
        e.preventDefault();

        let formData = new FormData(document.getElementById('testing_center'));
        if(validateForm(e, 'testing_center')){
            postFormData(TESTING_CENTER.ADD_TESTING_CENTER, formData, (res) => {
                if(res.status){
                    setTimeout(()=>{
                        navigate('/admin/testing-center-list');
                    },2000)
                }
            }, true);
        }
    }

    const getFarms = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=farm', (res) => {
            showloader()
            if(res.status){
                setFarmList(res.data.data)
            }
        }, true);
    }

	const fetchMaterialType = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=material', (res) => {
            showloader()
            if(res.status){
                setMaterialList(res.data.data)
            }
        }, true);
	}
	
	const fetchTestType = () => {
        showloader(true)
        getJsonData(GET_FORM_DATA.SYSTEM_DATA_LIST + '?input=1&type=test', (res) => {
            showloader()
            if(res.status){
                setTestTypeList(res.data.data)
            }
        }, true);
	}

	const getBatchId = () => {
		showloader(true)
        getJsonData(GET_FORM_DATA.GET_BATCH_LIST, (res) => {
            showloader()
            if (res.status) {
                setBatchList(res.data)
            }
        }, true)
	}

	const changeDateFilter = (date) => {
		setTestDate(momentDateDisplay(date, false, false, 'YYYY-MM-DD'));
	}

    useEffect(() => {
        getFarms();
        getBatchId();
        fetchMaterialType();
        fetchTestType();
    }, []);

    return (
        
        <div className="container-fluid container-common">

            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Testing Centre</h2>
                </div>
                <div className="col-sm-12 text-end">
                    <Link to="/admin/testing-center-list" className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_list" /></svg>
                        List
                    </Link>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                    <form method="post" className="row g-3 g-sm-4" id="testing_center">

                        <div className="col-6">
                            <label htmlFor="test_date" className="form-label"> Testing Date <strong className="text-danger">*</strong></label>
                            <div className="date-wrapperbox" style={{ display: 'block' }}>
                                <DatePicker
                                    placeholderText="dd-mm-yy"
                                    onChange={(e) => changeDateFilter(e)}
                                    name="test_date"
                                    id="test_date"
                                    value={test_date}
                                    required
                                    className="form-control form-dateicon"
                                    autoComplete="off"
                                    popperModifiers={[
                                        {
                                          name: 'arrow',
                                          options: {
                                            padding: ({ popper, reference }) => ({
                                              right: Math.min(popper.width, reference.width) + 50,
                                            }),
                                          },
                                        },
                                    ]}
                                />
                                <div className='invalid-feedback'>Please enter unit type.</div>
                            </div>
                        </div>

                        <div className="col-6">
                            <label htmlFor="batch_id" className="form-label">Batch Id <strong className="text-danger">*</strong></label>
                            <select id="batch_id" name="batch_id" onChange={(e) => handleInputChanged(e)} className="form-select form-select-lg" defaultValue={batch_id} required>
                                <option value=''>Choose</option>
                                {
                                    (batchList) ? batchList.map((batch, key) =>
                                        <option key={key} data-farm={batch.farm_name} value={batch.batch_id} >{batch.batch_id}</option>
                                    )
                                    :''
                                }
                            </select>
                            <div className='invalid-feedback'>Please choose batch id.</div>
                        </div>

                        <div className="col-6">
                            <label htmlFor="farm" className="form-label">Farm<strong className="text-danger">*</strong></label>
                            <select id="farm_name" name="farm_name" onChange={(e) => handleInputChanged(e)} className="form-select form-select-lg pe-none bg-light" value={farm_name} required>
                                <option value='no-farm'>No Farm</option>
                                { farm_list && farm_list.map((farmList)=>
                                    <option key={farmList.id} value={farmList.name}>{farmList.name}</option>
                                ) }
                            </select>
                        </div>

                        <div className="col-6">
                            <label htmlFor="material_type" className="form-label">Input Material<strong className="text-danger">*</strong></label>
                            <select id="material_type" name="material_type" onChange={(e) => handleInputChanged(e)} className="form-select form-select-lg" defaultValue={material_type} required>
                                <option value=''>Choose</option>
                                {
                                    (materialList) ? materialList.map((materialType) =>
                                        <option key={materialType.id} value={materialType.id}>({materialType.code}) {materialType.name}</option>
                                    )
                                        : ''
                                }
                            </select>
                            <div className='invalid-feedback'>Please choose input material.</div>
                        </div>
                        
                        <div className="col-6">
                            <label htmlFor="test_type" className="form-label">Test Type<strong className="text-danger">*</strong></label>
                            <select id="test_type" name="test_type" onChange={(e) => handleInputChanged(e)} className="form-select form-select-lg" defaultValue={test_type} required>
                                <option value='' disabled>Choose</option>
                                {
                                    (testTypeList) ? testTypeList.map((testType) =>
                                        <option key={testType.id} value={testType.id} > {testType.name} </option>
                                    )
                                        : ''
                                }

                            </select>
                            <div className='invalid-feedback'>Please choose test type.</div>
                        </div>
                        <div className="col-6">
                            <label htmlFor="attachment" className="form-label">Attachment</label>
                            <input type="file" id="attachment" accept=".jpg,.jpeg,.png,.pdf" name="attachment" className="form-control" placeholder="Attachment" />
                        </div>

                        {permission(context && context.auth.permission, 'add') &&
                            <div className="mb-4 mb-sm-5">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        }

                    </form>
                </div>
            </div>
        </div>
    )
}

export default TestingCenter;