import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { initialFormState, momentDate, momentDateDisplay, showloader, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { getJsonData, postFormData } from '../../fetch-data';
import { BIOMASS_BAGGING_SERVICE, PRE_EXTRACTION } from '../../components/ApiUrl';

const PreExtraction = () => {
    window.document.title = 'Pre Extraction Form'
    const location = useLocation()
    const navigate = useNavigate()
    const edit_id = location.state;

    const [context] = useContext(Context)
    const [option_list, setOptionList] = useState()
    const [recorder_by] = useState(context && context.auth.name)
    const [date] = useState(momentDate('', false, false, 'YYYY-MM-DD', true))
    const [default_time] = useState(momentDate('', false, false, 'HH:mm', true))
    const [editRequest, setEditRequest] = useState(false)
    const [preBoimassData, setPreBoimassData] = useState(false)
    const [biomass_bagging, setBoimassBagging] = useState('')

    const handleSelectChanged = (selectedValue) => {
        setBoimassBagging(selectedValue)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(document.getElementById('pre_extraction_form'));

        formData.append('date_time', date+' '+formData.get('time'))
        if(validateForm(e, 'pre_extraction_form')){
            if(editRequest){
                formData.append('id', edit_id);
                showloader(true)
                postFormData(PRE_EXTRACTION.UPDATE, formData, (res) => {
                    showloader()
                    if(res.status){
                        setEditRequest(false)
                        navigate('/user/pre-extraction-list');
                    }
                }, true);
            }else{
                showloader(true)
                postFormData(PRE_EXTRACTION.STORE, formData, (res) => {
                    showloader()
                    if(res.status){
                        initialFormState('pre_extraction_form')
                        getBagNumbers()                        
                    }
                }, true);
            }
            
        }
    }

    const getBagNumbers = () => {
        showloader(true)
        getJsonData(BIOMASS_BAGGING_SERVICE.BAGGING_ALL +`?type=0`, (res) => {
            showloader()
            if(res.status){
                var option = [];
                res.data.data.map((item) => {
                    option.push({ value: item.id, label: item.bag_num});
                });
                setOptionList(option)
            }
        }, true);
    }

    useEffect(() => {
        getBagNumbers()
        if(edit_id){
            showloader(true)
            getJsonData(PRE_EXTRACTION.DETAILS + `/${edit_id}`, (res) =>{
                showloader()
                if(res.status){
                    setEditRequest(true)
                    setPreBoimassData(res.data)
                }
            }, true);
        }
    }, [edit_id])
    console.log(preBoimassData);

    return (
        <div className="container-fluid container-common">
            <div className="row justify-content-center">
                <div className="col-sm-12">
                    <h2 className="page-heading">Pre Extraction (Biomass) Form</h2>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-7 py-4">
                <div className="col-sm-12 text-end">
                    <Link to="/user/pre-extraction-list"  className="btn btn-sm btn-success">
                        <svg className="icon me-2"><use xlinkHref="#icon_list"/></svg>
                        List
                    </Link>
                </div>
                <form method="post" className="row g-3 g-sm-4" id="pre_extraction_form">
                    <div className="col-6">
                        <label htmlFor="recorder_by" className="form-label">Recorded by <strong className="text-danger">*</strong></label>
                        <input type="text" id="recorder_by" name="recorder_by" className="form-control" placeholder="Recorded by" defaultValue={preBoimassData ? preBoimassData.recorder_by : recorder_by} required />
                        <div className="invalid-feedback">Please enter Recorder By.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="biomass_bagging_id" className="form-label">Bag No. <strong className="text-danger">*</strong></label>
                        <Select 
                            id="biomass_bagging_id" 
                            name="biomass_bagging_id" 
                            value={biomass_bagging ? biomass_bagging : {label: preBoimassData.bag_num, value: preBoimassData.biomassbagging_id}} 
                            options={option_list}
                            className="form-select-react"
                            onChange={(e) => handleSelectChanged(e)}
                            required
                        />
                        <div className="invalid-feedback">Please choose Bag No.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="date" className="form-label">Date <strong className="text-danger">*</strong></label>
                        <input type="date" id="date" name="date" readOnly value={preBoimassData ? momentDateDisplay(preBoimassData.date_time, false, false, 'YYYY-MM-DD') : date} className="form-control" placeholder="Date" required />
                        <div className="invalid-feedback">Please select Date.</div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="time" className="form-label">Time <strong className="text-danger">*</strong></label>
                        <input type="time" id="time" name="time" value={preBoimassData ? momentDateDisplay(preBoimassData.date_time, false, false, 'HH:mm') : default_time} className="form-control"  placeholder="Your answer" required />
                        <div className="invalid-feedback">Please select Time.</div>
                    </div>
                    <div className="mb-4 mb-sm-5">
                        <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => handleSubmit(e)}>Submit</button>
                    </div>
                </form>
                </div>
            </div>
        </div>
    )
}

export default PreExtraction;