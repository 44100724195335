import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonLoading, validateForm } from '../../components/Helper';
import Context from '../../components/Context';
import { postFormData } from '../../fetch-data';
import { AUTH } from '../../components/ApiUrl'

const Login = () => {
    window.document.title = 'Login';

    const navigate = useNavigate()
    const [context, setContext] = useContext(Context)
    const [disableButton, setDisableButton] = useState(false)

    const handleSubmit = async (e) => {
        let formdata = new FormData(document.getElementById('loginForm'));

        if (validateForm(e, "loginForm")) {
            setDisableButton(true)

            postFormData(AUTH.LOGIN, formdata, (res) => {
                setDisableButton(false)

                if (res.status) {
                    localStorage.setItem("token", res.data.access_token);

                    setContext(prevState => ({
                        ...prevState,
                        auth: res.data
                    }))

                    if (res.data.role_id === 2) {
                        navigate('/user/biomass-bagging')
                    } else {
                        navigate("/admin/dashboard");
                    }
                }
            })
        }
    }

    return (
        <section className="login-section">
            <img className='img-hero' src="/images/slider1.png" alt="..." />
            <div className="container my-auto position-relative">
                <div className="row g-0 justify-content-center">
                    <div className="col-sm-12">
                        <form className="logo-form" id="loginForm">
                            <div className="login-logo">
                                <img src="/images/logo.png" width="120" height="54" alt="logo" />
                            </div>
                            <hr />
                            <h2 className="login-heading mb-3">Login</h2>
                            <div className="mb-3">
                                <input type="email" name="email" id="email" className="form-control form-control-lg" placeholder="Enter Username" required />
                            </div>
                            <div className="mb-3">
                                <input type="password" name="password" id="password" className="form-control form-control-lg" placeholder="Enter Password" required />
                            </div>
                            <div className="w-100">
                                <button type="button" className="btn btn-primary w-100" onClick={handleSubmit} disabled={disableButton}>
                                    <ButtonLoading load={disableButton} btnName="Login" />
                                </button>
                                <div className='text-start mt-3'>
                                    <Link to="/" className='text-decoration-none text-body'><svg className="icon me-2"><use xlinkHref="#icon_prearrow" /></svg>Go To Home</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;