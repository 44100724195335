import React, { useEffect, useState, useContext } from 'react';
import { initialFormState, momentDateDisplay, permission, showloader, validateForm} from '../../components/Helper';
import NotAllowed from '../../components/NotAllowed';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Context from '../../components/Context';
import { SETTING, WASTAGE } from '../../components/ApiUrl';
import { getJsonData, postFormData } from '../../fetch-data'
import { DataList } from '../../components/DataList';
import { now } from 'lodash'

const ListWastage = () => {
    window.document.title = 'Wastage List';

    const [material, setMaterial] = useState()
    const [date, setDate] = useState(new Date())
    const [context] = useContext(Context)
    const [refresh, setRefresh] = useState()

    const dt = {
        id: 'wastage-list',
        fetchurl: WASTAGE.LIST,
        columns: [
            { name: 'id', title: 'ID' },
            { name: 'wastage_for', title: 'Wastage For' },
            { name: 'wastage', title: 'Wastage' },
            { name: 'date', title: 'Date' },
            { name: 'user', title: 'Logged By' },
        ]
    }

    const fetchMaterialType = () => {
        showloader(true)
        getJsonData(SETTING.SYSTEM_DATA_LIST + '?input=1&type=material', (res) => {
            showloader()
            if (res.status) {
                setMaterial(res.data.data)
            }
        }, true);
    }

    const StoreWastage = (e) => {
        e.preventDefault();
        const formData = new FormData(document.getElementById('add_wastage'));

        if (validateForm(e, 'add_wastage')) {
            showloader(true)
            postFormData(WASTAGE.ADD, formData, (res) => {
                showloader()
                initialFormState('add_wastage');
                document.querySelector('#addWastageModal [data-bs-dismiss="modal"]').click()
                if (res.status) {
                    setRefresh(now)
                }
            }, true);
        }
    }

    useEffect(() => {
        fetchMaterialType()
    }, [refresh])

    return (
        (permission(context && context.auth.permission, 'add') && permission(context && context.auth.permission, 'update') && permission(context && context.auth.permission, 'view') && permission(context && context.auth.permission, 'delete')) ?
            <div className="container-fluid container-common">
                <div className="row justify-content-center">
                    <div className="col-sm-12">
                        <h2 className="page-heading">Wastage List</h2>
                    </div>
                    <div className="col-md-12">
                        <DataList
                            dt={dt}
                            refresh={refresh}
                            outerbutton={(records) =>
                                <div className="ms-auto">
                                    <button className="btn btn-success btn-sm me-2 mb-1" data-bs-toggle="modal" data-bs-target="#addWastageModal">Add Wastage</button>
                                </div>
                            }
                            edit_column={{
                                wastage_for: (record) =>
                                    <span>
                                        {record && record.material_id === 3 ? 'CBDa Crude Oil' : record.wastage_for}
                                    </span>,
                                date: (record) =>
                                    <span>
                                        {record && momentDateDisplay(record.date)}
                                    </span>,
                                user: (record) =>
                                    <span>
                                        {record && record.user}
                                    </span>
                            }}
                        />
                    </div>
                </div>

                {/* Add Wastage Modal */}

                <div className="modal fade" id="addWastageModal" tabIndex="-1" data-bs-backdrop="static">
                    <form method="post" id="add_wastage">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="addwastageModalLabel">Add Wastage</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => { initialFormState('add_wastage'); setDate(new Date())} } aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">

                                        <div className="col-12">
                                            <label htmlFor="material" className="form-label">Select Material Type<strong className="text-danger">*</strong></label>
                                            <select id="material" name="material" className="form-select form-select-lg" required>
                                                <option value="">Choose Material Type</option>
                                                <option value={3}>CBDa Crude Oil</option>
                                                {material ? material.map((materialType) =>
                                                    <option key={materialType.id} value={materialType.id}>{materialType.name}</option>
                                                ) : ''}
                                            </select>
                                            <div className='invalid-feedback'>Please choose Material Type.</div>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label htmlFor="date" className="form-label"> Date <strong className="text-danger">*</strong></label>
                                        <DatePicker
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                            className="form-control form-dateicon"
                                            dateFormat="yyyy-MM-dd"
                                            name="date"
                                            required
                                            autoComplete="off"
                                            popperModifiers={[
                                                    {
                                                    name: 'arrow',
                                                    options: {
                                                        padding: ({ popper, reference }) => ({
                                                        right: Math.min(popper.width, reference.width) + 50,
                                                        }),
                                                    },
                                                    },
                                                ]}
                                            />
                                            <div className='invalid-feedback'>Please select Date.</div>
                                        </div>
                                        <div className="col-6 mt-2">
                                            <label htmlFor="wastage" className="form-label">Wastage <strong className="text-danger">*</strong></label>
                                            <input type="text" id="wastage" name="wastage" className="form-control" placeholder="Wastage" required />
                                            <div className='invalid-feedback'>Please enter Wastage.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => StoreWastage(e)}>Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            : <NotAllowed />
    )
}
export default ListWastage;
