import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ConfirmationModal, floatNumber, initialFormState, momentDateDisplay, permission, showloader, validateForm } from '../../components/Helper';
import Filter from '../../components/Filter';
import ExportData from '../../components/ExportData';
import Context from '../../components/Context';
import EditRequest from '../../components/EditRequest';
import { DataList } from '../../components/DataList';
import { ASSET_ENDPOINT, INVENTORY } from '../../components/ApiUrl';
import { getJsonData, postFormData } from '../../fetch-data';
import {now} from 'lodash'

const InventoryList = (props) =>  {

    const [context] = useContext(Context)
    const [wastageData, setWastageData] = useState()
    const [refresh, setRefresh] = useState('')
    const [deleteRecord, setDeleteRecord] = useState('')
    const [filter, setFilter] = useState('')
    const [axData, setAxData] = useState({
        export:{
            data: props.inventory_id == 2 ? 'Solvent_Inventory' : (props.inventory_id == 3 ? 'CBDa_Crude_Oil_Inventory' : (props.inventory_id == 1 ? 'Bulk_Inventory' : 'Hemp_Inventory')),
            type: 'xls'
        },
        inventory_log_id: '',
        attachment_date: '',
        attachments: '',
        unit_type: props.material_list && props.material_list.length > 0 ? props.material_list[0].units : 'lbs',
    })

    const cbda_crude_oil_inventory =  {
        id: 'cbda_crude_oil_inventory',
        fetchurl: INVENTORY.LIST,
        columns: [
            { name: 'id', title: 'ID'},
            { name: 'batch_id', title: 'Batch ID'},
            { name: 'recorded_by', title: 'Recorded by' },
            { name: 'bag_num', title: 'Vendor Name'},
            { name: 'batch_date', title: 'Batch Date'},
            { name: 'weight', title: 'Weight'},
            { name: 'component', title: 'Crude (%)', width: 100 },
            { name: 'notes', title: 'Notes', width: 10 },
            { name: 'attachment', title: 'Attachment', width: 200 },
            { name: 'attachment_date', title: 'Attachment Date', width: 10 },
            { name: 'action', title: 'Action', visible: context && context.auth.role_id === 2 ? true : false }

        ],
        filter_string: filter ? (filter.user_id && filter.date ? 'user_id='+filter.user_id+'&date='+filter.date : (filter.user_id ? 'user_id='+filter.user_id : filter.date && 'date='+filter.date)) : '',
        query_string: 'inventory=' + (props.inventory_id == 1 || props.inventory_id == 2 ? (props.material_list ? props.material_list[0].id : '') : props.inventory_id)
    }

    const hemp_inventory = {
        id: 'hemp_inventory_list',
        fetchurl: INVENTORY.LIST,
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'batch_id', title: 'Batch ID'},
            {name: 'farm_name', title: 'Farm Name'},
            {name: 'batch_date', title: 'Hemp Date'},
            {name: 'weight', title: 'Inventory(lbs)'},
            {name: 'component', title: 'Crude(%)', width: 200},
            {name: 'wastage', title: 'Wastage(lbs)'},
            {name: 'attachment', title: 'Attachment'},
            {name: 'attachment_date', title: 'Attachment Date'},
            {name: 'action', title: 'Action', visible: context && context.auth.role_id === 1 ? true : false}
        ],
        filter_string: 'date='+ (filter && filter.date ? filter.date : ''),
        query_string: 'inventory=' + (props.inventory_id == 1 || props.inventory_id == 2 ? (props.material_list ? props.material_list[0].id : '') : props.inventory_id),
        
    }

    const solvent_inventory = {
        id: 'solvent_inventory_list',
        fetchurl: INVENTORY.LIST,
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'batch_date', title: 'Date'},
            {name: 'weight', title: `Inventory (${axData.unit_type})`},
            {name: 'vendor_name', title: 'Vendor'},
            {name: 'wastage', title: `Wastage (${axData.unit_type})`},
            {name: 'action', title: 'Action', visible: context && context.auth.role_id === 1 ? true : false}
        ],
        query_string: 'inventory=' + (props.inventory_id == 1 || props.inventory_id == 2 ? props && props.material_list[0].id : props.inventory_id),
        filter_string: filter ? (filter.material_id && filter.date ? 'inventory='+filter.material_id+'&date='+filter.date : (filter.material_id ? 'inventory='+filter.material_id : 'date='+filter.date)) : '',
    }

    const bulk_inventory = {
        id: 'bulk_inventory_list',
        fetchurl: INVENTORY.LIST,
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'batch_date', title: 'Batch Date'},
            {name: 'recorded_by', title: 'Recorded by'},
            {name: 'weight', title: 'Weight'},
            {name: 'purity', title: 'Purity'},
            {name: 'notes', title: 'Notes'},
            {name: 'action', title: 'Action', visible: context && (context.auth.role_id === 1 || context.auth.role_id === 2) ? true : false},
        ],
        query_string: 'inventory=' + (props.inventory_id == 1 || props.inventory_id == 2 ? props && props.material_list[0].id : props.inventory_id),
        filter_string: filter && (filter.material_id || filter.date || filter.user_id) ? (filter.material_id && filter.date && filter.user_id ? 'inventory='+filter.material_id+'&date='+filter.date+'&user_id='+filter.user_id : (filter.material_id ? 'inventory='+filter.material_id : 'date='+filter.date)) : filter.user_id ? 'user_id='+ filter.user_id : '',
    }

    const StoreWastage = (e) => {
        e.preventDefault();
        let formdata = new FormData(document.getElementById('add_wastage_modal'));

        if(validateForm(e, 'add_wastage_modal')){
            showloader(true)
            postFormData(INVENTORY.UPDATE_WASTAGE, formdata, (res) => {
                showloader()
                if(res.status){
                    setRefresh(now)
                    initialFormState('add_wastage_modal')
                    document.querySelector('#add_wastage_modal [data-bs-dismiss="modal"]').click()
                }
            }, true);
        }        
    }

    const deleteInventoryRecord = (id) => {
        showloader(true)
        getJsonData(INVENTORY.INVENTORY_DELETE + `/${id}`, (res) => {
            showloader()
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if(res.status){
                setRefresh(now)
            }
        }, true);
    }

    const attachmentFor = (log_id) => {
        setAxData(prevState => ({
            ...prevState,
            inventory_log_id: log_id
        }))
    }

    const addAttachment = (e) => {
        e.preventDefault()
        const formData = new FormData(document.getElementById('add_attachment_modal'));
        if(validateForm(e, 'add_attachment_modal')){
            formData.append("inventory_log_id", axData.inventory_log_id);
            showloader(true)
            postFormData(INVENTORY.ADD_ATTACHMENT, formData, (res) => {
                showloader()
                if(res.status){
                    setRefresh(now)
                    initialFormState('add_attachment_modal')
                    document.querySelector('#addAttachmentModal [data-bs-dismiss="modal"]').click()
                }
            }, true);

        }
    }

    const changeDateFilter = (date) => {
        setAxData(prevState => ({
            ...prevState,
            attachment_date: momentDateDisplay(date, false, false, 'DD-MM-YYYY')
        }))
    }

    const openAddWastageModal = (item) => {
        document.getElementById('new_wastage').value = ''
        setWastageData({
            id: item.id,
            date: momentDateDisplay(item.batch_date),
            inventory: floatNumber(item.weight),
            old_wastage: floatNumber(item.wastage),
            new_wastage: ''
        })
    }

    useEffect(() => {
        // listData()
    }, [refresh, filter])

    return (
        <>
            <div className="col-md-12 my-2">
            {props.inventory_id == 4 &&
                <DataList
                    dt={hemp_inventory}
                    refresh={refresh ? refresh : filter}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                            { context && context.auth.role_id === 1 && 
                                <>
                                    { props && props.inventory_id == 4 && 
                                        <>
                                            <Link to="/admin/add-hemp" className="btn btn-sm btn-success me-2 mb-1">
                                                <svg className="icon me-2"><use xlinkHref="#icon_pluscircle" /></svg>
                                                Add Hemp
                                            </Link>
                                            <ExportData export_data={axData.export} inventory_id={props.inventory_id} unit_type={axData.unit_type}/>
                                            <Filter filter_type="MonthYear" onFilterChange={setFilter} />
                                        </> 
                                    }
                                </>
                            }
                        </div>
                    }
                    edit_column={{
                        batch_date: (record) =>
                            <span>
                                {record && momentDateDisplay(record.batch_date)}
                            </span>,
                        weight: (record) => 
                            <span>
                                {record && floatNumber(record.weight)}
                            </span>,
                        component: (record) =>
                            <span>
                                {record && record.component &&
                                    <>
                                        { JSON.parse(record.component).cbda && <><strong>CBDa:</strong> {floatNumber(JSON.parse(record.component).cbda)}<br/></> }
                                        { JSON.parse(record.component).cbd && <><strong>CBD: </strong> {floatNumber(JSON.parse(record.component).cbd)}<br/></> }
                                        { JSON.parse(record.component).cbna && <><strong>CBNa: </strong> {floatNumber(JSON.parse(record.component).cbna)}<br/></> }
                                        { JSON.parse(record.component).cbn && <><strong>CBN: </strong> {floatNumber(JSON.parse(record.component).cbn)}<br/></> }
                                        { JSON.parse(record.component).cbga && <><strong>CBGa: </strong> {floatNumber(JSON.parse(record.component).cbga)}<br/></> }
                                        { JSON.parse(record.component).cbg && <><strong>CBG: </strong> {floatNumber(JSON.parse(record.component).cbg)}<br/></> }
                                        { JSON.parse(record.component).thcva && <><strong>THCVa: </strong> {floatNumber(JSON.parse(record.component).thcva)}<br/></> }
                                        { JSON.parse(record.component).thcv && <><strong>THCV: </strong> {floatNumber(JSON.parse(record.component).thcv)}<br/></> }
                                        { JSON.parse(record.component).cbdva && <><strong>CBDVa: </strong> {floatNumber(JSON.parse(record.component).cbdva)}<br/></> }
                                        { JSON.parse(record.component).cbdv && <><strong>CBDV: </strong> {floatNumber(JSON.parse(record.component).cbdv)}<br/></> }
                                        { JSON.parse(record.component).cbca && <><strong>CBCa: </strong> {floatNumber(JSON.parse(record.component).cbca)}<br/></> }
                                        { JSON.parse(record.component).cbc && <><strong>CBC: </strong> {floatNumber(JSON.parse(record.component).cbc)}<br/></> }
                                        { JSON.parse(record.component).thca && <><strong>THCa: </strong> {floatNumber(JSON.parse(record.component).thca)}<br/></> }
                                        { JSON.parse(record.component).thc && <><strong>THC: </strong> {floatNumber(JSON.parse(record.component).thc)}<br/></> }
                                        { JSON.parse(record.component).thc8 && <><strong>8THC: </strong> {floatNumber(JSON.parse(record.component).thc8)}<br/></> }
                                        { JSON.parse(record.component).crude && <><strong>Crude:</strong> {floatNumber(JSON.parse(record.component).crude)} </> }
                                    </>
                                }
                            </span>,
                        wastage: (record) =>
                            <span>
                                {record && floatNumber(record.wastage)}
                            </span>,
                        attachment: (record) => 
                            <span>
                                {record.attachment ? <a href={`${ASSET_ENDPOINT}${record.attachment}`} rel="noopener noreferrer"  target="_blank">{record.attachment}</a>
                                    : <button className="btn btn-sm btn-dark me-2" data-bs-toggle="modal" data-bs-target="#addAttachmentModal" onClick={() => attachmentFor(record.id)}>Add Attachment</button> 
                                }
                            </span>,
                        attachment_date: (record) => 
                            <span>
                                { record.attachment ?
                                    record.attachment_date ? momentDateDisplay(record.attachment_date) : momentDateDisplay(record.hemp_date)
                                    : '-'
                                }
                            </span>,
                        action: (record) =>
                            <div className="d-flex">
                                { context && context.auth.role_id === 1 && permission(context.auth.permission, 'delete') && props.inventory_id == 4 &&
                                    <button className="btn btn-sm btn-primary me-2" data-bs-toggle="modal" data-bs-target="#addWastageModal" onClick={() => openAddWastageModal(record)}>Add Wastage</button>
                                }
                            </div>
                    }}
                />
            }

            {props && props.inventory_id == 3 &&
                <DataList 
                    dt={cbda_crude_oil_inventory}
                    refresh={refresh ? refresh : filter}
                    outerbutton={(record) =>
                        <div className="ms-auto">
                        { context && context.auth.role_id === 1 &&
                            <>
                                { props && props.inventory_id == 3 &&
                                    <>
                                        <ExportData export_data={axData.export} inventory_id={props.inventory_id} unit_type={axData.unit_type}/>
                                        <Filter filter_type="DateUser" user_list={axData.users_list} onFilterChange={setFilter} />
                                    </> 
                                }
                            </>                        
                        }
                        {context && context.auth.role_id == 2 && props && props.inventory_id == 3 &&
                            <Link to="/user/cbda-crude-oil"  className="btn btn-sm btn-success">
                                <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                Back
                            </Link>
                        }
                        </div>
                    }
                    edit_column={{
                        batch_date: (record) => 
                            <span>
                                {record && momentDateDisplay(record.batch_date)}
                            </span>,
                        weight: (record) => 
                            <span>
                                {record && floatNumber(record.weight)}
                            </span>,
                        component: (record) =>
                            <span>
                                {record && record.component &&
                                    <>
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbda && <><strong>CBDa:</strong> {floatNumber(JSON.parse(record.component).cbda)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbd && <><strong>CBD: </strong> {floatNumber(JSON.parse(record.component).cbd)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbna && <><strong>CBNa: </strong> {floatNumber(JSON.parse(record.component).cbna)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbn && <><strong>CBN: </strong> {floatNumber(JSON.parse(record.component).cbn)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbga && <><strong>CBGa: </strong> {floatNumber(JSON.parse(record.component).cbga)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbg && <><strong>CBG: </strong> {floatNumber(JSON.parse(record.component).cbg)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).thcva && <><strong>THCVa: </strong> {floatNumber(JSON.parse(record.component).thcva)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).thcv && <><strong>THCV: </strong> {floatNumber(JSON.parse(record.component).thcv)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbdva && <><strong>CBDVa: </strong> {floatNumber(JSON.parse(record.component).cbdva)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbdv && <><strong>CBDV: </strong> {floatNumber(JSON.parse(record.component).cbdv)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbca && <><strong>CBCa: </strong> {floatNumber(JSON.parse(record.component).cbca)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).cbc && <><strong>CBC: </strong> {floatNumber(JSON.parse(record.component).cbc)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).thca && <><strong>THCa: </strong> {floatNumber(JSON.parse(record.component).thca)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).thc && <><strong>THC: </strong> {floatNumber(JSON.parse(record.component).thc)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).thc8 && <><strong>8THC: </strong> {floatNumber(JSON.parse(record.component).thc8)}<br/></> }
                                        { JSON.parse(record.component) && JSON.parse(record.component).crude && <><strong>Crude:</strong> {floatNumber(JSON.parse(record.component).crude)} </> }
                                    </>
                                }
                            </span>,
                        attachment: (record) => 
                            <span>
                                    {record.attachment ? <a href={`${ASSET_ENDPOINT}${record.attachment}`} rel="noopener noreferrer" target="_blank">{record.attachment}</a>
                                : <> { record.vendor_name ? <button className="btn btn-sm btn-dark me-2" data-bs-toggle="modal" data-bs-target="#addAttachmentModal" onClick={() => attachmentFor(record.id)}>Add Attachment</button> : '-' } </>}
                            </span>,
                        attachment_date: (record) =>
                            <span>
                                { record.attachment ? record.attachment_date ? momentDateDisplay(record.attachment_date) : momentDateDisplay(record.hemp_date) : '-'} 
                            </span>,
                        action: (record) =>
                            <span>
                                { (record.status === 0 || record.status === 3 || record.status === 4) && 
                                    <EditRequest form_id="4" record_id={record.id} update_table={setRefresh}/> 
                                }

                                { props.inventory_id == 3 && record.status === 2 && 
                                    <Link to="/user/cbda-crude-oil" state={record.id} className="btn btn-sm btn-outline-primary m-1">
                                        <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                    </Link> 
                                }
                            </span>

                    }}
                />
            }

            {props.inventory_id == 2 &&
                <DataList
                    dt={solvent_inventory}
                    refresh={refresh ? refresh : filter}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                            { context && context.auth.role_id === 1 && 
                                <>
                                    { props && props.inventory_id == 2 && 
                                        <>
                                            <Link to="/admin/add-solvent" className="btn btn-sm btn-success me-2 mb-1">
                                                <svg className="icon me-2"><use xlinkHref="#icon_pluscircle" /></svg>
                                                Add Solvent
                                            </Link>
                                            <ExportData export_data={axData.export} inventory_id={props.inventory_id} unit_type={axData.unit_type}/>
                                            <Filter filter_type="MonthYearMaterial"  material_list={props && props.material_list} onFilterChange={setFilter} />
                                        </> 
                                    }
                                </>
                            }
                        </div>
                    }
                    edit_column={{
                        batch_date: (record) =>
                            <span>
                                {record && momentDateDisplay(record.batch_date)}
                            </span>,
                        weight: (record) => 
                            <span>
                                {record && floatNumber(record.weight)}
                            </span>,
                        
                        wastage: (record) =>
                            <span>
                                {record && floatNumber(record.wastage)}
                            </span>,
                        action: (record) =>
                            <span>
                                { permission(context && context.auth.permission, 'add') && <button className="btn btn-sm btn-primary me-2" data-bs-toggle="modal" data-bs-target="#addWastageModal" onClick={() => openAddWastageModal(record)}>Add Wastage</button> }
                            </span>
                    }}
                />
            }

            {props.inventory_id == 1 &&
                <DataList
                    dt={bulk_inventory}
                    refresh={refresh ? refresh : filter}
                    outerbutton={(records) =>
                        <div className="ms-auto">
                            { context && context.auth.role_id === 1 && 
                                <>
                                    <ExportData export_data={axData.export} inventory_id={props.inventory_id} unit_type={axData.unit_type}/>
                                    <Filter filter_type="DateUserMaterial"  material_list={props.material_list} onFilterChange={setFilter}  />
                                </>
                            }
                            { context && context.auth.role_id === 2 && 
                                <>
                                    <Filter filter_type="Material" material_list={props.material_list} onFilterChange={setFilter}/>
                                    <Link to="/user/bulk-inventory" className="btn btn-sm btn-success ms-1 mb-1">
                                        <svg className="icon me-2"><use xlinkHref="#icon_prearrow"/></svg>
                                        Back
                                    </Link>
                                </>
                            }
                        </div>
                    }
                    edit_column={{
                        batch_date: (record) =>
                            <span>
                                {record && momentDateDisplay(record.batch_date)}
                            </span>,
                        weight: (record) => 
                            <span>
                                {record && floatNumber(record.weight)}
                            </span>,
                        purity: (record) =>
                            <span>
                                {record && floatNumber(record.purity)}
                            </span>,
                        wastage: (record) =>
                            <span>
                                {record && floatNumber(record.wastage)}
                            </span>,
                        action: (record) =>
                            <span>
                                {context && context.auth.role_id === 1 &&
                                    <>
                                        {props.inventory_id == 1 &&
                                            <Link to="/admin/bulk-inventory" state={record} className="btn btn-sm btn-outline-success m-1">
                                                <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                            </Link> 
                                        }

                                        { props.inventory_id == 1 && (permission(context && context.auth.permission, 'delete')) &&
                                            <button type="button" className="btn btn-sm btn-outline-danger m-1" data-bs-target="#deletesection_modal" data-bs-toggle="modal" onClick={() => setDeleteRecord(record.id)}>
                                                <svg className="icon"><use xlinkHref="#icon_trash"/></svg>
                                            </button> 
                                        }
                                    </>
                                }
                                { context && context.auth.role_id === 2 && 
                                    <>
                                        {(record && record.status === 0 || record.status === 3 || record.status === 4) && 
                                            <EditRequest form_id="6" record_id={record.id} update_table={setRefresh} /> 
                                        }
                                        { props.inventory_id == 1 && record.status === 2 && 
                                            <Link to="/user/bulk-inventory" state={record.id} className="btn btn-sm btn-outline-primary m-1">
                                                <svg className="icon"><use xlinkHref="#icon_edit"/></svg>
                                            </Link> 
                                        }
                                    </>
                                }
                            </span>
                    }}
                />
            }

            </div>
            
            {/* Add Wastage Modal */}

            <div className="modal fade" id="addWastageModal" tabIndex="-1" aria-labelledby="addwastageModalLabel" aria-hidden="true">
                <form method="post" id="add_wastage_modal">
                    <input type="hidden" name='id' value={wastageData && wastageData.id ? wastageData.id : ''} />
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addwastageModalLabel">Add Wastage</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-6">
                                        <label htmlFor="date" className="form-label">Date <strong className="text-danger">*</strong></label>
                                        <input type="text" id="date" name="date" className="form-control" defaultValue={wastageData && wastageData.date ? wastageData.date : ''} placeholder="Date" readOnly />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="weight" className="form-label">Inventory ({axData.unit_type}) <strong className="text-danger">*</strong></label>
                                        <input type="text" id="weight" name="weight" className="form-control"  placeholder="Inventory (lbs)" defaultValue={wastageData && wastageData.date ? wastageData.inventory : ''} readOnly />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="wastage" className="form-label">Wastage <strong className="text-danger">*</strong></label>
                                        <input type="text" id="wastage" name="wastage" className="form-control" defaultValue={wastageData && wastageData.date ? wastageData.old_wastage : ''} placeholder="Wastage" readOnly />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="aw_new_wastage" className="form-label">New Wastage <strong className="text-danger">*</strong></label>
                                        <input type="text" id="new_wastage" name="new_wastage" className="form-control" defaultValue={wastageData && wastageData.date ? wastageData.new_wastage : ''} placeholder="Wastage" required />
                                        <div className="invalid-feedback">Please enter New Wastage.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => StoreWastage(e)}>Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Attachment Modal */}

            <div className="modal fade" id="addAttachmentModal" tabIndex="-1" aria-labelledby="addAttachmentLabel" aria-hidden="true">
                <form method='post' id="add_attachment_modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="addAttachmentLabel">Add Attachment #{axData.inventory_log_id}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) =>initialFormState('add_attachment_modal')} aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <label htmlFor="attachment" className="form-label">Attachment<strong className="text-danger">*</strong></label>
                                        <input type="file" id="attachment" name="attachment" className="form-control" accept=".pdf,.jpeg,.jpg,.png"  placeholder="Attachment" required />
                                        <div className="invalid-feedback">Please upload Attachment.</div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <label htmlFor="date" className="form-label"> Attachment Date <strong className="text-danger">*</strong></label>
                                        <DatePicker
                                            placeholderText="dd-mm-yy"
                                            onChange={(e) => changeDateFilter(e)}
                                            name="attachment_date"
                                            id="attachment_date"
                                            value={axData.attachment_date}
                                            className="form-control form-dateicon"
                                            autoComplete="off"
                                            popperModifiers={[
                                                {
                                                  name: 'arrow',
                                                  options: {
                                                    padding: ({ popper, reference }) => ({
                                                      right: Math.min(popper.width, reference.width) + 50,
                                                    }),
                                                  },
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-primary btn-160" onClick={(e) => addAttachment(e)}>Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <ConfirmationModal msg="Are you sure want to delete?" method={deleteRecord ? () => deleteInventoryRecord(deleteRecord) : ''} />
        </>

        
    )
}

export default InventoryList;